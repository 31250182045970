import React, { useState, useEffect } from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateVendor from './CreateVendor'
import { CircleLoader } from 'react-spinners'
import VendorTable from './VendorTable';
import moment from 'moment';
import { fetchVendorsData } from '../../../service/Vendor';
export function getDisplayName(groupName:string) {
    if(groupName){
        return groupName.replace(/^Vendor-/, '');
    }else{
        return 
    }
  
  }
const Vendors = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vendors, setVendors] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [selectedRow, setSelectedRow]=useState<any[]>([])
    const [isEdit, setIsEdit] = useState(false);
    const handleModalClose = async () => {
        setIsModalOpen(false)
        setIsEdit(false)
        // await fetchData(); 
    }
    const refreshTable = async () => {
        
        await fetchData(); 
    }
    const handleModalOpen = () => {
        setIsModalOpen(true);
    }
    const fetchData = async () => {
        try {
            setVendors([])
            setLoading(true);
            const response2= await fetchVendorsData()
           const parsedRes= response2.map((item:any)=>{
            return {
                ...item,
                CreationDate:moment(item.CreationDate).format('MMMM D, YYYY'),
                LastModifiedDate:moment(item.LastModifiedDate).format('MMMM D, YYYY'),
                displayName:getDisplayName(item.GroupName)
            }
            })
            setVendors(parsedRes); 
        } catch (error) {
            setError(error);
            console.error('Failed to fetch vendor groups:', error);
        } finally {
            setLoading(false);
        }
    };
    const deleteHandler=(groupName:string)=>{
        // setDeleteDOpen(true)
    }


    const editHandler=(vendor:any)=>{
        setIsModalOpen(true);
        setIsEdit(true)
        setSelectedRow(vendor)
        
    }
    
   

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <Paper elevation={3} className="card">
            <Box className="user_Container">
                <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                    <Box>

                        <Typography className="card-heading">Vendor List</Typography>
                        <Typography className="card-sub-heading">
                            Vendor Summary
                        </Typography>

                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
                        <Box className="ip-box">
                            {/* <Search value={searchValue} searchHandler={handleChange} /> */}
                        </Box>
                        <Box className="create-user-icon" >
                            <Button sx={{ padding: '6px 40px 6px 12px', backgroundColor: "#0ae" }} variant="contained" startIcon={<AddIcon />}
                                onClick={handleModalOpen}
                            >
                                Create vendor
                            </Button>
                        </Box>


                    </Box>
                </Box>
                <Box className='user_body'>
                    {loading ? <Box className="loading-spinner-container" sx={{
                        minHeight: "500px"
                    }}>

                        <CircleLoader color="#00BFFF" loading={loading ? true : false} size={60} />
                    </Box> :
                        <>
                        <VendorTable data={vendors}  deleteHandler={deleteHandler} refreshTable={refreshTable} editHandler={editHandler} doEdit={true}/>
                        </>
                    }
                </Box>

            </Box>


            <CreateVendor modalCloseHandler={handleModalClose} open={isModalOpen} isEdit={isEdit} rowData={selectedRow}   refreshTable={refreshTable} />
           
        </Paper>
    )
}


export default Vendors;


// const fetchData=async()=>{
//     const vendor= await fetchVendorGroups()
// return vendor;
// }