import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Chip, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material'
import MMTable from '../../Component/Table/MMTable'
import { tcpaHeadCell, tcpaRows } from '../../Component/Table/tableInterface'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { setBlockScroll, setNoDataFlag, setScrollNext } from '../../Store/Slices/advertiserTabApisData'
import Search from '../../Component/Search/Search';
import AddIcon from '@mui/icons-material/Add';
import { setMBrandEditFlag, setSearchMbrandInput } from '../../Store/Slices/ExternalApiSlice'
import { fetchMBrandApiData } from '../../Services/externalApis'
import { RootState } from '../../Store'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIconPop } from '../Advertiser/Action'
import { Link, useLocation } from 'react-router-dom'
import { fetchCompApiData } from '../../Services/advertiserTabApis'
import { fetchAdvertiserDataAction, fetchTcpaAction } from '../../Store/Actions'
import { fetchTcpaCampaignData, fetchTcpaSearchResult, filterTcpaSearch } from '../../Services/tcpaTabApis'
import { resetTcpaState, setSearchFilterData, setTcpaCampaignIdOnEdit, setTcpaCreateJson, setTcpaData, setTcpaEditFlag, setTcpaEditJson, setTcpafilterSearchFlag, setTcpaFlag, setTcpaSearchInput, updateStateForTCPAEditAction } from '../../Store/Slices/tcpaCampSlice'
import { campaignFormSelector, resetState } from '../../Store/Slices/CreateAdvCampaignSlice';
import CloseIcon from '@mui/icons-material/Close';


const TCPAcampaigns = () => {

  const dispatch = useAppDispatch();
  const tcpaTableData = useAppSelector((state) => state.tcpaCampSlice.tcpaData);
  const tcpaFullData = useAppSelector((state) => state.tcpaCampSlice.tcpaFullData);
  const fullData = useAppSelector((state) => state.advertiserAccApi.fullData);
  // const isLoading = useAppSelector((state) => state.tcpaCampSlice.loading);
  const isLoading = useAppSelector((state) => state.tcpaCampSlice.loading);
  const currentCursor = useAppSelector((state) => state.tcpaCampSlice.currentCursor);
  const hasMoreData = useAppSelector((state) => state.tcpaCampSlice.hasMoreData);
  // const [openPopup, setOpenPopup] = React.useState(false);
  // const [searchInput, setSearchInput] = useState<string>('');
  const tcpaSearchInput = useAppSelector((state) => state.tcpaCampSlice.tcpaSearchInput);
  const [openPopup, setOpenPopup] = React.useState(false);
  const location = useLocation();
  const advCampaignFormState = useAppSelector(campaignFormSelector);
  const advAPIPath = "advertisers"
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  let updatedFilterData:any
  const searchFilterData = useAppSelector((state) => state.tcpaCampSlice.searchFilterData);
  const filterSearchTcpaData = useAppSelector((state) => state.tcpaCampSlice.filterSearchTcpaData);
  const [isMouseOverDelete, setIsMouseOverDelete] = useState(false);
  const [filterSearchInput, setFilterSearchInput] = useState<string>('');
  const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
  const {tcpaFilterSearchFlag,tcpaNameError } = tcpaCampSlice

  
  useEffect(() => {
    // dispatch(resetTcpaState(''))
    dispatch(setTcpaFlag(false));
    dispatch(setTcpafilterSearchFlag(false))
    setIsMouseOverDelete(false);
    // dispatch(setSearchFilterData([]));
    if (!tcpaSearchInput && searchFilterData.length === 0) {
      dispatch(setBlockScroll(false));
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
      fetchInitialData();
      setDebouncedInputValue('');
      fetchCompApiData(dispatch);
    } else {

      if (searchFilterData.length !== 0 && tcpaSearchInput === '') {
        dispatch(setTcpaSearchInput(''));
        const debounceTimer = setTimeout(async () => {
          const payload = {
            "advertiserId": searchFilterData
          }
          // if (searchFilterData.length > 0) {
          //   await  filterAdvertiserSearch(payload, dispatch);
          //   FrontEndSearchInput && searchTable(FrontEndSearchInput);
          // }
          searchFilterData.length > 0 && filterTcpaSearch(payload, dispatch);
        }, 1000);

        // Clear the timer on every key press to restart the countdown
        return () => clearTimeout(debounceTimer);
      }
      else {
        const debounceTimer = setTimeout(() => {
          setDebouncedInputValue(tcpaSearchInput);
        }, 600);
        // Clear the timer on every key press to restart the countdown
        return () => {
            dispatch(resetState("test"));
          clearTimeout(debounceTimer);
          };

      }
    }
    // else {
    //   const debounceTimer = setTimeout(() => {
    //     setDebouncedInputValue(tcpaSearchInput);
    //   }, 600);
    //   // Clear the timer on every key press to restart the countdown
    //   return () => clearTimeout(debounceTimer);
    // }
    // return () => {
    //   dispatch(resetState("test"));
    // };
  }, [dispatch, tcpaSearchInput,searchFilterData]);

  useEffect(() => {
    dispatch(setTcpaSearchInput(''));
    dispatch(fetchTcpaAction(advAPIPath, { ID: "advertiser" }))
    fetchMBrandApiData(dispatch);
    dispatch(setTcpaCreateJson([]));
  }, [])


  const fetchInitialData = () => {
    fetchMBrandApiData(dispatch);
    fetchTcpaCampaignData(dispatch, null);
    dispatch(setScrollNext(true));
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setTcpaFlag(true));
      fetchTcpaCampaignData(dispatch, currentCursor);
    }
  };


  const handelSearch=(event: React.ChangeEvent<HTMLInputElement>)=>{
    dispatch(setTcpaSearchInput(event.target.value));
  }

  useEffect(()=>{
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      dispatch(setSearchFilterData([]));
   fetchTcpaSearchResult(debouncedInputValue, dispatch)
    }
  },[debouncedInputValue])

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = filterSearchTcpaData.filter(function (item:any) {
          return (
          item.tcpaCampaign_id?.toString().toLowerCase().includes(userInput) ||
          item.tcpaCampaign_name?.toLowerCase().includes(userInput) || 
          item.Adv_ID?.toString().toLowerCase().includes(userInput) ||
          item.Advertiser_Name?.toLowerCase().includes(userInput) ||  
          item.Status?.toString().toLocaleLowerCase().includes(userInput) 
        );
      });
     
      if(userInput && filteredDataa.length==0){
        dispatch(setNoDataFlag(true));
      }else{dispatch(setNoDataFlag(false));}
      dispatch(setTcpaData(filteredDataa));
    } else {
      dispatch(setTcpaData(filterSearchTcpaData));
      dispatch(setNoDataFlag(false));
    }
  }
  const hadleEditIcon = async (tcpa_camp_id: string) => {
    try {
      // setIsLoadingData(true);
      const selectedTcpaCampaigns = tcpaFullData.filter((camp: any) => {
        return camp.tcpaCampaignId === tcpa_camp_id;
      });
          dispatch(setTcpaEditJson(selectedTcpaCampaigns[0]))
     dispatch(setTcpaCampaignIdOnEdit(selectedTcpaCampaigns[0].tcpaCampaignId))
          dispatch(setTcpaEditFlag(true));
         dispatch(updateStateForTCPAEditAction(selectedTcpaCampaigns[0]));
    } catch (error) {
      console.error("Error fetching data for editing", error);
    }
    // finally {
    //   // setIsLoadingData(false);
    // }
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFilterSearchInput(value);
    // dispatch(setFrontEndSearchInput(value));
    searchTable(value)
  }

  const handleFilterSearch = ()=> {
    dispatch(setTcpaSearchInput(''));
  }

  const handleChipRemove = (advertiserID:number) => {
    updatedFilterData = searchFilterData.filter((id: number) => id !== advertiserID);
    dispatch(setSearchFilterData(updatedFilterData));
  };
  
  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Box>
            <Typography className="card-heading">TCPA</Typography>
            <Typography className="card-sub-heading">
              TCPA campaigns
            </Typography>
          </Box>
          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
            <Search value={tcpaFilterSearchFlag?filterSearchInput:tcpaSearchInput}
              searchHandler={tcpaFilterSearchFlag?handleFilter:handelSearch}
            />
            <Link to='/createTcpaCamp' style={{ textDecoration: "none" }}>
              <Button variant="contained" startIcon={<AddIcon />} onClick={()=> setTcpaEditFlag(false)} sx={{ backgroundColor: "#0ae" }} >
                Create TCPA Campaign
              </Button>
            </Link>
          </Box>
        </Box>
      <Box display="flex" gap="16px"  >
          <FormControl style={{width: '98.3%', marginBottom:'0.4%',paddingLeft:'1.3%'}}>
            <InputLabel style={{paddingLeft:'19px'}}>Filter by Advertiser</InputLabel>
            <Select
              multiple
              label="Filter by Advertiser"
              value={searchFilterData}
              onChange={(event) => {handleFilterSearch();
                dispatch(setSearchFilterData(event.target.value as [])) }}
              renderValue={(selected) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {fullData
                    .filter((adv: any) => selected.includes(adv.advertiserID))
                    .map((adv: any) => (
                      <Chip key={adv.SORTID} label={`${adv.advertiserName}`} 
                      onDelete={() => handleChipRemove(adv.advertiserID)}
                      deleteIcon={
                        <IconButton
                          size="small"
                          onClick={() => handleChipRemove(adv.advertiserID)}
                          onMouseEnter={() => setIsMouseOverDelete(true)}
                          onMouseLeave={() => setIsMouseOverDelete(false)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      }/>
                    ))}
                </div>
              )}
              MenuProps={{
                style: {
                  maxHeight: '400px', // Adjust the max height as needed
                  marginTop: '5px', // Adjust the top margin to start below the select field
                },
              }}
              disabled={isMouseOverDelete} 
              style={{ height: '50px' }}
            >
              {fullData
                .filter((adv: any) => adv.advertiserName)
                .sort((a: any, b: any) => a.advertiserName.localeCompare(b.advertiserName))
                .map((adv: any) => (
                  <MenuItem key={adv.SORTID} value={adv.advertiserID}>
                    <Checkbox checked={searchFilterData.includes(adv.advertiserID)} />
                    <ListItemText primary={`${adv.advertiserName} - ${adv.advertiserID}`} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <MMTable
          tableData={tcpaTableData}
          tableHeadCell={tcpaHeadCell}
          isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          fetchInitialData={fetchInitialData}
          editComponentNavigation="/editTcpaCamp"
          editHandler={hadleEditIcon}
        />
      </Paper>
      {/* <CreateMbrands
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          fetchInitialData={fetchInitialData}
          // maxBrandID={maxBrandID}
        /> */}
    </React.Fragment>
  )
}
export default TCPAcampaigns

export const TcpaActionIcons = (props: any) => {
  const { tcpaCampData } = props;
  const [openPopup, setOpenPopup] = React.useState(false);
  const dispatch = useAppDispatch();
  const [openDeletePopup, setDeletePopup] = React.useState(false);

  const fetchData = () => {
    fetchTcpaCampaignData(dispatch, null);
  };

  // let delPayload: { ID: any; mPubID: any; }
  // delPayload = {
  //   ID: rowData?.ID,
  //   mPubID: rowData?.mPubID,
  // }
  const rowData = tcpaCampData;
  const delPayload = tcpaCampData.tcpaCampaign_id;

  return (
    <>
      <Tooltip title="Delete"><DeleteIcon onClick={() => setDeletePopup(true)} color="error" /></Tooltip>
      <DeleteIconPop
        openDeletePopup={openDeletePopup}
        setDeletePopup={setDeletePopup}
        rowData={rowData}
        delPayload={delPayload}
        fetchData={fetchData}
      />
    </>
  )
}
