import React, { useEffect, useMemo, useState } from 'react'
import { RootState } from '../../Store/index'; // Update the path to your Redux store
import { Box, Button, Checkbox, Chip, DialogContentText, FormControl, FormLabel, Input, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, Snackbar, Tooltip, Typography } from '@mui/material'
import MMTable from '../../Component/Table/MMTable'
import AddIcon from '@mui/icons-material/Add';
import { publiUserCampaignHeadCells } from '../../Component/Table/tableInterface';
import './PublisherList.css'
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import Search from '../../Component/Search/Search';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import { saveAs } from 'file-saver';
import { fetchCompApiData, filterPublisherSearch, searchPublisherCampaigns } from '../../Services/publisherTabApis';
import { setFields, setDayCapField, resetForm, statusHandler, specificDayToggle, setSelectedRadioValue, setEditFlag, setExcludeMbrandId, setCapSettingsField } from '../../Store/Slices/PublisherCampaignSlice';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { createPublisherCampAsync, setCampData, setCampFlag, setFrontEndFilSearch, setfilterSearchFlag, updatePublisherCampAsync } from '../../Store/Slices/PublisherTabApisData';
import { fetchPublisherCampApiData } from '../../Services/publisherTabApis';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import MuiAlert from '@mui/material/Alert';
import { DeleteIconPop } from '../Advertiser/Action';
import { setBlockScroll, setIsoLoading, setNoDataFlag, setScrollNext, setSearchFilterData, setSearchInput } from '../../Store/Slices/advertiserTabApisData';
import {useNavigate} from 'react-router-dom'


const PublisherCampaigns = () => {

  const dispatch = useAppDispatch();
  const publiCampaignTableData = useAppSelector((state) => state.publisherCampApi.datacamp);
  const searchFilterData = useAppSelector((state) => state.advertiserCampApi.searchFilterData);
  const isLoading = useAppSelector((state) => state.publisherCampApi.campLoading);
  const compApiData = useAppSelector((state) => state.publisherCampApi.compApiDataCamp);
  const currentCursor = useAppSelector((state) => state.publisherCampApi.currentCampCursor);
  const hasMoreData = useAppSelector((state) => state.publisherCampApi.hasCampMoreData);
  const [openPopup, setOpenPopup] = React.useState(false);
  const fullDataPubli = useAppSelector((state) => state.publisherAccApi.fullData);
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]); // Specify the type as number[]
  const [isMouseOverDelete, setIsMouseOverDelete] = useState(false);
  const filterSearchFlag = useAppSelector((state) => state.publisherCampApi.filterSearchFlag);
  const filterSearchPubData = useAppSelector((state) => state.publisherCampApi.filterSearchPubData);
  const [filterSearchInput, setFilterSearchInput] = useState<string>('');
  // const FrontEndSearchInput = useAppSelector((state) => state.advertiserCampApi.FrontEndSearchInput);
  const frontEndFilSearch = useAppSelector((state) => state.publisherCampApi.frontEndFilSearch);

  let updatedFilterData:any
  let userSortid=localStorage.getItem("userSortid")

  useEffect(() => {
    dispatch(setfilterSearchFlag(false));
    setIsMouseOverDelete(false);
    dispatch(setCampFlag(false));
    if (!searchInput && searchFilterData.length === 0) {
    console.trace("tracedddd")
        if (userSortid) {
    // Extract the last 5 characters dynamically
   let  lastFiveCharacters = userSortid.slice(-5);

    console.log("Last 5 characters:", lastFiveCharacters);
    const payload = {
      "mPubIds": [lastFiveCharacters]
    }
    lastFiveCharacters.length >0 && filterPublisherSearch(payload,dispatch);
  } else {
    console.log("userSortid is not available in localStorage");
  }
  
      dispatch(setBlockScroll(false));
      // If searchInput is empty, fetch the initial data
      dispatch(setFrontEndFilSearch(''));
      dispatch(setScrollNext(false));
      setDebouncedInputValue('');
      // fetchInitialCampData();
      dispatch(setNoDataFlag(false));
      // fetchComCampApiData(dispatch);
    }else {
      
    }
  }, [dispatch, searchInput]);

  useEffect(() => {
    dispatch(setSearchFilterData([]));
    fetchPublisherCampApiData(dispatch, null)
    fetchCompApiData(dispatch);
  }, [])
  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      dispatch(setSearchFilterData([]));
      searchPublisherCampaigns(debouncedInputValue, dispatch, 'campaign')
    }
  }, [debouncedInputValue])
  const fetchInitialCampData = () => {
    // fetchPublisherCampApiData(dispatch, null).then(() => {
    //   dispatch(setScrollNext(true));
    // });
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setCampFlag(true));
      fetchPublisherCampApiData(dispatch, currentCursor);
    }
  };

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = filterSearchPubData.filter(function (item:any) {
          return (
          item.campaign_id?.toString().toLowerCase().includes(userInput) ||
          item.campaign_name?.toLowerCase().includes(userInput) || 
          item.Publisher_ID?.toString().toLowerCase().includes(userInput) ||
          item.Publisher_Name?.toLowerCase().includes(userInput) ||        
          item.Status?.toString().toLocaleLowerCase().includes(userInput) 
          // item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      if(userInput && filteredDataa.length==0){
        dispatch(setNoDataFlag(true));
      }else{dispatch(setNoDataFlag(false));}
      dispatch(setCampData(filteredDataa));
    } else {
      dispatch(setCampData(filterSearchPubData));
      // dispatch(appendCampData(filteredData));
      dispatch(setNoDataFlag(false));

      // dispatch(setFullData(publisherTableData));
    }
  }

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
       setFilterSearchInput(value);
      searchTable(value)
    }

  const handelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchInput(event.target.value));
  }

  return (
    <>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3}   sx={{ flexDirection: { xs: 'column', md: 'row' } }} >
          <Box>
            <Typography className="card-heading">Publisher Campaign</Typography>
            <Typography className="card-sub-heading">
              Publisher Campaigns Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px"  sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}> 
            <Search value={filterSearchFlag?filterSearchInput:searchInput} searchHandler={filterSearchFlag?handleFilter:handelSearch} />
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenPopup(true);
                dispatch(setEditFlag(false));
              }}
              sx={{backgroundColor:"#0ae"}}
            >
              Create Campaign
            </Button>
          </Box>
        </Box>
        <Box display="flex" gap="16px">
        <FormControl style={{ width: '98.3%' , marginBottom:'0.4%',paddingLeft:'1.3%'}}>
  </FormControl>
</Box>

        <MMTable
          tableData={publiCampaignTableData}
          tableHeadCell={publiUserCampaignHeadCells}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          isLoadingMore={isLoading}
          fetchInitialData={fetchInitialCampData}
          searchInput={debouncedInputValue}
        />
      </Paper>
      <CreateUserPubliCampaigns
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        fetchInitialData={fetchInitialCampData}
      />
    </>
  );

}

export default PublisherCampaigns



export const CreateUserPubliCampaigns: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const campaignState = useAppSelector((state: RootState) => state.campaign);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { openPopup, setOpenPopup, rowData, fetchInitialData ,searchInput} = props;
  const fullDataPubli = useAppSelector((state) => state.publisherAccApi.fullData);
  const [selectedFileName, setSelectedFileName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const fullData = useAppSelector((state) => state.publisherCampApi.fullDataCamp);
  const [publisherNameError, setPublisherNameError] = useState(false);
  const [campaignNameError, setCampaignNameError] = useState(false);
  const [compareMbrand, setCompareMbrand] = useState('');
  const searchFilterData = useAppSelector((state) => state.advertiserCampApi.searchFilterData);

  let userSortid=localStorage.getItem("userSortid")
  const getRowDataBycamId = (camId: any) => {
    return fullDataPubli.find((row: any) => row?.SORTID === camId);
  };
  const RowDataPay = getRowDataBycamId(userSortid);

  //extracting data from api with publisher sortid
  const getRowDataById = (camId: string) => {
    return fullData.find((row: any) => row.publisher?.SORTID === camId);
  };
  const createRowData = getRowDataById(campaignState.publisherName);


  const handleFieldChange = (field: keyof typeof campaignState, value: any) => {
    dispatch(setFields({ field, value }));
  };


  const handleNumericField = (field: keyof typeof campaignState.capSettings, value: any) => {
    const numericValue: number | undefined = value === '' ? undefined : Number(value);

    // Check if the value is within the desired range
    if (value === '' || (!isNaN(numericValue as number) && numericValue as number >= 0 && numericValue as number <= 10)) {
      dispatch(setCapSettingsField({ field, value: numericValue }));
    } else {
      
      console.error('Invalid input. Please enter a value between 0 and 10.');
    }
  };




  function isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }
  const downloadSampleCSV = () => {
    let sampleData = [];
    if (campaignState.editFlag === true && createRowData.excludeMbrandID !== undefined &&
      createRowData.excludeMbrandID !== null && !isEmptyObject(createRowData.excludeMbrandID)) {
      // Extract IDs from createRowData.excludeMbrandID
      // //console.log('rowData.excludeMbrandID',rowData.excludeMbrandID);

      const extractedIds = Object.keys(createRowData.excludeMbrandID);
      sampleData = [['mbrandId'], ...extractedIds.map(id => [id])];
    } else {
      sampleData = [
        ['mbrandId'],
        ['12345'],
        ['67890'],
      ];
    }
    // Convert the sample data to CSV format
    const csvContent = sampleData.map((row) => row.join(',')).join('\n');
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    // Trigger the download
    saveAs(blob, 'excludeMbrandId.csv');
  };

  const specificDayHandler = () => {
    dispatch(specificDayToggle());
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedRadioValue = event.target.value;
    dispatch(setSelectedRadioValue(selectedRadioValue));

  };

  const [open, setOpen] = useState(false);
  const [matchingIds, setMatchingIds] = useState<string[]>([]);

  const handleConfirm = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    clearSelectedFile();
    setOpen(false);
  };


  const clearSelectedFile = () => {
    setSelectedFileName('');
    dispatch(setExcludeMbrandId({}))
    // Reset the file input
    const fileInput = document.getElementById('file-input') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Clear the file input value
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // Read and parse the CSV data
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target?.result as string;

        // Parse CSV data and extract ID values into an array
        // const lines = contents.split('\n');
        const lines = contents.split('\n').map(line => line.trim()).filter(Boolean);
        const newIds = lines.slice(1).map(line => line.trim());


        if (campaignState.editFlag) {
          // Check for existing mbrand IDs that match the new IDs
          const matchingIds = Object.keys(createRowData.excludeMbrandID).filter(id => newIds.includes(id));

          if (matchingIds.length > 0) {
            // Display confirmation popup
            setMatchingIds(matchingIds);
            // const confirmDelete = window.confirm(`The following mbrand IDs already exist: ${matchingIds.join(', ')}. Do you want to continue?`);
            setOpen(true);

          }
        }

        // Set the extracted data in state
        const data: { [id: string]: { exclude: boolean } } = {};
        newIds.forEach(id => {
          data[id] = { exclude: true };
        });
        dispatch(setExcludeMbrandId(data));

        // Set the selected file in state
        setSelectedFile(file);
        // Set the selected file name in state
        setSelectedFileName(file.name);

      };
      reader.readAsText(file);
    }
  };


  const handleCreateCampaign = async () => {
     setErrorMessage('');
     setSuccessMessage('');
    if (!campaignState.campaignname.trim()) {
      setCampaignNameError(!campaignState.campaignname.trim());
    } else if (campaignState.campaignname.includes(',')) {
      setErrorMessage('Comma is not allowed in Publisher Camp Name');
      setSnackbarOpen(true);
      return;
    }  else {
      setSuccessMessage('');
      setErrorMessage('');
      if ((campaignState.editFlag === false) && (campaignState.excludedBrandIDAction === 'true') && (Object.keys(campaignState.excludeMbrandID).length === 0)) {
        setErrorMessage('Upload mBrabdIDs Exclusion file');
        setSnackbarOpen(true);
        return;
      }

      if ((campaignState.editFlag) && (campaignState.excludedBrandIDAction === 'true') && (Object.keys(campaignState.excludeMbrandID).length === 0) && (!rowData.excludeMbrandID ? true : Object.keys(rowData.excludeMbrandID).length === 0)) {
        setErrorMessage('Upload mBrabdIDs Exclusion file');
        setSnackbarOpen(true);
        return;
      }
      //console.log('selectedFile',selectedFile);
      setOpenPopup(false);
      dispatch(setIsoLoading(true));
      setPublisherNameError(false);
      setCampaignNameError(false);
     
      const payload: any = {
        advertiserStatus: RowDataPay?.status,
        campaignname: campaignState.campaignname,
        campaignType: 'publisherCampaign',
        publisher: RowDataPay,
        capSettings: {
          daily: (campaignState.capSettings.daily == null) ? 0 : campaignState.capSettings.daily,
          dayCap: {
            monday: (campaignState.capSettings.monday == null) ? 0 : campaignState.capSettings.monday,
            tuesday: (campaignState.capSettings.tuesday == null) ? 0 : campaignState.capSettings.tuesday,
            wednesday: (campaignState.capSettings.wednesday == null) ? 0 : campaignState.capSettings.wednesday,
            thursday: (campaignState.capSettings.thursday == null) ? 0 : campaignState.capSettings.thursday,
            friday: (campaignState.capSettings.friday == null) ? 0 : campaignState.capSettings.friday,
            saturday: (campaignState.capSettings.saturday == null) ? 0 : campaignState.capSettings.saturday,
            sunday: (campaignState.capSettings.sunday == null) ? 0 : campaignState.capSettings.sunday
          },
          enableDayCap: campaignState.enableDayCap,
          monthly: (campaignState.capSettings.monthly == null) ? 0 : campaignState.capSettings.monthly,
          total: 500,
          weekly: (campaignState.capSettings.weekly == null) ? 0 : campaignState.capSettings.weekly,
        },
        excludedBrandIdToggle: campaignState.excludedBrandIDAction,
        excludeMbrandID: campaignState.excludeMbrandID,
        publisherID: RowDataPay?.mPubID,
        mPubID:  RowDataPay?.mPubID,
        publisherName: userSortid,
        source: campaignState.description,
        status: campaignState.status,
        website: "",
      }


      let requestData
      if (rowData) {
        requestData = {
          ID: rowData.ID,
          SORTID: rowData?.campaignID,
          advertiserStatus: rowData?.publisher?.status,
          createdOn: rowData?.createdOn,
          campaignname: campaignState.campaignname,
          campaignType: rowData?.campaignType,
          publisher: RowDataPay,
          capSettings: {
            daily: (campaignState.capSettings.daily == null) ? 0 : campaignState.capSettings.daily,
            dayCap: {
              monday: (campaignState.capSettings.monday == null) ? 0 : campaignState.capSettings.monday,
              tuesday: (campaignState.capSettings.tuesday == null) ? 0 : campaignState.capSettings.tuesday,
              wednesday: (campaignState.capSettings.wednesday == null) ? 0 : campaignState.capSettings.wednesday,
              thursday: (campaignState.capSettings.thursday == null) ? 0 : campaignState.capSettings.thursday,
              friday: (campaignState.capSettings.friday == null) ? 0 : campaignState.capSettings.friday,
              saturday: (campaignState.capSettings.saturday == null) ? 0 : campaignState.capSettings.saturday,
              sunday: (campaignState.capSettings.sunday == null) ? 0 : campaignState.capSettings.sunday
            },
            enableDayCap: campaignState.enableDayCap,
            monthly: (campaignState.capSettings.monthly == null) ? 0 : campaignState.capSettings.monthly,
            total: 500,
            weekly: (campaignState.capSettings.weekly == null) ? 0 : campaignState.capSettings.weekly,
          },
          excludedBrandIdToggle: campaignState.excludedBrandIDAction,
          excludeMbrandID: (campaignState.excludedBrandIDAction == 'false') ? {} : ((Object.keys(campaignState.excludeMbrandID).length === 0) ? rowData?.excludeMbrandID : campaignState.excludeMbrandID), //campaignState.excludeMbrandID,
          publisherID: rowData?.publisherID,
          mPubID:  RowDataPay?.mPubID,
          publisherName: rowData?.publisher?.SORTID,
          source: campaignState.description,
          status: campaignState.status,
          website: "",
        };
      }
      //console.log("campaignState.excludeMbrandID",campaignState.excludeMbrandID,'rowData?.excludeMbrandID',rowData?.excludeMbrandID)
      try {
        if (campaignState.editFlag) {
          try{
            await dispatch(updatePublisherCampAsync(requestData)).then(() =>  setSuccessMessage('Campaign Updated Successfully'));
            // setSuccessMessage('Campaign Updated Successfully');
          }catch (error){
            console.log("Error in Updating the Campaign", error);
          }
        } else {
          try{
           const response = await dispatch(createPublisherCampAsync(payload));
           response && setSuccessMessage('Campaign Created Successfully');
          }catch(error){
            console.log("Error in Creating the Campaign", error);
            dispatch(setIsoLoading(false));
          }
        }
        dispatch(setCampFlag(false));
        setOpenPopup(false);
        setSnackbarOpen(true);
        // !rowData && fetchInitialData();
        // if(rowData && searchInput ){searchPublisherCampaigns(searchInput, dispatch, 'campaign')}
         if(userSortid ){
          let  lastFiveCharacters = userSortid.slice(-5);
          const payload = {
            "mPubIds": [lastFiveCharacters]}
    
        lastFiveCharacters.length >0 && filterPublisherSearch(payload,dispatch);
        }
        // fetchComCampApiData(dispatch);
        fetchPublisherCampApiData(dispatch, null)
        // fetchCompApiData(dispatch);
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('Failed to create publisher');
        setSnackbarOpen(false);
      }
      // //console.log(campaignState);
      dispatch(setIsoLoading(false));
      // !rowData &&  dispatch(setSearchInput(''));
      dispatch(resetForm());
      dispatch(setExcludeMbrandId({}));
      clearSelectedFile();
      
    }
  };

  const cancelButton = () => {
    setOpenPopup(false);
    dispatch(resetForm());
    setSelectedFileName('');
    dispatch(setExcludeMbrandId({}))
    setPublisherNameError(false);
    setCampaignNameError(false);
  };




  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };


  const [activeField, setActiveField] = useState<string | null>(null);
  const handleTextFieldClick = (field: string) => {
    setActiveField(field);
  };

  const handleTextFieldBlur = () => {
    setActiveField(null);
  };

  const isFieldActive = (field: string) => activeField === field;

  return (
    <div>
      <Dialog open={openPopup}  >
        <DialogTitle>{campaignState.editFlag ? "Update" : 'Create'}  Campaign</DialogTitle>
        <div className="close-button" onClick={cancelButton}><CloseIcon style={{ fontSize: '32px' }} /></div>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
              disabled
                fullWidth
                value={userSortid}
                onChange={(e: { target: { value: any; }; }) => {
                  setPublisherNameError(false);
                  handleFieldChange('publisherName', e.target.value)
                }}
                error={publisherNameError}
                >

                {fullDataPubli.filter((pub: any) => pub.publisherName) // Filter out elements with undefined or null publisherName
                  .sort((a: any, b: any) => a.publisherName.localeCompare(b.publisherName))
                  .map((pub: any, index: any) => (
                    <MenuItem key={pub.mPubID} value={pub.SORTID}>
                      {`${pub.publisherName} - ${pub.mPubID}`}
                    </MenuItem>
                  ))}
              </Select>
              {publisherNameError && <div style={{ color: '#d32f2f', fontSize: '13px', paddingLeft: '12px' }}></div>}
            </Grid>

            <Grid item xs={12}>
              <TextField label="Name*" fullWidth
                value={campaignState.campaignname}
                onChange={(e: { target: { value: any; }; }) => { setCampaignNameError(false); handleFieldChange('campaignname', e.target.value) }}
                error={campaignNameError}
                helperText={campaignNameError ? 'Campaign Name is mandatory' : ''} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Description" fullWidth rows={4} variant="outlined" multiline
                value={campaignState.description}
                onChange={(e: { target: { value: any; }; }) => handleFieldChange('description', e.target.value)} />
            </Grid >
            {/* <Grid item xs={12}>
              <FormControlLabel
                // control={<Switch defaultChecked />}
                label="Status"
                labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
                control={<Switch checked={campaignState.status === 'Active'} onChange={() => dispatch(statusHandler())} />}/>
            </Grid> */}
            <Grid item xs={12}>
              <div style={{ margin: 5, fontWeight: "bold" }}>Cap Settings</div>
            </Grid>
            <Grid item xs={3}>
              {/* <TextField
                label="Daily Cap" value={campaignState.capSettings.daily}
                onChange={(e: { target: { value: any; }; }) => handleNumericField('daily', Number(e.target.value))}
                 type="number"
                InputLabelProps={{ shrink: true, }}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
              /> */}
              <TextField
                label="Daily Cap"
                value={campaignState.capSettings.daily}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumericField('daily', e.target.value)}
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                helperText={isFieldActive('daily') ? "Leave 0 for unlimited" : null}
                onClick={() => handleTextFieldClick('daily')}
                onBlur={handleTextFieldBlur}
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Weekly Cap"
                value={campaignState.capSettings.weekly}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumericField('weekly', e.target.value)}
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                helperText={isFieldActive('weekly') ? "Leave 0 for unlimited" : null}
                onClick={() => handleTextFieldClick('weekly')}
                onBlur={handleTextFieldBlur}
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Monthly Cap"
                value={campaignState.capSettings.monthly}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumericField('monthly', e.target.value)}
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                helperText={isFieldActive('monthly') ? "Leave 0 for unlimited" : null}
                onClick={() => handleTextFieldClick('monthly')}
                onBlur={handleTextFieldBlur}
                size="small"
              />
            </Grid>
            <div>
              <br /><br /><br /><br />
            </div>
            <Grid item xs={12}>
              <FormControlLabel
                label="Specific Day Cap"
                labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
                control={<Switch checked={campaignState.enableDayCap} onChange={specificDayHandler} />}
              />
            </Grid>

            {campaignState.enableDayCap &&
              <><Grid item xs={3}>
                {/* <TextField
                  label="Mon" value={campaignState.capSettings.monday}
                  onChange={(e: { target: { value: any; }; }) => handleNumericField('monday', Number(e.target.value))}
                  type="number"
                  InputLabelProps={{ shrink: true, }}
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }} /> */}
                <TextField
                  label="Mon"
                  value={campaignState.capSettings.monday}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumericField('monday', e.target.value)}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  helperText={isFieldActive('monday') ? "Leave 0 for unlimited" : null}
                  onClick={() => handleTextFieldClick('monday')}
                  onBlur={handleTextFieldBlur}
                  size="small"
                />
              </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Tue"
                    value={campaignState.capSettings.tuesday}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumericField('tuesday', e.target.value)}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    helperText={isFieldActive('tuesday') ? "Leave 0 for unlimited" : null}
                    onClick={() => handleTextFieldClick('tuesday')}
                    onBlur={handleTextFieldBlur}
                    size="small"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Wed"
                    value={campaignState.capSettings.wednesday}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumericField('wednesday', e.target.value)}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    helperText={isFieldActive('wednesday') ? "Leave 0 for unlimited" : null}
                    onClick={() => handleTextFieldClick('wednesday')}
                    onBlur={handleTextFieldBlur}
                    size="small"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Thu"
                    value={campaignState.capSettings.thursday}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumericField('thursday', e.target.value)}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    helperText={isFieldActive('thursday') ? "Leave 0 for unlimited" : null}
                    onClick={() => handleTextFieldClick('thursday')}
                    onBlur={handleTextFieldBlur}
                    size="small"
                  />
                </Grid>
                <div>
                  <br /><br /><br /><br />
                </div>
                <Grid item xs={3}>
                  <TextField
                    label="Fri"
                    value={campaignState.capSettings.friday}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumericField('friday', e.target.value)}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    helperText={isFieldActive('friday') ? "Leave 0 for unlimited" : null}
                    onClick={() => handleTextFieldClick('friday')}
                    onBlur={handleTextFieldBlur}
                    size="small"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Sat"
                    value={campaignState.capSettings.saturday}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumericField('saturday', e.target.value)}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    helperText={isFieldActive('saturday') ? "Leave 0 for unlimited" : null}
                    onClick={() => handleTextFieldClick('saturday')}
                    onBlur={handleTextFieldBlur}
                    size="small"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Sun"
                    value={campaignState.capSettings.sunday}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumericField('sunday', e.target.value)}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    helperText={isFieldActive('sunday') ? "Leave 0 for unlimited" : null}
                    onClick={() => handleTextFieldClick('sunday')}
                    onBlur={handleTextFieldBlur}
                    size="small"
                  />
                </Grid>
                <div>
                  <br /><br /><br /><br />
                </div>
              </>}
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={campaignState.excludedBrandIDAction} // Replace with your selected value from state
                  onChange={handleRadioChange}>
                  <FormControlLabel value="true" control={<Radio />} label="Add/Replace Excluded mBrandID" />
                  <FormControlLabel value="false" control={<Radio />} label="Remove mBrand List" />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={8}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="file-input">
                  <IconButton
                    color="primary"
                    aria-label="upload file"
                    component="span"
                    disabled={campaignState.excludedBrandIDAction == "false"}>
                    <AttachFileIcon style={{ marginTop: "19px", fontSize: '30px' }} />
                  </IconButton>
                </label>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-helperText"
                  label="Upload mBrandIDs Exclusion File"
                  helperText="Select .csv files"
                  variant="standard"
                  value={selectedFileName}
                  disabled={campaignState.excludedBrandIDAction == "false"}
                />
                {selectedFileName && ( // Display the "X" button only when a file is selected
                  <IconButton
                    style={{ marginRight: '18px' }}
                    color="primary"
                    aria-label="clear file"
                    component="span"
                    disabled={campaignState.excludedBrandIDAction == "false"}
                    onClick={clearSelectedFile}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
              <input
                disabled={campaignState.excludedBrandIDAction == "false"}
                type="file"
                accept=".csv"
                id="file-input"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

            </Grid>

            <Grid item xs={4}>
              <Button  title="Download file" disabled={campaignState.excludedBrandIDAction == "false"} onClick={downloadSampleCSV}><DownloadIcon style={{ marginTop: "13px", fontSize: '40px' }} />{(campaignState.editFlag && createRowData?.excludeMbrandID !== undefined &&
                createRowData?.excludeMbrandID !== null && !isEmptyObject(createRowData?.excludeMbrandID)) ? '' : ''}</Button>
              {/* <Button disabled={campaignState.excludedBrandIDAction == "false"} onClick={downloadSampleCSV}><DownloadIcon />{(campaignState.editFlag && createRowData?.excludeMbrandID !== undefined &&
                createRowData?.excludeMbrandID !== null && !isEmptyObject(createRowData?.excludeMbrandID)) ? 'DOWNLOAD LAST UPLOADED FILE' : 'DOWNLOAD SAMPLE TEMPLATE'}</Button> */}
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                // control={<Switch defaultChecked />}
                label="Status"
                labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
                control={<Switch checked={campaignState.status === 'Active'} onChange={() => dispatch(statusHandler())} />} />
            </Grid>
          </Grid>
        </DialogContent >
        <DialogActions style={{ paddingBottom: "15px", paddingRight: "20px" }}>
          <Button onClick={cancelButton}  style={{ color: 'red' }} >CANCEL</Button>
          <Button variant="contained" color="primary" onClick={handleCreateCampaign}>{campaignState.editFlag ? "UPDATE" : 'CREATE'}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
      <Dialog open={open} onClose={handleCancel} maxWidth="xs" >
        <DialogTitle style={{ backgroundColor: 'rgb(198, 40, 40)', color: 'white' }}>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginTop: '10px', color: 'black' }}>
            The following mbrand IDs already exist: {matchingIds.join(', ')}. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


export const PubUserCamActionIcons = (props: any) => {
  const { CamID, fetchInitialData , searchInputDe} = props;
  const [openPopup, setOpenPopup] = React.useState(false);
  const dispatch = useAppDispatch();
  const fullData = useAppSelector((state) => state.publisherCampApi.fullDataCamp);
  const [openDeletePopup, setDeletePopup] = React.useState(false);
  const campaignState = useAppSelector((state: RootState) => state.campaign);


  const getRowDataById = (camId: string) => {
    return fullData.find((row: any) => row.campaignID === camId);
  };
  const rowData = getRowDataById(CamID);

  const editFetch = () => {
    if (campaignState.editFlag && rowData) {
      const fieldsToUpdate = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ];

      fieldsToUpdate.forEach((field: any) => {
        dispatch(setCapSettingsField({ field, value: rowData.capSettings?.dayCap?.[field] || 0 }));
      });

      const fields = [
        'campaignname',
        'status',
        'excludeMbrandID'
        // 'publisherName',
      ]
      fields.forEach((field: any) => {
        dispatch(setFields({ field, value: rowData?.[field] || '' }));
      });

      const dayCap = [
        'daily',
        'weekly',
        'monthly',
        // 'enableDayCap',
      ]
      dayCap.forEach((field: any) => {
        dispatch(setCapSettingsField({ field, value: rowData.capSettings?.[field] || 0 }));
      });
      //console.log('excludeMbrandID', campaignState.excludeMbrandID);
      if (rowData.excludeMbrandID && Object.keys(rowData.excludeMbrandID).length > 0) {
        dispatch(setFields({ field: 'excludedBrandIDAction', value: 'true' }));
      } else {
        dispatch(setFields({ field: 'excludedBrandIDAction', value: 'false' }));
      }
      // dispatch(setFields({ field: 'excludedBrandIDAction', value: rowData?.excludedBrandIdToggle || 'false' }));

      dispatch(setFields({ field: 'excludeMbrandID', value: rowData?.excludeMbrandID || {} }));
      dispatch(setFields({ field: 'description', value: rowData.source || '' }));
      dispatch(setFields({ field: 'publisherName', value: rowData.publisher?.SORTID || '' }))
      dispatch(setFields({ field: 'enableDayCap', value: rowData.capSettings?.enableDayCap || false }));
      ;
    }
  };

  const handleEditButtonClick = () => {
    editFetch();
    setOpenPopup(true);
  };

  const fetchData = () => {
    // fetchPublisherCampApiData(dispatch, null);
    //  fetchComCampApiData(dispatch);
    fetchPublisherCampApiData(dispatch, null)
  };

  const delPayload = rowData?.SORTID;
  return (
    <div>
      <Tooltip title="Edit">
        <EditIcon onClick={handleEditButtonClick} color="primary" /></Tooltip>
      {/* <DeleteIcon onClick={() => //console.log("delete ",rowData,fullData)} color="error" /> */}
      <Tooltip title="Delete"><DeleteIcon onClick={() => setDeletePopup(true)} color="error" /></Tooltip>
      <CreateUserPubliCampaigns openPopup={openPopup} setOpenPopup={setOpenPopup}
        rowData={rowData} CamID={CamID} fetchInitialData={fetchInitialData} searchInput={searchInputDe} />
      <DeleteIconPop
        openDeletePopup={openDeletePopup}
        setDeletePopup={setDeletePopup}
        rowData={rowData}
        delPayload={delPayload}
        fetchData={fetchData}
        searchInputDe={searchInputDe}
      />
    </div>
  )
}
