
import { FormControlLabel, FormGroup, Paper, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, Modal, Typography, FormControl, InputAdornment, OutlinedInput, InputLabel, Checkbox, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { updateMPrograms } from '../../../../Store/Slices/CreateAdvCampaignSlice';
import { TRACKING_URL_FIELD_ERROR } from '../../../../Mock/MessageContstant';
interface data {

    label: string;
    value: string;
    id: string;
    status: boolean;
    trackingUrl: string;
    trackUrlError:string;
    areaStudy: string;
    bid: string;
    bonusDescription: string,
    enable: boolean;
}



const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#FFFFFF',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
    borderRadius: "4px",

};




interface MMTabContentProps {
    // Define the props you want to pass dynamically to the content component
    data: data[] | []
}

const MMTabContent: React.FC<MMTabContentProps> = (props) => {
    const { data } = props
    return (
        <Paper>
            <ProgramTable tableContent={data} />
        </Paper>
    )
}

export default MMTabContent

// What is the objective of this component , we need to show program its bid price , status and tracking url

// interface programTable{
//     tableContent:data[]
// }
const ProgramTable = (props: any) => {
    const { tableContent } = props
    // console.log("Program content data", tableContent)
    const [rowFormData, setRowFormData] = useState<data[]>(tableContent);
    const [errorFlag, setErrorFlag] = useState<boolean>(false);
    const positiveNumberRegex = /^\d+(\.\d+)?$/;


    const [isSelectAll, setSelectAll] = useState(false);
    const dispatch = useAppDispatch()
    const handleBidInputField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {

        const { name, value } = event.target;


        if (value === '' || parseInt(value, 10) >= 0) {
            // setInputValue(value === '' ? '' : parseInt(value, 10));
            setRowFormData(prevData => prevData.map(item => ((item.id ? item.id : item.value) === id ? { ...item, [name]: value } : item)));
        }
        // setRowFormData(prevData => prevData.map(item => ((item.id ? item.id : item.value) === id ? { ...item, [name]: value } : item)));


    }



    const handleStatus = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        // //console.log("event" , event.target.name)
        const { name, checked } = event.target;
        //console.log("id", id, "checked", checked)

        setRowFormData(prevData => prevData.map(item => (item.id === id ? { ...item, [name]: checked } : item)));
      

    }
    const handleTrackURLInputField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

       
        if (urlPattern.test(value)) {
            setModaltrackingUrl(value)
            setTrackingURLError("")
            setErrorFlag(false);
            setRowFormData(prevData => prevData.map(item => (item.id === modalId ? { ...item, trackingUrl:value, 
            trackUrlError:'' } : item)))
        } else {
            //   dispatch(storeTrackingURLError("PLease provide valid URL"))
            value == '' ?
             setErrorFlag(false) 
             : setErrorFlag(true);
            value == '' ? setTrackingURLError('') : setTrackingURLError(TRACKING_URL_FIELD_ERROR)
            // setRowFormData(prevData => prevData.map(item => (item.id === modalId ? { ...item, [name]: value } : item)));
            setModaltrackingUrl(value)
        }

        // setRowFormData(prevData => prevData.map(item => (item.id=== modalId ? { ...item, [name]: value } : item)));
    }

    //console.log("rowFormData", rowFormData)
    const [open, setOpen] = React.useState(false);
    const [modalId, setModalId] = React.useState("100")
    const [modalBonusDesc, setmodalBonusDesc] = React.useState('')
    const [modaltrackingUrl, setModaltrackingUrl] = React.useState("")
  
 
    const generateModalInputValue = (MODAL_ID: string) => {
        // console.log("modal date", MODAL_ID);
        const currentModalData = rowFormData.filter(item => item?.id === MODAL_ID)[0]
        // console.log("Current modal data", currentModalData);
        const bonusDesc = currentModalData?.bonusDescription
        const trackURL = currentModalData?.trackingUrl
        const trackUrlError=currentModalData?.trackUrlError?currentModalData?.trackUrlError: '';

        setmodalBonusDesc(bonusDesc);
        setModaltrackingUrl(trackURL);
        setTrackingURLError(trackUrlError)

    }
    const [trackingUrlError, setTrackingURLError] = useState('')
    const handleOpen = (id: string) => {
        setOpen(true)
        setModalId(id)
        generateModalInputValue(id)
    };
    const handleClose = () => {
        setErrorFlag(false);
        setOpen(false);
        setRowFormData(prevData => prevData.map(item => (item.id === modalId ? { ...item, trackingUrl: modaltrackingUrl, bonusDescription: modalBonusDesc, trackUrlError:trackingUrlError } : item)))

    }

    const handleDialogClose=(event:any , reason:string)=>{
        if(reason !=='backdropClick'){
            setErrorFlag(false);
            setOpen(false);
            setRowFormData(prevData => prevData.map(item => (item.id === modalId ? { ...item, trackingUrl: modaltrackingUrl, bonusDescription: modalBonusDesc, trackUrlError:trackingUrlError } : item)))
        }
    }
    const handleCancel = () => {
        setOpen(false);
        setErrorFlag(false);
        if (errorFlag) {
            const tUrl = rowFormData.filter(item => item?.id === modalId)[0]?.trackingUrl
            if (!tUrl) {
                setRowFormData(prevData =>
                    prevData.map(item =>
                        item.id === modalId
                            ? {
                                ...item,
                                //   bonusDescription: '', // Clear bonusDescription
                                trackingUrl: '', // Clear trackingUrl
                            }
                            : item
                    )
                );

            }
        }

        setTrackingURLError('');
    };
    // console.log("error flag", errorFlag)
    //  const isAddModalDisabled=(MODAL_ID:string)=>{
    //     // rowFormData.filter(item=>item?.id===modalId)[0]?.bonusDescription?.length<=3 ||  !rowFormData.filter(item=>item?.id===modalId)[0]?.bonusDescription || trackingUrlError.length>1
    // console.log("error flag", errorFlag)
    //     const currentModalData=rowFormData.filter(item=>item?.id===MODAL_ID)[0]
    //     const bonusDesc=currentModalData?.bonusDescription
    //     const trackURL=currentModalData?.trackingUrl
    //     // console.log("bonus desc", modalBonusDesc);
    //     // console.log("trackURL", modaltrackingUrl);
    //     // if((bonusDesc || trackURL) && !(trackingUrlError.length>1)){
    //     //     return false
    //     // }else{
    //     //     return true   
    //     // }

    //     if(( modalBonusDesc || modaltrackingUrl) && !(trackingUrlError.length>1)){
    //         return false
    //     }else{
    //         return true   
    //     }
    //     // return true
    //  }
    const handelAddDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        // setRowFormData(prevData => prevData.map(item => (item.id === modalId ? { ...item, [name]: value } : item)));
        setmodalBonusDesc(value);
    }
    const handelSelectAllCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log(event.target.checked)
        const { checked } = event.target;
        if (event.target.checked) {
            setRowFormData(prevData => prevData.map(item => ({ ...item, enable: checked })));
        } else {

            setRowFormData(prevData => prevData.map(item => ({ ...item, enable: checked })));
        }
    }

    const formatData = (ip: any) => {
        const op: any = {}
        ip.forEach((item: any) => {
            op[item.value] = item
        });
        return op;
    }


    const checkSelectAllStatus = () => {
        // const { yourData } = this.state; // Replace with the actual name of your data array
        const allSelected = rowFormData.every(item => item.enable === true);
        const allDeselected = rowFormData.every(item => item.enable === false);

        if (allSelected) {
            setSelectAll(true)
        } else {
            setSelectAll(false);
        }
    };
    console.log("ROW program content", rowFormData)
    useEffect(() => {
        console.log("ROW FROM DATA", rowFormData)
        const programRowData = formatData(rowFormData)
        //console.log("row form data pt", rowFormData)
        //console.log("ProwFormData", programRowData)
        checkSelectAllStatus();
        dispatch(updateMPrograms(programRowData))
    }, [rowFormData])


    //console.log("IS SELECT ALL", isSelectAll)
    return (
        <Paper>
            <Box className='select-all-container'>
                <Box className="select-action">

                    {/* <FormControlLabel control={<Checkbox onChange={handelSelectAllCheckBox}  checked={isSelectAll}/>}   label="Select All" /> */}

                    <FormGroup sx={{ m: 1, minWidth: "100%", p: 1 }}>
                        <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} name='status' checked={isSelectAll} onChange={handelSelectAllCheckBox} />}
                            label="Select All"
                        />
                    </FormGroup>
                </Box>
            </Box>
            <Box className='no-more-tables responsive-tbl'>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell >Program Name</TableCell>
                            <TableCell sx={{ paddingLeft: "30px" }}>Bid Price</TableCell>
                            <TableCell  >Status</TableCell>
                            {/* <TableCell >Tracking URL</TableCell>*/}
                            <TableCell >Add Description</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rowFormData.map((item: data, index: number) => {
                                return (
                                    <TableRow key={index}  sx={{backgroundColor: item?.trackUrlError?.length>1?"#f3cdb6":""}} >
                                        <TableCell data-title='Program Name'>{item.label}</TableCell>
                                        <TableCell data-title='Bid Price'>
                                            <FormControl sx={{ width: { xs: '100%', md: '40%' }, my: 1 }}>
                                                <InputLabel htmlFor="outlined-adornment-amount">Base bid</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    label="Amount"
                                                    value={item.bid}
                                                    // name={}
                                                    name='bid'
                                                    onChange={(event) => {
                                                        handleBidInputField(event, item.id ? item.id : item.value)
                                                    }}
                                                    sx={{
                                                        // Adjust padding for the value inside OutlinedInput
                                                        "& input": {
                                                            padding: ("12px 14px 12px 0px")
                                                        },
                                                    }}
                                                />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell data-title='Status'>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{ m: 1 }}
                                                        name={"enable"}
                                                        checked={item.enable}
                                                        onChange={(event) => {
                                                            handleStatus(event, item.id)
                                                        }}
                                                    />}
                                                    label=""
                                                />
                                            </FormGroup>
                                        </TableCell>
                                     
                                        <TableCell data-title={item.bonusDescription ? "Description Added" : "Add Description"}>

                                            <Button variant="outlined" startIcon={item.bonusDescription ? <DoneIcon /> : <AddIcon /> }
                                                onClick={() => handleOpen(item.id)}
                                                disabled={!item.enable}
                                                sx={{
                                                    backgroundColor: "#FFF",
                                                    border: item.trackUrlError?"1px solid red":"1px solid #0AE",
                                                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                                                    borderRadius: "8px",
                                                    color: item.trackUrlError?'red':"#00AAEE",
                                                    minWidth: "150px",
                                                    height: "45px",
                                                    wordBreak:'normal'
                                                }}
                                            >
                                                {item.bonusDescription ? "Description Added" : "Add Description"}
                                            </Button>

                                        </TableCell>


                                    </TableRow>
                                )
                            })
                        }


                    </TableBody>
                </Table>
            </Box>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth="sm"
                fullWidth
                // BackdropProps={{ onClick: (event) => event.stopPropagation() }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Program Description
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box className="bonus-description" pt={1} mb={2}>
                        <TextField fullWidth label="Add Description" multiline maxRows={4} name='bonusDescription' onChange={handelAddDescription}
                            // value={rowFormData.filter(item=>item?.id===modalId)[0]?.bonusDescription}
                            value={modalBonusDesc}/>
                    </Box>
                    <Box className="bonus-description">
                        <TextField
                            label='Track URL'
                            multiline
                            maxRows={3}
                            fullWidth
                            // value={rowFormData.filter(item=>item?.id===modalId)[0]?.trackingUrl}
                            value={modaltrackingUrl}
                            name="trackingUrl"
                            error={trackingUrlError.length > 1}
                            helperText={trackingUrlError}
                            onChange={(e) => handleTrackURLInputField(e)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{px:"24px", pb:"20px"}}>
                    <Box className='modal-buttons' >
                        <Button
                            sx={{
                                backgroundColor: "#FFF",
                                border: "1px solid #0AE",
                                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                                borderRadius: "6px",
                                color: "#00AAEE",
                                width: "100px",
                                height: "45px",
                                minWidth: "100px"
                            }}
                            onClick={handleCancel}
                        >
                            cancel
                        </Button>

                        <Button sx={{
                            backgroundColor: " #00AAEE", "&.MuiButtonBase-root:hover": {
                                bgcolor: "#00AAEE !important"
                            },
                            "&:hover": { backgroundColor: "#00AAEE" },
                            "&:disabled": {
                                backgroundColor: "lightgrey",
                                borderColor: "#fff"
                            },
                            border: "1px solid #0AE",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            borderRadius: "6px",
                            color: "#ffff",
                            height: "45px",
                            minWidth: "100px"
                        }}
                            // disabled={rowFormData.filter(item=>item?.id===modalId)[0]?.bonusDescription?.length<=3 ||  !rowFormData.filter(item=>item?.id===modalId)[0]?.bonusDescription || trackingUrlError.length>1}
                            //  disabled={isAddModalDisabled(modalId)}
                            disabled={errorFlag === true}
                            onClick={handleClose}
                        >
                            Add
                        </Button>
                    </Box>
                </DialogActions>


            </Dialog>
        </Paper>
    )
}

