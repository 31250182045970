import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { CircleLoader } from 'react-spinners';
// import SkeletonLoader from '../../../../Component/Skeleton Loader';
import { fetchAllPublisherCampaignsData } from '../../../../service/Publisher';
// import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { setIsoLoading } from '../../../../Store/Slices/advertiserTabApisData';
import { createCampaignBarDatasets } from '../../../../Store/Slices/Publisher-role/Dashboard/utils';
import { Intraday } from './Intraday';
import Overall from './Overall';

const fetchCampaigns = async () => {
  const userId = localStorage.getItem('userSortid')
 
  let lastFiveCharacters
  if (userId) {
    lastFiveCharacters = userId.slice(-5);
  }

  const arg2 = {
    path: "publishers/campaign",
    queryParamsObject: {
      publisherID: lastFiveCharacters
    }
  }
  const response = await fetchAllPublisherCampaignsData(arg2.path, arg2.queryParamsObject)
  return response.data
}
const DashboardPUB = () => {
  const [campaignDropdownData, setCampaignDropDownData] = useState<any[]>([])
  const [barDataSet, setbarDataSet] = useState<any>()
  const [loading, setLoading] = useState(true);
const location=useLocation()
console.log('first log;;;; pathName', location.pathname)
 const getPubCampaignData=async()=>{
  setIsoLoading(true);
  const res = fetchCampaigns()
  res.then((resT) => {
    const ddData = resT.map((campaign: any) => {
      return {
        label: campaign.campaignname,
        value: `${campaign.SORTID}`
      }
    })

    const campaignBarDataSets = createCampaignBarDatasets(resT)
    setCampaignDropDownData(ddData);
    setbarDataSet(campaignBarDataSets)
    setLoading(false)


  })
 }

 const getVendorAdsData=()=>{
  console.log("vendor add Data")
  // here we make both api call and store campaigns and advertiser dropdown value and we need to make another Api call to fire API on dropdown  changes.
 }
  useEffect(() => {
    getPubCampaignData()
    switch (location.pathname) {
      case '/overall':
      //  return <Overall campaignDropDownMenus={campaignDropdownData} defaultBarDataSet={barDataSet}  dashboardType={'publisher'}/>
      getPubCampaignData()
      break;
      case '/intraday':
        getPubCampaignData()
        break;
      case '/adv-intraday':
        getVendorAdsData()
        break;
      case '/adv-overall':
        getVendorAdsData()
      break;
      default:
        // Handle unknown paths or render a default component
        break;
    }
  }, [])

console.log("Bar data set log -3 ", barDataSet)


const renderContent = () => {
  switch (location.pathname) {
    case '/overall':
     return <Overall campaignDropDownMenus={campaignDropdownData} defaultBarDataSet={barDataSet}  dashboardType={'publisher'}/>
    case '/intraday':
     return <Intraday campaignDropDownMenus={campaignDropdownData} defaultBarDataSet={barDataSet}  dashboardType={'publisher'}/>
    case '/adv-intraday':
      return <Intraday campaignDropDownMenus={campaignDropdownData} defaultBarDataSet={barDataSet}  dashboardType="advertiser"/>
    case '/adv-overall':
      return <Overall campaignDropDownMenus={campaignDropdownData} defaultBarDataSet={barDataSet} dashboardType="advertiser" />
    default:
      // Handle unknown paths or render a default component
      break;
  }
};




  return (
   
    <>{
      loading ?
      <Box p={3} display='flex' justifyContent={'center'} alignItems={'center'} height={"100vh"}>
         
        <CircleLoader color="#00BFFF"loading={loading? true : false} size={60} />
    
      </Box>
           : 
      
        
           renderContent()

    }
    </>
   
  )
}

export default DashboardPUB


// (location.pathname==='/overall')? <Overall campaignDropDownMenus={campaignDropdownData} defaultBarDataSet={barDataSet} />:  <Intraday campaignDropDownMenus={campaignDropdownData} defaultBarDataSet={barDataSet} />