import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface seoWebsiteState {
  template: string;
  primaryColor: string;
  secondaryColor: string;
  areaStudy: string;
  mProgram: string;
  degreeInterest: string;
  widgetButton: string;
  // bannerRequestToggle: boolean;
  bannerRequestInfo: string;
  onlineSchoolsMatching: boolean;
  requestInformation: boolean;
  footerContent: string;
  footerContentToggle: boolean;
  footerLinks: { key: any; value: any }[];
  footerLinksToggle: boolean;
  // copyright: string;
  // copyrightToggle: boolean;
  advertiserDisclosure: string;
  advertiserDisToggle: boolean;
  advertiserMessage: string;
  advertiserLink: string;
  pubId: string;
  pubCampaignId: string;
  // defaultURL: [];
  defaultURL: { key: any; value: any }[];
  footerLogo: string;
  favIcon: string;
  successMessage: string;
  errorMessage: string;
  snackbarOpen: boolean;
  currentStepperIndex: number;
  programContent: any;
  // const [successMessage, setSuccessMessage] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  // const [isSnackbarOpen, setSnackbarOpen] = useState(false);
}

const initialWebsiteInfoState: any = {
 // template: '',
  // primaryColor: '#a8d507',
  // secondaryColor: '#e52a4f',
  // areaStudy: '',
  // mProgram: '',
  // degreeInterest: '',
  // widgetButton: '',
  // bannerRequestInfo: '',
  // onlineSchoolsMatching: false,
  // requestInformation: false,
  // footerContent: '',
  // footerContentToggle: true,

  // // copyright: '',
  // advertiserDisclosure: '',
  // advertiserMessage: '',
  // advertiserLink: '',
  // pubId: '',
  // pubCampaignId: '',
  // // defaultURL: [],
  // defaultURL: [
  //   {
  //     key: '',
  //     value: '',
  //   },
  // ],
  // footerLinksToggle: true,
  // // copyrightToggle: false,
  // advertiserDisToggle: true,
  // // uploadImage: '',
  // // bannerRequestToggle: false,
  successMessage: '',
  errorMessage: '',
  snackbarOpen: false,
  // currentStepperIndex: 0,
  // footerLogo: '',
  // favIcon: '',
  // footerLinks: [
  //   {
  //     key: '',
  //     value: '',
  //   },
  // ],
  // programContent: [
  //   {
  //     label: 'Business',
  //     enable: true,
  //     subProgram: [
  //       {
  //         areaStudy: '1',
  //         id: '100',
  //         label: 'General Business & Finance',
  //         bid: '0',
  //         value: '100',
  //         enable: true,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '101',
  //         label: 'Accounting',
  //         bid: 0,
  //         value: '101',
  //         enable: false,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '102',
  //         label: 'Business Administration',
  //         bid: 0,
  //         value: '102',
  //         enable: true,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '103',
  //         label: 'Business Analytics',
  //         bid: 0,
  //         value: '103',
  //         enable: false,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '104',
  //         label: 'Communications',
  //         bid: 0,
  //         value: '104',
  //         enable: false,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '105',
  //         label: 'Economics',
  //         bid: 0,
  //         value: '105',
  //         enable: false,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '106',
  //         label: 'Finance',
  //         bid: 0,
  //         value: '106',
  //         enable: false,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '107',
  //         label: 'Human Resources',
  //         bid: 0,
  //         value: '107',
  //         enable: false,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '108',
  //         label: 'Management',
  //         bid: 0,
  //         value: '108',
  //         enable: false,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '109',
  //         label: 'Marketing & Advertising',
  //         bid: 0,
  //         value: '109',
  //         enable: false,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '110',
  //         label: 'Project Management',
  //         bid: 0,
  //         value: '110',
  //         enable: false,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '111',
  //         label: 'Sports Management',
  //         bid: 0,
  //         value: '111',
  //         enable: false,
  //       },
  //       {
  //         areaStudy: '1',
  //         id: '112',
  //         label: 'Healthcare Administration',
  //         bid: 0,
  //         value: '112',
  //         enable: false,
  //       },
  //     ],
  //   },
  //   {
  //     label: 'Criminal Justice',
  //     enable: true,
  //     subProgram: [
  //       {
  //         areaStudy: '1',
  //         id: '100',
  //         label: 'General Business & Finance',
  //         bid: '0',
  //         value: '100',
  //         enable: true,
  //       },
  //     ],
  //   },
  // ],
};

const seoWebsiteInfoApiSlice = createSlice({
  name: 'seoWebsiteList',
  initialState: initialWebsiteInfoState,
  reducers: {
    setTempData: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
    // setTemplateData: (state, action: PayloadAction<any>) => {
    //   state?.template = action.payload;
    // },
    // setFooterLinks: (state, action: PayloadAction<any>) => {
    //   // console.log('state.footerLinks',state.footerLinks)
    //   // console.log('action.payload',action.payload)
    //   state?.footerLinks = action.payload;
    // },
    // setDefaultURL: (state, action: PayloadAction<any>) => {
    //   state?.defaultURL = action.payload;
    // },
    setWebsiteInfoField: (
      state,
      action: PayloadAction<{
        field: keyof seoWebsiteState;
        value: string | boolean;
      }>
    ) => {
      const { field, value } = action.payload;
      (state as any)[field] = value;
    },
    // setPrimaryColorData: (state, action: PayloadAction<any>) => {
    //   state.primaryColor = action.payload;
    // },
    // setSecondaryColorData: (state, action: PayloadAction<any>) => {
    //   state.secondaryColor = action.payload;
    // },
    // setCurrentStepperIndex: (state, action: PayloadAction<any>) => {
    //   state.currentStepperIndex = action.payload;
    // },

    // setToggleField: (
    //   state: seoWebsiteState,
    //   action: PayloadAction<{ field: keyof seoWebsiteState }>
    // ) => {
    //   const { field } = action.payload;
    //   // Toggle the boolean value of the specified field
    //   (state as any)[field] = !(state as any)[field];
    // },
    
    // setSuccessMessage: (state, action: PayloadAction<any>) => {
    //   state.successMessage = action.payload;
    // },
    // setErrorMessage: (state, action: PayloadAction<any>) => {
    //   state.errorMessage = action.payload;
    // },
    // setSnackbarOpen: (state, action: PayloadAction<any>) => {
    //   state.snackbarOpen = action.payload;
    // },
  },
});

export const {
  // setTemplateData,
  // setPrimaryColorData,
  // setSecondaryColorData,
  // setToggleField,
  // setDefaultURL,
  // setSuccessMessage,
  // setSnackbarOpen,
  // setErrorMessage,
  // setWebsiteInfoField,
  // setFooterLinks,
  // setCurrentStepperIndex,
  setTempData,
} = seoWebsiteInfoApiSlice.actions;
export const websiteInfoApi = seoWebsiteInfoApiSlice.reducer;
