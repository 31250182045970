
import React, { useState, useEffect } from 'react'
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import "./Login.scss";
import { PersonPinCircleOutlined, Visibility, VisibilityOff, Person } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { clearErrorMessage, handelChangePasswordModalOpen, loginUser, setUserType } from '../../Store/Slices/Auth/userSlice';
import { RootState } from '../../Store';
import CreatePassword from './createPassword';
import SnackbarMessage from '../Snackbar';
import { CircleLoader } from 'react-spinners'
// import LoadingButton from '@mui/lab/LoadingButton';
type loginFormData = {
  email: string,
  password: string
}
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
export const Login1 = ({ ...props }) => {

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const userType = useAppSelector((state: RootState) => state.userAuthData.userType);
  const userState = useAppSelector((state: RootState) => state.userAuthData);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');

  const [btnLoading, setBtnLoading] = useState(false);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  let navigate = useNavigate();
  const [userData, setUserData] = useState<loginFormData>({
    email: "",
    password: ""
  })

  const [errorModal, setErrorModal] = useState(false)
  const [error, setError] = useState<loginFormData>({
    email: '',
    password: ''
  })


  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value } = e.target
    if (name === 'password') {
      if (!passwordRegex.test(value)) {
        // console.log("track:::::::::::::::")
        setError((prev) => ({
          ...prev, [name]: "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
        }))
      } else {
        setError((prev) => ({
          ...prev, [name]: ""
        }))

      }

    } else {
      if (!value) {
        setError((prev) => ({
          ...prev, [name]: "Please enter the user name"
        }))
      } else {
        setError((prev) => ({
          ...prev, [name]: ""
        }))
      }
    }
    //   console.log("e",e)
    setUserData((prev) => ({
      ...prev, [name]: value
    }))
  };


  const validate = () => {
    let isValid = true
    if (userData.email.length < 5) {
      setError((prev) => ({
        ...prev, email: "Please enter the user name"
      }))
      isValid = false
    } else {
      setError((prev) => ({
        ...prev, email: ""
      }))
      isValid = true;
    }
    if (!passwordRegex.test(userData.password)) {

      setError((prev) => ({
        ...prev, password: "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
      }));
      isValid = false
    } else {
      setError((prev) => ({
        ...prev, password: ""
      }))
      isValid = true

    }

    return isValid;
  }
  const handelLogin = async () => {
    // console.log("Error", error)
    setBtnLoading(true)
    const isValid = validate()
    if (isValid) {
      dispatch(loginUser(userData))
      setBtnLoading(true)
    }
    setBtnLoading(false)


    // console.log("USer state in redux", userState)

  }
  let userRole = localStorage.getItem("userRole")

  console.log('userROle', userRole )
  useEffect(() => {

    // console.log("user data reduxx", userState)
    if (!(Object.keys(userState.user).length === 0) && !(userState.user.constructor === Object)) {
       console.log(" track 1")
      if (userState.user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        //  write API LOGIC first set the user pwd the update the user pool
        dispatch(handelChangePasswordModalOpen(true))
      } else if (userRole === 'Publisher') {
        navigate("/publisherCampaigns")
      } else if (userRole === 'admin' || userRole === "Super-Admin") {
        navigate("/user")
      } else if (userRole === 'Vendor') {
        navigate("/vendor-user")
      } else if (!userRole) {
        navigate("/access-denied")
      }

      userRole && window.location.reload();
    } else {
      if (userState.error) {
        setSnackbarOpen(true);
        setSnackbarMessage(userState.error.message)
        setSnackbarType("error")

      } 
    }

  }, [userState.user, userState.error])


  const handleReset = () => {
    setUserData({
      email: "",
      password: ""
    });
    setError({
      email: "",
      password: ""
    })
  }


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    if (snackbarType === 'error') {
      dispatch(clearErrorMessage("clear"))
    }
    //  handleReset()

  }

  return (
    <>
      {!userRole &&
        <div className="login">


          <Box className="login__media-matchers-logo">
            <img src='https://mm-portal-media-v3.s3.amazonaws.com/advertiser/images/1696308219864.svg%2Bxml' alt='mm-logo' />

          </Box>

          <div className="login__box">
            <Box display="flex" className="login__frame-1000002748">
              <Typography className="card-headingg">Sign in to Account</Typography>
              <Typography className="card-sub-heading">
                Please enter your details below to continue
              </Typography>
            </Box>

            <div className="login__frame-1000002747">

              <TextField
                error={error.email.length > 0}
                id="filled-error-helper-text"
                label="User name"
                defaultValue=""
                helperText={error.email}
                variant="outlined"
                name="email"
                onChange={handleInput}
                className='password-text-input'
                sx={{ width: "100%" }}
                value={userData.email}
                FormHelperTextProps={{ sx: { fontSize: '0.95rem' } }} // Adjust the font size as needed
              />

              <FormControl sx={{ m: 0, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  name='password'
                  error={error.password.length > 0}
                  onChange={handleInput}
                  value={userData.password}
                />
                {error.password.length > 0 && <span className="signIn-error-message">{error.password}</span>}
              </FormControl>
              <Box className="login__forgot-password">
                <Typography> <Link to="/reset-password" ><span style={{ color: "#00AAE3", cursor: "pointer" }}>Reset password</span></Link></Typography>
              </Box>

              <Button className="login-password__button-base" sx={{ backgroundColor: userState.loading ? "#FFFFF !important" : '#00AAEE !important' }} onClick={handelLogin} variant="outlined" disabled={userState.loading}>

                {userState.loading ?
                  <CircleLoader color="#00BFFF" loading={true ? true : false} size={40} /> : "Continue"}
              </Button>
            </div>
          </div>
          <img
            className="login-page-image"
            src="images/login-bg.png"
            alt="bg-logo"

          />
          <CreatePassword resetHandler={handleReset} />
          <SnackbarMessage open={snackbarOpen} onClose={handleSnackbarClose} type={snackbarType} message={snackbarMessage} />
        </div>
      }
    </>

  );
};
