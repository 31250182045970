export const staging={
    "env":"STAGE",
    //  "baseURl":"https://f0720w0one.execute-api.us-east-1.amazonaws.com/staging/v3",
    "baseURl":"https://3xkv7zjm2i.execute-api.us-east-1.amazonaws.com/staging/v3",
     "publisherDashboardBaseUrl":"https://oywto0960h.execute-api.us-east-1.amazonaws.com/staging/v3/",
     "advertiserDashboardBaseUrl":"https://xprc1rdtae.execute-api.us-east-1.amazonaws.com/production/v3/",
     "listingBaseURL":"https://api.media-matchers.com/search"
}

export const production={
    "env":"PROD",
     "baseURl":"https://api-portal-v3.media-matchers.com/mmp/v3",
     "publisherDashboardBaseUrl":"https://837mtp2kgh.execute-api.us-east-1.amazonaws.com/production/v3/",
     "advertiserDashboardBaseUrl":"https://xprc1rdtae.execute-api.us-east-1.amazonaws.com/production/v3/",
     "listingBaseURL":"https://api.media-matchers.com/search"
} 

// export const staging_1 = {
//     Auth: {
//       userPoolId: "us-east-1_2vsXozcgn",
//       region: "us-east-1",
//       userPoolWebClientId: "ba7l3ma1n7veu68cgh5ol1pce",
    
//     }
//   }
  export const stageAwsAuthConfig = {
    Auth: {
      environment: process.env.REACT_APP_BUILD_ENV,
      userPoolId: process.env.REACT_APP_STAGE_COGNITO_AUTH_ID,
      region: "us-east-1",
      userPoolWebClientId: process.env.REACT_APP_STAGE_COGNITO_AUTH_USER_WEBPOOL_ID,
      accessKeyID: process.env.REACT_APP_STAGE_ACCESS_KEY_ID,
       secretKeyID: process.env.REACT_APP_STAGE_SECRET_KEY_ID,
    }
  }

  export const prodAwsAuthConfig = {
    Auth: {
      environment: process.env.REACT_APP_BUILD_ENV,
      userPoolId: process.env.REACT_APP_PROD_COGNITO_AUTH_ID,
      region: "us-east-1",
      userPoolWebClientId: process.env.REACT_APP_PROD_COGNITO_AUTH_USER_WEBPOOL_ID,
      accessKeyID: process.env.REACT_APP_PROD_ACCESS_KEY_ID,
      secretKeyID: process.env.REACT_APP_PROD_SECRET_KEY_ID,
    
    }
  }

  // console.log("prod", prodAwsAuthConfig)
  // console.log("Stage", stageAwsAuthConfig)

  // export const production_1 = {

  //   Auth: {
  //     userPoolId: "us-east-1_bin3svJOz",
  //     region: "us-east-1",
  //     userPoolWebClientId: "13l1odv37o89sbqhoei6fltpq6",
  //   },
   
  // }
  