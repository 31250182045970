import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';


interface AdvertiserState {
  GroupName: string;
  companyDescription: string;
  advertiser: string;
  primaryContact: string;
  city: string;
  phNo: number | string;
  state: string;
  website: string;
  rpl: string;
  status: string;
  ppl: boolean;
  leadFlag: boolean;
  applicationFlag: boolean;
  enrollmentFlag: boolean;
  startsFlag: boolean;
  type:string;
  companyName:string;
  brandName:string;
  mPubID:string;
  editFlag:boolean;
  seoKeywords: string[];
  vendorName:string;
  vertical:string;
  verticalFilter:string;
 
}

const initialState: AdvertiserState = {
  GroupName: '',
  companyDescription: '',
  advertiser: '',
  primaryContact: '',
  city: '',
  phNo: '',
  state: '',
  website: '',
  rpl: '',
  status: "Active",
  ppl: false,
  leadFlag: false,
  applicationFlag: false,
  enrollmentFlag: false,
  startsFlag: false,
  type: "Advertiser",
  companyName: '',
  brandName: '',
  mPubID: '',
  editFlag: true,
  seoKeywords: [],
  vendorName: '',
  vertical: '',
  verticalFilter: ''
};

const advertiserSlice = createSlice({
  name: 'advertiser',
  initialState,
  reducers: {
    setAdvertiserField: (state, action: PayloadAction<{ field: keyof AdvertiserState, value: string | boolean }>) => {
      const { field, value } = action.payload;
      (state as any)[field] = value;
      state.companyName = state.GroupName;
    },
    resetForm: (state) => {
        Object.assign(state, initialState);
      },
      toggleStatus: (state) => {
        // state.status = !state.status;
        state.status = state.status === 'Active' ? 'Inactive' : 'Active';
      },
      togglePayPerLead: (state) => {
        state.ppl = !state.ppl;
        if( state.ppl === false){
          state.rpl = '';
        }
      },
      toggleLeadFlag: (state) => {
        state.leadFlag = !state.leadFlag;
      },
      toggleApplicationFlag: (state) => {
        state.applicationFlag = !state.applicationFlag;
      },
      toggleEnrollmentFlag: (state) => {
        state.enrollmentFlag = !state.enrollmentFlag;
      },
      toggleStartFlag: (state) => {
        state.startsFlag = !state.startsFlag;
      },
      setEditFlag: (state, action: PayloadAction<boolean>) => {
        state.editFlag = action.payload;
      },

  },
  
});

export const { setAdvertiserField ,resetForm,toggleStatus,togglePayPerLead,toggleApplicationFlag,toggleEnrollmentFlag,toggleLeadFlag,toggleStartFlag,setEditFlag} = advertiserSlice.actions;
export default advertiserSlice.reducer;
