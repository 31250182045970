import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Fab,
  useMediaQuery,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, {
  FC,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
// import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuIcon from '@mui/icons-material/Menu';
import isEqual from 'lodash/isEqual';
import ManageMProgramMapping from './ManageTcpaMProgramMapping';
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import MultiMapping from './tcpamultiMapping';
import { createTcpaCampAsync, setSnackbarMessage, setSnackbarOpen, setSnackbarType, setTcpaCreateJson, setTcpaEditFlag, setTcpaEditJson } from '../../../../Store/Slices/tcpaCampSlice';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { CAMPAIGN_CREATE_SUCCESS, CAMPAIGN_UPDATE_SUCCESS } from '../../../../Mock/MessageContstant';
import { error } from 'console';
import { ErrorMessage } from 'formik';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


const tabOptions2:any = [
  {
    "enable": false,
    "areaStudyLabel": "Business",
    "areaStudyId": "1",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "100",
        "mProgramLabel": "General Business",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "101",
        "mProgramLabel": "Accounting",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "102",
        "mProgramLabel": "Business Administration",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "103",
        "mProgramLabel": "Business Analytics",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "104",
        "mProgramLabel": "Communications",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "105",
        "mProgramLabel": "Economics",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "106",
        "mProgramLabel": "Finance",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "107",
        "mProgramLabel": "Human Resources",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "108",
        "mProgramLabel": "Management",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "109",
        "mProgramLabel": "Marketing & Advertising",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "110",
        "mProgramLabel": "Project Management",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "111",
        "mProgramLabel": "Sports Management",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "112",
        "mProgramLabel": "Healthcare Administration",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "113",
        "mProgramLabel": "Entrepreneurship",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Criminal Justice",
    "areaStudyId": "2",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "200",
        "mProgramLabel": "General Criminal Justice & Legal Services",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "201",
        "mProgramLabel": "Corrections",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "202",
        "mProgramLabel": "Crime Scence Investigation",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "203",
        "mProgramLabel": "Criminal Justice",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "204",
        "mProgramLabel": "Criminology",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "205",
        "mProgramLabel": "Cyber Security",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "206",
        "mProgramLabel": "Forensic Science",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "207",
        "mProgramLabel": "Homeland Security",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "208",
        "mProgramLabel": "Law Enforcement",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "209",
        "mProgramLabel": "Legal & Paralegal Studies",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "211",
        "mProgramLabel": "Public Safety Administration",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "212",
        "mProgramLabel": "Security",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "213",
        "mProgramLabel": "Fire Science",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Psychology",
    "areaStudyId": "3",
    "mProgram": [
      {
        "postValue":'',
        "enable": false,
        "mProgramId": "300",
        "mProgramLabel": "General Psychology & Counseling"
      },
      {
        "postValue":'',
        "enable": false,
        "mProgramId": "301",
        "mProgramLabel": "Addictions & Recovery"
      },
      {
        "postValue":'',
        "enable": false,
        "mProgramId": "302",
        "mProgramLabel": "Applied Psychology"
      },
      {
        "postValue":'',
        "enable": false,
        "mProgramId": "303",
        "mProgramLabel": "Behavioral Psychology"
      },
      {
        "postValue":'',
        "enable": false,
        "mProgramId": "304",
        "mProgramLabel": "Child and Adolescent Psychology"
      },
      {
        "postValue":'',
        "enable": false,
        "mProgramId": "305",
        "mProgramLabel": "Counseling"
      },
      {
        "postValue":'',
        "enable": false,
        "mProgramId": "307",
        "mProgramLabel": "Forensic Psychology"
      },
      {
        "postValue":'',
        "enable": false,
        "mProgramId": "308",
        "mProgramLabel": "Human Services"
      },
      {
        "postValue":'',
        "enable": false,
        "mProgramId": "310",
        "mProgramLabel": "Organizational Psychology"
      },
      {
        "postValue":'',
        "enable": false,
        "mProgramId": "310",
        "mProgramLabel": "Sociology"
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Technology",
    "areaStudy": "4",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "400",
        "mProgramLabel": "General Computers & IT",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "401",
        "mProgramLabel": "Bootcamp Programs",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "402",
        "mProgramLabel": "Computer Programming",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "403",
        "mProgramLabel": "Data Science & Analytics",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "404",
        "mProgramLabel": "Game Art Development",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "405",
        "mProgramLabel": "Information Systems",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "406",
        "mProgramLabel": "Information Technology",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "407",
        "mProgramLabel": "IT Project Managment",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "408",
        "mProgramLabel": "Network Security",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "409",
        "mProgramLabel": "Product and Graphic Design",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "410",
        "mProgramLabel": "Software Engineering",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "411",
        "mProgramLabel": "Web Development",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "412",
        "mProgramLabel": "CyberSecurity",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Healthcare",
    "areaStudyId": "5",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "500",
        "mProgramLabel": "General Healthcare & Medical Assisting",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "501",
        "mProgramLabel": "Certified Nursing Assistant (CNA)",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "502",
        "mProgramLabel": "Allied Health",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "503",
        "mProgramLabel": "Emergency Management (EMT)",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "505",
        "mProgramLabel": "Health Science",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "506",
        "mProgramLabel": "Healthcare Administration",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "507",
        "mProgramLabel": "Healthcare Management",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "508",
        "mProgramLabel": "Dental, Medical, or Pharmacy Technician",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "509",
        "mProgramLabel": "Medical Billing & Coding",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "510",
        "mProgramLabel": "Medical Office Assistant",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "511",
        "mProgramLabel": "Nutition, Fitness, and Sports Science",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "512",
        "mProgramLabel": "Public Health",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "513",
        "mProgramLabel": "Veterinary Technician",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "514",
        "mProgramLabel": "X-Ray/Radiologic Technician",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Education & Teaching",
    "areaStudyId": "6",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "600",
        "mProgramLabel": "General Education & Teaching",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "601",
        "mProgramLabel": "Adult Education/Learning",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "603",
        "mProgramLabel": "Early Childhood Development",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "604",
        "mProgramLabel": "K-12 Education",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "605",
        "mProgramLabel": "Social Studies",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "606",
        "mProgramLabel": "Special Education",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "607",
        "mProgramLabel": "Teacher Liscensure",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Vocational Training",
    "areaStudyId": "7",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "700",
        "mProgramLabel": "General Trade, Vocational, Career",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "701",
        "mProgramLabel": "Automotive focused",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "702",
        "mProgramLabel": "Aviation focused",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "703",
        "mProgramLabel": "Certified Nursing Assistant (CNA)",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "704",
        "mProgramLabel": "Construction management",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "705",
        "mProgramLabel": "Electrical Technican",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "706",
        "mProgramLabel": "Fire Science",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "707",
        "mProgramLabel": "Truck Driver (CDL)",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "708",
        "mProgramLabel": "HVAC",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "709",
        "mProgramLabel": "Welding",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Liberal Arts",
    "areaStudyId": "8",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "800",
        "mProgramLabel": "General Liberal Arts",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "801",
        "mProgramLabel": "Creative Writing & English",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "802",
        "mProgramLabel": "Communications",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "803",
        "mProgramLabel": "History",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "804",
        "mProgramLabel": "Religious Studies",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "805",
        "mProgramLabel": "General Liberal Arts",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "806",
        "mProgramLabel": "Humanities",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "807",
        "mProgramLabel": "Political Science",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "General",
    "areaStudyId": "9",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "900",
        "mProgramLabel": "General",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Nursing",
    "areaStudyId": "10",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "1000",
        "mProgramLabel": "General Nursing",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1001",
        "mProgramLabel": "Entry Level",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1002",
        "mProgramLabel": "RN Degree Programs",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1003",
        "mProgramLabel": "RN to BSN Bridge Programs",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1004",
        "mProgramLabel": "MSN Programs",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Media & Communications",
    "areaStudyId": "11",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "1100",
        "mProgramLabel": "General Multi-Media & Communications",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1101",
        "mProgramLabel": "Advertising",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1102",
        "mProgramLabel": "Animation & Video Graphic",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1103",
        "mProgramLabel": "Commercial & Advertising Art",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1104",
        "mProgramLabel": "Computer Media Applications",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1105",
        "mProgramLabel": "Design & Visual Communication",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1106",
        "mProgramLabel": "Film/Video & Cinematography",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1107",
        "mProgramLabel": "Graphic Design",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1108",
        "mProgramLabel": "Intermedia/Multimedia",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1109",
        "mProgramLabel": "Photography",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1110",
        "mProgramLabel": "Recording Arts Technology",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1111",
        "mProgramLabel": "Web Design",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Visual Arts & Graphic Design",
    "areaStudyId": "12",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "1200",
        "mProgramLabel": "General Visual Arts & Graphic Design",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1201",
        "mProgramLabel": "Design & Visual Communications",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1202",
        "mProgramLabel": "Graphic Design",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1203",
        "mProgramLabel": "Industrial Design",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1204",
        "mProgramLabel": "Interior Design",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1205",
        "mProgramLabel": "Photography",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1206",
        "mProgramLabel": "Visual Arts",
        "postValue":''
      }
    ]
  },
  {
    "enable": false,
    "areaStudyLabel": "Religious Studies",
    "areaStudyId": "13",
    "mProgram": [
      {
       
        "enable": false,
        "mProgramId": "1300",
        "mProgramLabel": "General Religious Studies",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1301",
        "mProgramLabel": "Christian Studies",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1302",
        "mProgramLabel": "Ministry",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1303",
        "mProgramLabel": "Religious Studies",
        "postValue":''
      },
      {
       
        "enable": false,
        "mProgramId": "1304",
        "mProgramLabel": "Theology",
        "postValue":''
      }
    ]
  }
]

// const TabPanel = ({ program, upDatedValue,submitRef, handleNext }: any) => {
//   const [programContent, setProgramContent] = useState(program);
//   const [isSelectAll, setSelectAll] = useState(
//     program.mProgram.every((item: any) => item.enable === true)
//   );
//   const [mappingDialog, setMappingDialog] = useState(false);
//   const handelSelectAllCheckBox = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     const { checked } = event.target;
//     const updatedSubPrograms = programContent.mProgram.map((item: any) => ({
//       ...item,
//       enable: checked,
//     }));
//     setProgramContent((prevData: any) => ({
//       ...prevData,
//       mProgram: updatedSubPrograms,
//     }));
//   };

//   useEffect(() => {
//     // console.log('programContent',programContent)
//     setSelectAll(
//       programContent.mProgram.every((item: any) => item.enable === true)
//     );
//     !isEqual(programContent, program) && upDatedValue(programContent);
//   }, [programContent]);

//   const handleStatus = (
//     event: React.ChangeEvent<HTMLInputElement>,
//     id: string
//   ) => {
//     const { name, checked } = event.target;

//     const updatedSubPrograms = programContent.mProgram.map((item: any) =>
//       item.mProgramId === id ? { ...item, enable: checked } : item
//     );
//     setProgramContent((prevData: any) => ({
//       ...prevData,
//       mProgram: updatedSubPrograms,
//     }));
//   };

//   const handlePostValue = (
//     event: React.ChangeEvent<HTMLInputElement>,
//     id: string
//   ) => {
//     // const { name } = event.target;

//     const updatedSubPrograms = programContent.mProgram.map((item: any) =>
//       item.mProgramId === id ? { ...item, postValue: event.target.value } : item
//     );
//     setProgramContent((prevData: any) => ({
//       ...prevData,
//       mProgram: updatedSubPrograms,
//     }));
//   };
//   const handleAreaStudyStatus = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     const { checked } = event.target;
//     setProgramContent((prevData: any) => ({
//       ...prevData,
//       enable: checked,
//     }));
//   };
//   useEffect(() => {
//     setProgramContent(program);
//   }, [program]);

//   //  useEffect(() => {
//   //   submitRef.current = () => {
//   //     handleSubmit();
//   //   };
//   // }, [programContent]);

//   // const handleSubmit =()=>{
//   //   handleNext();
//   // }

//   return (
//     <div role="tabpanel" style={{ width: '100%' }}>
//       <Paper>
//         <Grid container justifyContent="space-between">
//           <Grid item>
//             <FormGroup sx={{ m: 1, minWidth: '100%', p: 1 }}>
//               <FormControlLabel
//                 control={
//                   <IOSSwitch
//                     sx={{ m: 1 }}
//                     name="status"
//                     checked={isSelectAll}
//                     onChange={handelSelectAllCheckBox}
//                   />
//                 }
//                 label="Select All"
//               />
//             </FormGroup>
//           </Grid>
//           <Grid item>
//             {/* <FormGroup sx={{ m: 1, minWidth: '100%', p: 1 }}>
//               <FormControlLabel
//                 control={
//                   <IOSSwitch
//                     sx={{ m: 1 }}
//                     name="status"
//                     checked={programContent?.enable}
//                     onChange={handleAreaStudyStatus}
//                   />
//                 }
//                 label="Select All"
//               />
//             </FormGroup> */}
//           </Grid>
//         </Grid>
//         <Box className="no-more-tables responsive-tbl">
//           <Table size="small">
//             <TableHead>
//               <TableRow>
//                 <TableCell>Status</TableCell>
//                 <TableCell >Program Name</TableCell>
//                 <TableCell sx={{ textAlign: 'center' }}>Advertiser Key</TableCell>
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {programContent?.mProgram?.map((item: any, index: number) => {
//                 return (
//                   <TableRow key={index}>
//                     <TableCell data-title="Status">
//                       {' '}
//                       <FormGroup>
//                         <FormControlLabel
//                           control={
//                             <IOSSwitch
//                               sx={{ m: 1 }}
//                               name={'enable'}
//                               checked={item.enable}
//                               onChange={event => {
//                                 handleStatus(event, item.mProgramId);
//                               }}
//                             />
//                           }
//                           label=""
//                         />
//                       </FormGroup>
//                       {/* <FormControl
//                         sx={{ width: { xs: '100%', md: '40%' }, my: 1 }}
//                       >
//                         <InputLabel htmlFor="outlined-adornment-amount">
//                           Base bid
//                         </InputLabel>
//                         <OutlinedInput
//                           id="outlined-adornment-amount"
//                           startAdornment={
//                             <InputAdornment position="start">$</InputAdornment>
//                           }
//                           label="Amount"
//                           // value={item.bid}
//                           // name={}
//                           name="bid"
//                           onChange={event => {
//                             // handleBidInputField(event, item.id ? item.id : item.value)
//                           }}
//                           sx={{
//                             // Adjust padding for the value inside OutlinedInput
//                             '& input': {
//                               padding: '12px 14px 12px 0px',
//                             },
//                           }}
//                         />
//                       </FormControl> */}
//                     </TableCell>
//                     <TableCell data-title="Program Name" >
//                       {item.mProgramLabel}
//                     </TableCell>
//                     {/* <TableCell data-title="" sx={{ textAlign: 'center' }}>
//                       <Button
//                         variant="outlined"
//                         sx={{
//                           backgroundColor: '#FFF',
//                           border: '1px solid #0AE',
//                           boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
//                           borderRadius: '8px',
//                           color: '#00AAEE',
//                           marginRight: '8px',
//                         }}
//                         onClick={() => {
//                           setMappingDialog(true);
//                         }}
//                       >
//                         Mapping
//                       </Button>
//                     </TableCell> */}
//                     <TableCell data-title="Advertiser Key" sx={{ textAlign: 'center' }}>
//                       <TextField
//                        disabled={!item.enable} // Disable if switch is off
//                         type="text"
//                         variant="outlined" // Ensure the variant is set correctly
//                         value={item.postValue}
//                         placeholder='Post Value'
//                         InputProps={{
//                           style: {
//                             padding: '6px', // Remove internal padding
//                             height: '41px', // Set your desired height
//                           },
//                         }}
//                         sx={{
//                           backgroundColor: '#FFF',
//                           border: '1px solid #0AE',
//                           boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
//                           borderRadius: '8px',
//                           color: '#00AAEE',
//                         }}
//                         onChange={(event:any) => {
//                           handlePostValue(event, item.mProgramId);
//                         }}
//                       />
//                     </TableCell>
//                   </TableRow>
//                 );
//               })}
//             </TableBody>
//           </Table>
//           {/* {mappingDialog && (
//             <ManageMProgramMapping
//               openPopup={mappingDialog}
//               setOpenPopup={setMappingDialog}
//             />
//           )} */}
//           {/* {mappingDialog && (
//             <MultiMapping
//               openPopup={mappingDialog}
//               setOpenPopup={setMappingDialog}
//             />
//           )} */}
//         </Box>
//       </Paper>
//     </div>
//   );
// };

const TabPanel = ({ program, upDatedValue, submitRef }: any) => {
  const [programContent, setProgramContent] = useState(program);
  const [isSelectAll, setSelectAll] = useState(
    program.mProgram.every((item: any) => item.enable === true)
  );
  const [errors, setErrors] = useState<any>({}); // To track validation errors

  const handelSelectAllCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;

     // Clear the errors for all fields if the Select All switch is turned off
  if (!checked) {
    setErrors((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      programContent.mProgram.forEach((item: any) => {
        if (newErrors[item.mProgramId]) {
          delete newErrors[item.mProgramId]; // Remove the error for each field
        }
      });
      return newErrors;
    });
  }
    const updatedSubPrograms = programContent.mProgram.map((item: any) => ({
      ...item,
      enable: checked,
    }));
    setProgramContent((prevData: any) => ({
      ...prevData,
      mProgram: updatedSubPrograms,
    }));
  };

  useEffect(() => {
    setSelectAll(
      programContent.mProgram.every((item: any) => item.enable === true)
    );
    !isEqual(programContent, program) && upDatedValue(programContent);
  }, [programContent]);

  // const handleStatus = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   id: string
  // ) => {
  //   const { checked } = event.target;

  //   const updatedSubPrograms = programContent.mProgram.map((item: any) =>
  //     item.mProgramId === id ? { ...item, enable: checked } : item
  //   );
  //   setProgramContent((prevData: any) => ({
  //     ...prevData,
  //     mProgram: updatedSubPrograms,
  //   }));
  // };

  // const handlePostValue = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   id: string
  // ) => {
  //   const updatedSubPrograms = programContent.mProgram.map((item: any) =>
  //     item.mProgramId === id ? { ...item, postValue: event.target.value } : item
  //   );
  //   setProgramContent((prevData: any) => ({
  //     ...prevData,
  //     mProgram: updatedSubPrograms,
  //   }));
  // };


  const handleStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { checked } = event.target;
  
    // Clear the error for this field if the switch is turned off
    if (!checked) {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        if (newErrors[id]) {
          delete newErrors[id]; // Remove the error for the specific field
        }
        return newErrors;
      });
    }
  
    // Update the enable status for the specific item
    const updatedSubPrograms = programContent.mProgram.map((item: any) =>
      item.mProgramId === id ? { ...item, enable: checked } : item
    );
    setProgramContent((prevData: any) => ({
      ...prevData,
      mProgram: updatedSubPrograms,
    }));
  };
  


  const handlePostValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { value } = event.target;
  
    // Clear the error for this field if user starts typing
    setErrors((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      if (newErrors[id] && value.trim() !== "") {
        delete newErrors[id]; // Remove the error for the specific field
      }
      return newErrors;
    });
  
    // Update the post value for the specific item
    const updatedSubPrograms = programContent.mProgram.map((item: any) =>
      item.mProgramId === id ? { ...item, postValue: value } : item
    );
    setProgramContent((prevData: any) => ({
      ...prevData,
      mProgram: updatedSubPrograms,
    }));
  };
  

  const handleAreaStudyStatus = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setProgramContent((prevData: any) => ({
      ...prevData,
      enable: checked,
    }));
  };

  // Validation function to check if advertiser key is empty when the switch is on
  const validateFields = () => {
    const newErrors: any = {};
    programContent.mProgram.forEach((item: any) => {
      if (item.enable && !item.postValue) {
        newErrors[item.mProgramId] = "Advertiser Key cannot be empty";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  useEffect(() => {
    setProgramContent(program);
  }, [program]);

  // Trigger validation and call handleNext only if no errors
  // useEffect(() => {
  //   submitRef.current = () => {
  //     if (validateFields()) {
  //       handleNext();
  //     }
  //   };
  // }, [programContent]);

  useEffect(() => {
    if (submitRef) {
      submitRef.current = validateFields;
    }
  }, [programContent]);

  return (
    <div role="tabpanel" style={{ width: "100%" }}>
      <Paper>
        <Grid container justifyContent="space-between">
          <Grid item>
            <FormGroup sx={{ m: 1, minWidth: "100%", p: 1 }}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    name="status"
                    checked={isSelectAll}
                    onChange={handelSelectAllCheckBox}
                  />
                }
                label="Select All"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box className="no-more-tables responsive-tbl">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Program Name</TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  Advertiser Key
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {programContent?.mProgram?.map((item: any, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell data-title="Status">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              name={"enable"}
                              checked={item.enable}
                              onChange={(event) => {
                                handleStatus(event, item.mProgramId);
                              }}
                            />
                          }
                          label=""
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell data-title="Program Name">
                      {item.mProgramLabel}
                    </TableCell>
                    {/* <TableCell
                      data-title="Advertiser Key"
                      sx={{ textAlign: "center" }}
                    >
                      <TextField
                        disabled={!item.enable} // Disable if switch is off
                        type="text"
                        variant="outlined"
                        value={item.postValue}
                        placeholder="Post Value"
                        InputProps={{
                          style: {
                            padding: "6px",
                            height: "41px",
                          },
                        }}
                        sx={{
                          backgroundColor: "#FFF",
                          border: "1px solid #0AE",
                          boxShadow:
                            "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                          borderRadius: "8px",
                          color: "#00AAEE",
                        }}
                        onChange={(event: any) => {
                          handlePostValue(event, item.mProgramId);
                        }}
                        // error={!!errors[item.mProgramId]} // true if error exists for this field
                        // helperText={errors[item.mProgramId]} // Display the error message
                      />
                      {errors[item.mProgramId] && (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          {errors[item.mProgramId]}
                        </p>
                      )}
                    </TableCell> */}
                    <TableCell data-title="Advertiser Key" sx={{ textAlign: "center" }}>
  <TextField
    disabled={!item.enable} // Disable if switch is off
    type="text"
    variant="outlined"
    value={item.postValue}
    placeholder="Post Value"
    InputProps={{
      style: {
        padding: "6px",
        height: "41px",
      },
      // Add error icon conditionally using InputAdornment
      endAdornment: errors[item.mProgramId] ? (
        <InputAdornment position="end">
          <ErrorOutlineIcon color="error" />
        </InputAdornment>
      ) : null,
    }}
    sx={{
      backgroundColor: "#FFF",
      border: "1px solid #0AE",
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      borderRadius: "8px",
      color: "#00AAEE",
    }}
    onChange={(event: any) => {
      handlePostValue(event, item.mProgramId);
    }}
    error={!!errors[item.mProgramId]} // true if error exists for this field
  />
</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </div>
  );
};


const TCPAProgramTarg: FC<any> = ({ submitRef, handleNext }) => {
  const dispatch = useAppDispatch();
  const [showTabs, setShowTabs] = useState(true);
  const tcpaCampId = useAppSelector((state) => state.tcpaCampSlice.payloadTcpaId);
  // const [seoDialog, setSeoDialog] = useState(false);
  // const [contentDialog, setContentDialog] = useState(false);
  // const [imgDialog, setImgDialog] = useState(false);
  // const [faqDialog, setFaqDialog] = useState(false);
  const [value, setValue] = React.useState(0);
  const [selectedValue, setSelectedValue] = React.useState<any>('');
  const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
  const { tcpaEditJsonData,tcpaEditFlag,tcpaCampaignIdOnEdit,tcpaCreateJsonData} = tcpaCampSlice
  // const [subProgram, setSubProgram] = useState<any>(tcpaEditFlag ? (tcpaEditJsonData[0].programTargeting[0] || tabOptions2[0]) :  tabOptions2[0]);
  const [subProgram, setSubProgram] = useState<any>(
    tcpaEditFlag ? (tcpaEditJsonData?.programTargeting?.[0] || tabOptions2[0])  : tabOptions2[0] );
  // const [parentTabValue, setParentTabValue] = React.useState(tabOptions2);
  // const [value, setValue] = React.useState(tabOptions[0]?.areaStudy[0]);
  const [tabContent, setTabContent] = useState(tcpaEditFlag ? (tcpaEditJsonData?.programTargeting || tabOptions2):  tabOptions2);
  // const [subProgram, setSubProgram] = useState<any>(tabContent[0]);
  // const handleChange = (event: React.SyntheticEvent, newValue: any) => {
  //   console.log(newValue, 'test');
  //   setValue(newValue);
  // //  setSubProgram(newValue);
  // };

  const submitRefs = useRef<any>(null);
  
useEffect(()=> {
  console.log('2222',tcpaCreateJsonData)
  if(tcpaCreateJsonData?.programTargeting){
    tcpaCreateJsonData?.programTargeting?.[0] && setSubProgram(tcpaCreateJsonData?.programTargeting?.[0] || tabOptions2[0])
    tcpaCreateJsonData?.programTargeting && setTabContent(tcpaCreateJsonData?.programTargeting || tabOptions2)
  }
},[])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {

    setValue(newValue);
    setSubProgram(tabContent[newValue]);
  };


  const toggleTabsVisibility = () => {
    setShowTabs(!showTabs);
  };

  // Using useMediaQuery to detect screen width
  const isScreenAbove1300 = useMediaQuery('(min-width:1300px)');

  function a11yProps(index: number, name: string) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      name: name,
    };
  }
  // function a11yProps(index: number, name: string, value: string) {
  //   return {
  //     id: `vertical-tab-${index}`,
  //     'aria-controls': `vertical-tabpanel-${index}`,
  //     name: name,
  //   };
  // }

  // const setUpDatedValue = (updateValue: any) => {
  //   const updatedTabContent ={...parentTabValue,areaStudy: parentTabValue?.areaStudy?.map((item: any, index: number) =>
  //     item.label === updateValue.label ? updateValue : item
  //   )};
  //   setParentTabValue(updatedTabContent);
  //    console.log('updateValue', updatedTabContent);
  //   // setTabContent(updatedTabContent);
  //   // setSubProgram(updatedTabContent[value]);
  // };
  const setUpDatedValue = (updateValue: any) => {
    const updatedTabContent = tabContent.map((item: any, index: number) =>
      item.areaStudyLabel === updateValue.areaStudyLabel ? updateValue : item
    );
    // console.log('updateValue', updatedTabContent);
    setTabContent(updatedTabContent);
    setSubProgram(updatedTabContent[value]);
  };
  
  // useEffect(() => {
  //   console.log('value', value);
  // }, [value]);

  useEffect(() => {
    // Automatically close the tabs if the screen width is below 1300px
    if (!isScreenAbove1300) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
  }, [isScreenAbove1300]);

  useEffect(() => {
    submitRef.current = () => {
      handleSubmit();
    };
  }, [tabContent]);


  const handleSubmit =async ()=>{
    if (submitRefs.current) {
      const isValid = submitRefs.current();
      if(isValid){
        const payload={
          programTargeting:tabContent,
          campaignType: "tcpaCampaign",
        tcpaCampaignId: tcpaEditFlag ? tcpaCampaignIdOnEdit : tcpaCampId,
        }
        try{
          if(tcpaEditFlag){
            const response = await dispatch(createTcpaCampAsync({ payload: payload, method: 'put' }));
            if(response){
              dispatch(setTcpaEditJson(response.payload.data))
              dispatch(setSnackbarOpen(true))
              dispatch(setSnackbarType("success"))
              dispatch(setSnackbarMessage(CAMPAIGN_UPDATE_SUCCESS))
              handleNext()
            }
          }else{
            const response = await dispatch(createTcpaCampAsync({ payload: payload, method: 'put' }));
            if(response){
              console.log('program',response)
              dispatch(setTcpaCreateJson(response.payload.data))
              dispatch(setSnackbarOpen(true))
              dispatch(setSnackbarType("success"))
              dispatch(setSnackbarMessage(CAMPAIGN_CREATE_SUCCESS))
              handleNext()
            }
          }
      } catch(err){
        console.error(err);
        setSnackbarType("error")
        setSnackbarMessage(ErrorMessage)
        setSnackbarOpen(true)
      }
      }else{
        console.log("Validation failed.");
      }
    }
   
  }

  return (
    <>
      <Box sx={{ position: 'relative', display: 'flex' }}>
        {showTabs  && (
          <Box
          sx={{
            height: '70vh',
            flexBasis: '20%',
            padding: '10px',
          }}
          className="mm-tab-container"
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {tabContent.map((item: any, index: any) => {
              return (
                <Tab
                  label={item.areaStudyLabel}
                  {...a11yProps(index, item.areaStudyLabel)}
                  className={`mm-tab ${index === value ? 'active' : ''} `}
                />
              );
            })}
          </Tabs>
        </Box>
        )}

        <Box
          className="mm-tabpanel-container"
          sx={{
            height: '75vh',
            flexBasis: '100%',
            padding: '11.5px',
          }}
        >
        <TabPanel program={subProgram} upDatedValue={setUpDatedValue} submitRef={submitRefs} />
        </Box>

        <Box
          sx={{
            position: 'fixed',
            bottom: '92px',
            right: '12px',
            zIndex: 999, // Ensure it's above other elements
          }}
        >
          {!isScreenAbove1300 && (
            <Fab
              onClick={toggleTabsVisibility}
              variant="extended"
              size="small"
              color="primary"
            >
              <MenuIcon sx={{ mr: 1 }} />
              Program
              {/* {tabContent[0].label == 'Business & Finance' ? 'Programs' : (tabContent[0].label == 'Campus Type' ? 'Audience' : (tabContent[0].label == '[ISM] Online College Guide' ? 'Media' : 'Rules'))} */}
            </Fab>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TCPAProgramTarg;
