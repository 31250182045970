import { TextField } from "@mui/material";

interface basicTextField{
    name:string;
    value:string;
    label:string;
    changeHandler:(event:React.ChangeEvent<HTMLInputElement>)=>void
  }
  
  const BasicTextField:React.FC<basicTextField>=({name, value, label, changeHandler })=>{
    return <TextField  fullWidth name={name} label={label} value={value} onChange={changeHandler} />
  }

  export default BasicTextField;