import { Box, Button } from '@mui/material'
import { Auth } from 'aws-amplify';
import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../Store/hooks';
import { handelLogout } from '../../Store/Slices/Auth/userSlice';
// import { withRouter } from 'react-router-dom';

const AccessDenied = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const handelSignOut = () => {
    // console.log("Handel sign out")
    // dispatch(setUserType(''));
    Auth.signOut().then(() => {
      // console.log("logout successful",)
      dispatch(handelLogout("logout successfull"))
      navigate("/login")
      window.location.reload();
    }).catch((err) => {
      console.log("err", err)
    })
  }
  return (
    <Box className='accessDenied-Container' sx={{display:"flex", justifyContent:"center", alignItems:"center" ,minHeight:"100vh" , flexDirection:"column"}}>
      <h1>
        AccessDenied
      </h1>

      <Box className='logout-container'>
        <Button onClick={handelSignOut}>
          Log out
        </Button>
      </Box>


    </Box>

  )

}

export default AccessDenied
