import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  TextField,
  InputAdornment,
  Input,
  Button,
  Paper,
  TextareaAutosize,
  FormGroup,
  IconButton,
  CardMedia,
  Tooltip,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import htmlToDraft from 'html-to-draftjs';
import {
  // setCurrentStepperIndex,
  // setFooterLinks,
  setTempData,
  // setToggleField,
  // setWebsiteInfoField,
} from '../../../../Store/Slices/SEOWebsiteInfoApiSlice';
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import CancelIcon from '@mui/icons-material/Cancel';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useFormik, FormikProvider, FieldArray, getIn } from 'formik';
import MuiAlert from '@mui/material/Alert';
import { uploadSEOToS3 } from '../../../../service/S3 Upload';
import { websiteInfoValidation } from '../../validation';
import draftToHtml from 'draftjs-to-html';
import {
  updateWebSiteContent,
} from '../../../../Services/seoApis';
import { useLocation, useNavigate } from 'react-router-dom';
import { setApiLoading, setSnackbarOpen, setSuccessMessage } from '../../../../Store/Slices/SEOHandlerApisSlice';
import { debounce } from 'lodash';

interface websiteInfoFieldValues {
  template: string;
  primaryColor: string;
  secondaryColor: string;
  advertiserDisToggle: boolean;
  advertiserMessage: string;
  advertiserLink: string;
  advertiserDisclosure: string;
  areaStudy: string;
  degreeInterest: string;
  widgetButton: string;
  bannerRequestInfo: string;
  mProgram: string;
  // footerContent: string;
  // footerContentToggle: boolean;
  // copyright: string;
  // copyrightToggle: boolean;
  footerLogo: string;
  // uploadImage2: string;
  favIcon: string;
  //  footerLinksToggle: boolean;
  // footerLinks: { key: string; value: string }[];
}

const WebSiteInfo: FC<any> = ({ submitRef,handleNext }) => {
  const dispatch = useAppDispatch();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [footerLogo, setFooterLogo] = useState<string>('');
  // const [uploadImage2, setUploadImage2] = useState<string>('');
  const [favicon, setFavicon] = useState<string>('');
  const [imageAlert1, setImageAlert1] = React.useState(false);
  const [imageExtAlert, setImageExtAlert] = React.useState(false);
  const [faviconExtAlert, setFaviconExtAlert] = React.useState(false);
  const [faviconAlert, setFaviconAlert] = React.useState(false);
  //  const [areaStudy, setAreaStudy] = React.useState<any>('');
  const [mProgram, setMProgram] = React.useState<any>('');
  // const [degreeInterest, setDegreeInterest] = React.useState<any>('');
  const websiteInfoFormData = useAppSelector(state => state.websiteInfoApi);
  const areaStudynew = useAppSelector((state:any) => state.seoHandlerApi.areaMprogramData);
  const degreeIntrestNew = useAppSelector((state:any) => state.seoHandlerApi.degreeIntrestNew);
  const cmsEditFlag = useAppSelector((state:any) => state.seoHandlerApi.cmsEditFlag);
  const [templates, setTemplates] = useState([
    { id: '1', name: 'Template 1' },
    { id: '2', name: 'Template 2' },
    // Add more templates as needed
  ]);
  const navigate=useNavigate();
  const location = useLocation();

  useEffect(()=>{
    if( location.pathname==='/edit-cmsWebsite' && cmsEditFlag === false){
         navigate('/cms-website')
    }
  },[])

  const debouncedSetFieldValue = useCallback(
    debounce((fieldName:any, value:any) => {
      formik.setFieldValue(fieldName, value);
    }, 10),
    [] // Empty dependency array ensures that the debounced function is memoized and reused
  );

  const formik = useFormik<websiteInfoFieldValues>({
    initialValues: {
      template: websiteInfoFormData?.template,
      primaryColor: websiteInfoFormData?.primaryColor,
      secondaryColor: websiteInfoFormData?.secondaryColor,
      advertiserDisToggle: websiteInfoFormData?.advertiserDisToggle,
      advertiserMessage: websiteInfoFormData?.advertiserMessage,
      advertiserLink: websiteInfoFormData?.advertiserLink,
      advertiserDisclosure: websiteInfoFormData?.advertiserDisclosure,
      areaStudy: websiteInfoFormData?.areaStudy,
      mProgram: websiteInfoFormData?.mProgram,
      degreeInterest: websiteInfoFormData?.degreeInterest,
      widgetButton: websiteInfoFormData?.widgetButton,
      bannerRequestInfo: websiteInfoFormData?.bannerRequestInfo,
      favIcon: websiteInfoFormData?.favIcon,
      footerLogo: websiteInfoFormData?.footerLogo,
    },
    onSubmit: values => {
   dispatch(setApiLoading(true));
function enableProgram(programId: string,areaStudyId:string,formData: any) {
  // Iterate through each area of study
  // console.log('start',formData.programContent)
  for (let i = 0; i < formData.programContent.length; i++) {
      let areaOfStudy = formData.programContent[i];
      if (areaOfStudy.areaStudy === areaStudyId) {
        // Enable the program by setting enable flag to true
        areaOfStudy.enable = true;
    }
      // Iterate through each program in the area of study
      for (let j = 0; j < areaOfStudy.mProgram.length; j++) {
          let program = areaOfStudy.mProgram[j];
          
          // Check if the program ID matches the provided programId
          if (program.id === programId) {
              // Enable the program by setting enable flag to true
              program.enable = true;
              return formData; // Program found and enabled
          }
      }
  }
  
  return false;
}

let mutableWebsiteInfoFormData = JSON.parse(JSON.stringify(websiteInfoFormData));
const resultt =  enableProgram(values?.mProgram,values?.areaStudy,mutableWebsiteInfoFormData);

      dispatch(setTempData({ ...resultt, ...values }));
      const updateContent = async () => {
        try {
         await updateWebSiteContent({ ...resultt, ...values });
         dispatch(setSuccessMessage('Updated Website Info'));
         dispatch(setSnackbarOpen(true));
         handleNext();
        } catch (error) {
          console.error('Error fetching data:', error);
   dispatch(setApiLoading(false));
        }
   dispatch(setApiLoading(false));
      };
    updateContent();
    },
    validationSchema: websiteInfoValidation,
  });

  const handleEditorFocus = () => {
    formik.handleBlur('advertiserDisclosure');
  };

  useEffect(() => {
    if (websiteInfoFormData?.footerLogo) {
      setFooterLogo(websiteInfoFormData?.footerLogo);
    }

    if (websiteInfoFormData?.favIcon) {
      setFavicon(websiteInfoFormData?.favIcon);
    }
  }, [websiteInfoFormData]);

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    rawContentState?.blocks.forEach(value => {
      if (value.text) {
        formik.setFieldValue(
          'advertiserDisclosure',
          draftToHtml(rawContentState)
        );
      }
    });
  }, [editorState]);

  const handleUnsupportedFileType = () => {
    setFaviconExtAlert(true);
  };
  const handleUnsupportedImageFileType = () => {
    setImageExtAlert(true);
  };

  const handlefooterLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageAlert1(false);
    const fileInput = event.target;
    const file = fileInput.files && fileInput.files[0];

    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp') {
        const reader = new FileReader();

        reader.onloadend = () => {
          const image = new Image();
          image.src = reader.result as string;

          image.onload = () => {
            const width = image.width;
            const height = image.height;

            if (width <= 500 && height <= 250) {
              const objectURL = uploadSEOToS3(
                reader.result,
                `${new Date().getTime()}`,
                file
              );
              objectURL.then((res: any) => {
                formik.setFieldValue('footerLogo', res);
                // console.log('file details....', res);
                setFooterLogo(res as any);
              });
            } else {
              event.target.value = '';
              // Display an error alert
              setImageAlert1(true);
              // Reset the file input to clear the selected file
              fileInput.value = '';
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        // Display an error alert
        handleUnsupportedImageFileType();
        // Reset the file input to clear the selected file
        fileInput.value = '';
      }
    }
  };

  const handleAlertClose1 = () => {
    setImageAlert1(false);
  };

  const handleFaviconAlertClose = () => {
    setFaviconAlert(false);
  };
  // console.log('template====',websiteInfoFormData.template,formik.values.template)

  const handleUploadFavicon = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFaviconAlert(false);
    const fileInput = event.target;
    const file = fileInput.files && fileInput.files[0];
// console.log('type',file)
    if (file) {
      if (file.type === 'image/x-icon' || file.type === 'image/svg+xml') {
        const reader = new FileReader();

        reader.onloadend = () => {
          const image = new Image();
          image.src = reader.result as string;

          image.onload = () => {
            const width = image.width;
            const height = image.height;

            if (width <= 32 && height <= 32) {
              const objectURL = uploadSEOToS3(
                reader.result,
                `${new Date().getTime()}`,
                file
              );
              objectURL.then((res: any) => {
                formik.setFieldValue('favIcon', res);
                setFavicon(res as any);
              });
            } else {
              event.target.value = '';
              // Display an error alert
              setFaviconAlert(true);
              // Reset the file input to clear the selected file
              fileInput.value = '';
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        // Display an error alert
        handleUnsupportedFileType();
        // Reset the file input to clear the selected file
        fileInput.value = '';
      }
    }
  };

  const { handleSubmit } = formik;

  const loadInitialValue = (initialContent: string) => {
    if (initialContent) {
      const contentBlock = htmlToDraft(initialContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateFromHTML = EditorState.createWithContent(contentState);
      setEditorState(editorStateFromHTML);
    }
  };
  useEffect(() => {
    submitRef.current = () => {
      handleSubmit();
    };
    loadInitialValue(websiteInfoFormData?.advertiserDisclosure);
  }, []);

  useEffect(() => {
console.log('error',formik?.errors)
  }, [formik.errors]);

  useEffect(() => {
    // const fetchAreaStudy = async () => {
    //   try {
    //     const areaStudy = await fetchAreaMprogram();
    //     setAreaStudy(areaStudy);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };

    // const fetchDInterest = async () => {
    //   try {
    //     const dInterest = await fetchDegreeInterest();
    //     console.log('dInterest====',dInterest,degreeIntrestNew)
    //     setDegreeInterest(dInterest[0]?.list);

    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    //  fetchAreaStudy();
    // fetchDInterest();
  }, []);


  const findProgramById = (programId: any) => {
    const category = areaStudynew?.find((category: any) => {
      return (
        category?.id !== 'lookup#Category' &&
        category?.value?.category_id == programId
      );
    });

    if (category) {
      // category.value.programs.sort((a: any, b: any) =>
      //   a.program_id.localeCompare(b.program_id)
      // );
     formik.setFieldValue("mProgram",category.value.programs?.[0]?.program_id);
      setMProgram(category.value.programs);
    } else {
      setMProgram([]);
    }
  };

  // const findProgramById2 = (programId: any) => {
  //   const category = areaStudynew?.find((category: any) => {
  //     return (
  //       category?.id !== 'lookup#Category' &&
  //       category?.value?.category_id == programId
  //     );
  //   });
  //   console.log('category===2',category)
  // }

  useEffect(() => {
    if (areaStudynew) {
      findProgramById(websiteInfoFormData?.areaStudy);
    }
  }, [areaStudynew]);

  // useEffect(() => {
  //   if(areaStudy){
  //   findProgramById2(websiteInfoFormData?.areaStudy)

  //   }
  // }, [areaStudy]);

  // useEffect(() => {
  //   console.log('websiteInfoFormData', websiteInfoFormData);
  // }, []);
  return (
    <Paper elevation={3} className="ad-campaign-form">
      <form onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <Grid>
            <FormControl fullWidth>
              <InputLabel>Template</InputLabel>
              <Select
                value={formik.values.template}
                onChange={e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                name="template"
                label="Template"
              >
                {/* <MenuItem value="">Select</MenuItem>
                <MenuItem value="1" key="1">
                  Template 1
                </MenuItem>
                <MenuItem value="2" key="2">
                  Template 2
                </MenuItem> */}
                {/* <MenuItem value="">Select</MenuItem> */}
          {templates.map(template => (
            <MenuItem value={template.id} key={template.id}>
              {template.name}
            </MenuItem>
          ))}
              </Select>
              {formik.errors.template && formik.touched.template ? (
                <Box color="#db2424">{formik.errors.template}</Box>
              ) : null}
            </FormControl>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={6}>
              <TextField
                id="primary-color-picker"
                label="Primary Color"
                name="primaryColor"
                value={formik.values.primaryColor}
                variant="outlined"
                fullWidth
                onChange={(e:any)=>{
                  debouncedSetFieldValue('primaryColor',e.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <input
                        type="color"
                        value={formik.values.primaryColor}
                        onChange={(e:any)=>{
                          debouncedSetFieldValue('primaryColor',e.target.value)
                        }}
                        style={{
                          width: '40px',
                          height: '40px',
                          padding: 0,
                          margin: 0,
                          border: 'none',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="secondary-color-picker"
                label="Secondary Color"
                name="secondaryColor"
                value={formik.values.secondaryColor}
                variant="outlined"
                onChange={(e:any)=>{
                  debouncedSetFieldValue('secondaryColor',e.target.value)
                }}
                // onChange={formik.handleChange}
                // type="color"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <input
                        type="color"
                        value={formik.values.secondaryColor}
                        onChange={(e:any)=>{
                          debouncedSetFieldValue('secondaryColor',e.target.value)
                        }}
                        // onChange={e => {
                        //   formik.setFieldValue(
                        //     'secondaryColor',
                        //     e.target.value
                        //   );
                        // }}
                        style={{
                          width: '40px',
                          height: '40px',
                          padding: 0,
                          margin: 0,
                          border: 'none',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Area Study</InputLabel>
                <Select
                  label="Area Study"
                  name="areaStudy"
                  value={formik.values.areaStudy}
                  onChange={e => {
                    formik.handleChange(e);
                    findProgramById(e?.target?.value);
                  }}
                  onBlur={formik.handleBlur}
                >
                  {Array.isArray(areaStudynew) &&
                    areaStudynew.map(({ id, value }) => {
                      if (id !== 'lookup#Category') {
                        return (
                          <MenuItem key={id} value={value?.category_id.toString()}>
                            {value?.category}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                </Select>
              </FormControl>
              {formik.errors.areaStudy && formik.touched.areaStudy ? (
                <Box color="#db2424">{formik.errors.areaStudy}</Box>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Program</InputLabel>
                <Select
                  label="mProgram"
                  name="mProgram"
                  value={formik.values.mProgram}
                  onChange={e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                >
                  {Array.isArray(mProgram) &&
                    mProgram?.map((value: any, index: any) => (
                      <MenuItem key={index} value={value?.program_id}>
                        {value?.program_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {formik.errors.mProgram && formik.touched.mProgram ? (
                <Box color="#db2424">{formik.errors.mProgram}</Box>
              ) : null}
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Degree Interest</InputLabel>
                <Select
                  label="Degree Interest"
                  name="degreeInterest"
                  value={formik.values.degreeInterest}
                  onChange={e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                >
                  {Array.isArray(degreeIntrestNew) &&
                    degreeIntrestNew?.map((value: any, index: any) => (
                      <MenuItem key={index} value={value?.value}>
                        {value?.type}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {formik.errors.degreeInterest && formik.touched.degreeInterest ? (
                <Box color="#db2424">{formik.errors.degreeInterest}</Box>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Widget Button Text"
                name="widgetButton"
                variant="outlined"
                value={formik.values.widgetButton}
                onChange={e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                fullWidth
              />
              {formik.errors.widgetButton && formik.touched.widgetButton ? (
                <Box color="#db2424">{formik.errors.widgetButton}</Box>
              ) : null}
            </Grid>
          </Grid>

          <Box>
            <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
              <Grid item xs={6}>
                <Input
                  type="file"
                  id="footerLogo"
                  name="footerLogo"
                  inputProps={{ accept: 'image/*' }}
                  style={{ display: 'none' }}
                  onChange={handlefooterLogo}
                  onBlur={formik.handleBlur}
                />
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" gap={1}>
                    <Tooltip title={footerLogo || ''} arrow>
                      <TextField
                        value={footerLogo}
                        variant="outlined"
                        fullWidth
                        label="Upload Footer Logo*"
                        helperText="Dimension max(500*250)"
                      />
                    </Tooltip>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        backgroundColor: '#1976d2',
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: '#1976d2 !important',
                        },

                        '&:hover': { backgroundColor: '#1976d2' },
                        border: '1px solid #1976d2',
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                        borderRadius: '8px',
                        // color: '#ffff',
                        height: '45px',
                        margin: '5px',
                      }}
                      onClick={() => {
                        // Trigger the hidden file input
                        const fileInput = document.getElementById(
                          'footerLogo'
                        ) as HTMLInputElement;
                        fileInput.click();
                      }}
                    >
                      <Typography>Upload</Typography>
                    </Button>
                  </Box>

                  {imageExtAlert && (
                    <MuiAlert
                      elevation={6}
                      variant="filled"
                      severity="error"
                      onClose={() => setImageExtAlert(false)}
                    >
                      Unsupported file type for upload
                    </MuiAlert>
                  )}
                  {imageAlert1 && (
                    <MuiAlert
                      elevation={6}
                      variant="filled"
                      severity="error"
                      onClose={handleAlertClose1}
                      sx={{ marginTop: '10px' }}
                    >
                      Image dimensions must be maximum 500*250 pixels or smaller.
                    </MuiAlert>
                  )}

                  {formik.errors.footerLogo &&
                  formik.touched.footerLogo &&
                  typeof formik.errors.footerLogo === 'string' ? (
                    <Box color="#db2424">{formik.errors.footerLogo}</Box>
                  ) : null}
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Input
                  type="file"
                  id="favIcon"
                  inputProps={{ accept: 'image/*' }}
                  style={{ display: 'none' }}
                  onChange={handleUploadFavicon}
                  onBlur={formik.handleBlur}
                  name='favIcon'
                />
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" gap={1}>
                    <Tooltip title={favicon || ''} arrow>
                      <TextField
                        value={favicon}
                        variant="outlined"
                        fullWidth
                        label="Upload favIcon *"
                        helperText="Dimension (32*32)"
                        name='favIcon'
                      />
                    </Tooltip>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        backgroundColor: '#1976d2',
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: '#1976d2 !important',
                        },

                        '&:hover': { backgroundColor: '#1976d2' },
                        border: '1px solid #1976d2',
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                        borderRadius: '8px',
                        // color: '#ffff',
                        height: '45px',
                        margin: '5px',
                      }}
                      onClick={() => {
                        // Trigger the hidden file input
                        const fileInput = document.getElementById(
                          'favIcon'
                        ) as HTMLInputElement;
                        fileInput.click();
                      }}
                    >
                      <Typography>Upload</Typography>
                    </Button>
                  </Box>
                  {faviconExtAlert && (
                    <MuiAlert
                      elevation={6}
                      variant="filled"
                      severity="error"
                      onClose={() => setFaviconExtAlert(false)}
                    >
                      Unsupported file type for favicon
                    </MuiAlert>
                  )}
                  {faviconAlert && (
                    <MuiAlert
                      elevation={6}
                      variant="filled"
                      severity="error"
                      onClose={handleFaviconAlertClose}
                      sx={{ marginTop: '10px' }}
                    >
                      Favicon dimensions must be 32*32 pixels or smaller.
                    </MuiAlert>
                  )}
                  {formik.errors.favIcon &&
                  formik.touched.favIcon &&
                  typeof formik.errors.favIcon === 'string' ? (
                    <Box color="#db2424">{formik.errors.favIcon}</Box>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={12}>
              <TextField
                label="Banner Request Information"
                placeholder="Banner Request Information"
                name="bannerRequestInfo"
                value={formik.values.bannerRequestInfo}
                onChange={e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                style={{ borderRadius: '8px' }}
                fullWidth
              />

              {formik.errors.bannerRequestInfo &&
              formik.touched.bannerRequestInfo ? (
                <Box color="#db2424">{formik.errors.bannerRequestInfo}</Box>
              ) : null}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={formik.values.advertiserDisToggle}
                    name="advertiserDisToggle"
                    onChange={() => {
                      formik.setFieldValue(
                        'advertiserDisToggle',
                        !formik.values.advertiserDisToggle
                      );
                    }}
                    sx={{ ml: 2 }}
                  />
                }
                label={
                  <Typography sx={{ fontWeight: '500' }}>
                    Advertiser Disclosure
                  </Typography>
                }
                labelPlacement="start"
                sx={{ ml: 1 }}
              />
            </Grid>
            <Grid item xs={12}>
              {formik.values.advertiserDisToggle && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Advertiser Message"
                        name="advertiserMessage"
                        value={formik.values.advertiserMessage}
                        onChange={e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        variant="outlined"
                        fullWidth
                        style={{ marginBottom: '10px' }}
                      />
                      {formik.values.advertiserDisToggle &&
                      formik.errors.advertiserMessage &&
                      formik.touched.advertiserMessage ? (
                        <Box color="#db2424">
                          {formik.errors.advertiserMessage}
                        </Box>
                      ) : null}
                    </Grid>

                    {/* <Grid item xs={3}>
                      <TextField
                        label="Advertiser Link"
                        name="advertiserLink"
                        value={formik.values.advertiserLink}
                        onChange={e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        variant="outlined"
                        fullWidth
                        style={{ marginBottom: '10px' }}
                      />
                      {formik.values.advertiserDisToggle &&
                      formik.errors.advertiserLink &&
                      formik.touched.advertiserLink ? (
                        <Box color="#db2424">
                          {formik.errors.advertiserLink}
                        </Box>
                      ) : null}
                    </Grid> */}
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapper"
                        editorClassName="editor"
                        // onEditorStateChange={setEditorState}
                        onEditorStateChange={editorState => {
                          setEditorState(editorState);
                        }}
                        onFocus={handleEditorFocus}
                        toolbar={{
                          options: [
                            'inline',
                            'blockType',
                            'fontSize',
                            'fontFamily',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'emoji',
                            'remove',
                            'history',
                          ],
                        }}
                      />

                      {formik.values.advertiserDisToggle &&
                      formik.errors.advertiserDisclosure &&
                      formik.touched.advertiserDisclosure ? (
                        <Box color="#db2424">
                          {formik.errors.advertiserDisclosure}
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          {/* <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: '500' }}>
                Copyright Section
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={formik.values.copyrightToggle}
                      onChange={() => {
                        formik.setFieldValue(
                          'copyrightToggle',
                          !formik.values.copyrightToggle
                        );
                        dispatch(setToggleField({ field: 'copyrightToggle' }));
                      }}
                    />
                  }
                  label=""
                  labelPlacement="end"
                  onChange={e => {
                    formik.handleChange(e);
                    dispatch(setToggleField({ field: 'copyrightToggle' }));
                  }}
                  sx={{ m: 1 }}
                />
              </FormGroup>
              {formik.values.copyrightToggle && (
                <TextField
                  placeholder="Copyright"
                  name="copyright"
                  value={formik.values.copyright}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ borderRadius: '8px', marginTop: '20px' }}
                  fullWidth
                />
              )}

              {formik.values.copyrightToggle &&
              formik.errors.copyright &&
              formik.touched.copyright ? (
                <Box color="#db2424">{formik.errors.copyright}</Box>
              ) : null}
            </Box> */}
        </FormikProvider>
      </form>
    </Paper>
  );
};

export default WebSiteInfo;
