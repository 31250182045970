import { Flag } from '@mui/icons-material';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAdvertiser, deleteAdvertiser, deleteAdvertiserCamp, updateAdvertiser,updateStatusAdvCamp } from '../../Services/advertiserTabApis';
import { baseURLConfig } from '../../config/envConfig';
import { generateHeader } from '../../service/Auth';
import { fetchVendor } from '../../service/User';

interface AdvertiserIn {
    Adv_ID: string;
    Advertiser_Name: string;
    // User: string;
    Website: string;
    Status: string;
    Brand_Name: string;
    Created_on: string;
}

interface AdvertiserListState {
  data: AdvertiserIn[];
  loading: boolean;
  currentCursor: string | null;
  hasMoreData: boolean;
  flag:boolean;
  scrollNext:boolean;
  fullData:any;
  brandNames: string[];
  brandNamesNew: string[];
  compApiData:AdvertiserIn[];
  fullApiData:any;
  isoLoading:boolean;
  searchInput:string;
  noDataFlag:boolean;
  vendorName:string;
  pageLoading:boolean;
  vendorList:any[],
  pageError:any;
}

const initialState: AdvertiserListState = {
  data: [],
  loading: false,
  currentCursor: null,
  hasMoreData: true,
  flag:false,
  scrollNext:false,
  brandNames: [],
  brandNamesNew: [],
  fullData:[],
  compApiData:[],
  fullApiData:[],
  isoLoading:false,
  searchInput:'',
  noDataFlag:false,
  vendorName:'',
  pageLoading:false,
  vendorList:[],
  pageError:''
};

const baseURL = baseURLConfig.baseURl;
export const fetchBrandNames = createAsyncThunk('advertiser/fetchBrandNames', async () => {
    try {
       const headers=await generateHeader()
        const response = await fetch(`${baseURL}/brands`,{headers});
        const data = await response.json();
        const brandNames = Object.keys(data.data.values);
        // console.log("valueeeeeeee",brandNames)
        const sortedBrandNames = brandNames.sort();
        // console.log('sortedBrandNames',brandNames)
        return sortedBrandNames;
    } catch (error) {
      throw new Error('Failed to fetch brand names');
    }
  });

  export const fetchBrandNamesNew = createAsyncThunk('advertiser/fetchBrandNamesnew', async () => {
    try {
       const headers=await generateHeader()
        const response = await fetch(`${baseURL}/getBrandMapping`,{headers});
        const data = await response.json();
        const brandNamesjj = data.data.values.map((item: any) => item.mBrandName);
         const sortedBrandNamess = brandNamesjj.sort();
        console.log('sortedBrandNamess',sortedBrandNamess)
        return sortedBrandNamess;
    } catch (error) {
      throw new Error('Failed to fetch brand names');
    }
  });
  export const fetchAllVendorData = createAsyncThunk(
    'data/fetchAllVendorData',
    async () => {
      // const response = await fetchAllPublisherCampaignsData(path, queryParamsObject)
      const response=await fetchVendor()
      return response.result; 
    }
  );
  

export const createAdvertiserAsync = createAsyncThunk(
  'advertiserAccApi/createAdvertiser',
  async (payload:any, { rejectWithValue }) => {
    try {
      const response = await createAdvertiser(payload);
      return response;
    } catch (error:any) {
      return rejectWithValue(error.message);
    }
  }
);
  
// Define an async thunk for updating an advertiser
export const updateAdvertiserAsync = createAsyncThunk(
  'advertiserAccApi/updateAdvertiser',
  async ( payload:any , { rejectWithValue }) => {
    try {
      const response = await updateAdvertiser( payload);
      return response;
    } catch (error:any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAdvertiserAsync = createAsyncThunk(
  'advertiserAccApi/deleteAdvertiser',
  async (payload:any, { rejectWithValue }) => {
    try {
      const response = await deleteAdvertiser(payload);
      return response;
    } catch (error:any) {
      return rejectWithValue(error.message);
    }
  }
);

const advertiserAccApiSlice = createSlice({
  name: 'advertiserAccList',
  initialState,
  reducers: {
    
    setData: (state, action: PayloadAction<AdvertiserIn[]>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCurrentCursor: (state, action: PayloadAction<string | null>) => {
      state.currentCursor = action.payload;
    },
    setHasMoreData: (state, action: PayloadAction<boolean>) => {
      state.hasMoreData = action.payload;
    },
    appendData: (state, action: PayloadAction<AdvertiserIn[]>) => {
      if (state.flag) {
        // console.log("yes");
        state.data = [...state.data, ...action.payload];
      } else {
        // console.log("no");
        state.data = action.payload;
      }
    },

    setFlag: (state, action: PayloadAction<boolean>) => {
      state.flag = action.payload;
    },
    setScrollNext: (state, action: PayloadAction<boolean>) => {
      state.scrollNext = action.payload;
    },
    setNoDataFlag: (state, action: PayloadAction<boolean>) => {
      state.noDataFlag = action.payload;
    },
    setFullData: (state, action: PayloadAction<any>) => {
      state.fullData = action.payload;
    },
    setApiFullData: (state, action: PayloadAction<any>) => {
      state.compApiData = action.payload;
    },
    setIsoLoading: (state, action: PayloadAction<boolean>) => {
      state.isoLoading = action.payload;
    },
    setSearchInput: (state, action: PayloadAction<any>) => {
      state.searchInput = action.payload;
    },
    resetSearchInput: (state) => {
      state.searchInput = '';
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchAllVendorData.pending,(state, action)=>{
       state.pageLoading=true;
    })
    builder.addCase(fetchAllVendorData.fulfilled,(state, action)=>{
      console.log("action payload",action.payload)
      state.vendorList=action.payload;
      state.pageLoading=false;
   })
   builder.addCase(fetchAllVendorData.rejected,(state, action)=>{
    // state.vendorList=action.payload;
    state.pageLoading=true;
    // state.pageError=action.payload
 })
 builder.addCase(fetchBrandNamesNew.pending, (state, action) => {
      // state.brandNamesNew = action.payload;
      state.pageLoading=true;
    }) 
      builder.addCase(fetchBrandNamesNew.fulfilled, (state, action) => {
        state.brandNamesNew = action.payload;
        state.pageLoading=false;
      })  
      builder.addCase(updateAdvertiserAsync.rejected, (state, action) => {
        // Handle the successful update of an advertiser if needed
        state.pageLoading=true;
      }) 
  },

});


/////////////////adv campaign

interface AdvertiserCampIn {
  campaign_id: string;
  campaign_name: string;
  Adv_ID: string;
  Advertiser_Name: string;
  Brand_ID: string;
  Brand_Name: string;
  Status: string;
  error:boolean;
  
}

interface AdvertiserCampListState {
datacamp: AdvertiserCampIn[];
campLoading: boolean;
currentCampCursor: string | null;
hasCampMoreData: boolean;
campFlag:boolean;
blockScroll:boolean;
fullDataCamp:any;
compApiDataCamp:AdvertiserCampIn[];
fetchFlagAdvCamp:boolean;
campaignDataWithLimits:any[];
searchFilterData:any[];
filterSearchAdvData:[];
FrontEndSearchInput:string;
isReload:boolean;
verticalFilter:string;
}

const initialCampState: AdvertiserCampListState = {
  datacamp: [],
  campLoading: false,
  currentCampCursor: null,
  hasCampMoreData: true,
  campFlag: false,
  blockScroll: false,
  fullDataCamp: [],
  compApiDataCamp: [],
  fetchFlagAdvCamp: false,
  campaignDataWithLimits: [],
  searchFilterData: [],
  filterSearchAdvData: [],
  FrontEndSearchInput: '',
  isReload: false,
  verticalFilter: ''
};

//For status toggle switch not for edit icon.
export const statusUpdateAdvCampAsync = createAsyncThunk(
  'advertiserAccCampApi/updateAdvertiserCamp',
  async ( { SORTID, ID ,requestData }: { SORTID: any; ID: any ,requestData: any }, { rejectWithValue }) => {
    try {
      const response = await updateStatusAdvCamp(SORTID, ID, requestData);
      return response;
    } catch (error:any) {
      return rejectWithValue(error.message);
    }
  }
);


export const deleteAdvertiserCampAsync = createAsyncThunk(
  'advertiserCampApi/deleteAdvertiserCamp',
  async (payload:any, { rejectWithValue }) => {
    try {
      const response = await deleteAdvertiserCamp(payload);
      return response;
    } catch (error:any) {
      return rejectWithValue(error.message);
    }
  }
);

const advertiserCampApiSlice = createSlice({
  name: 'advertiserCampList',
  initialState:initialCampState,
  reducers: {
    setFilterSearchAdvData: (state, action: PayloadAction<[]>) => {
      state.filterSearchAdvData = action.payload;
    },
    setFields: (state, action) => {
      const { field, value } = action.payload;
      (state as any)[field] = value;
    },
    setAdvCampData: (state, action: PayloadAction<AdvertiserCampIn[]>) => {
      state.datacamp = action.payload;
    },
    setCampLoading: (state, action: PayloadAction<boolean>) => {
      state.campLoading = action.payload;
    },
    setCurrentCampCursor: (state, action: PayloadAction<string | null>) => {
      state.currentCampCursor = action.payload;
    },
    setHasCampMoreData: (state, action: PayloadAction<boolean>) => {
      state.hasCampMoreData = action.payload;
    },
    setFetchFlagAdvCamp: (state, action: PayloadAction<boolean>) => {
      state.fetchFlagAdvCamp = action.payload;
    },

    appendCampData: (state, action: PayloadAction<AdvertiserCampIn[]>) => {
      if(state.campFlag){
      state.datacamp = [...state.datacamp, ...action.payload];
      }else{
        state.datacamp=[];
        // console.log('state.datacamp',state.datacamp);
        state.datacamp = action.payload;
        // console.log('state.datacamp',state.datacamp);
      }
    },
      updateIsReload:(state, action)=>{
          state.isReload=!state.isReload;
      },
    setCampFlag: (state, action: PayloadAction<boolean>) => {
      state.campFlag = action.payload;
    },
    setFrontEndSearchInput: (state, action: PayloadAction<any>) => {
      state.FrontEndSearchInput = action.payload;
    },
    setSearchFilterData: (state, action: PayloadAction<[]>) => {
      state.searchFilterData = action.payload;
    },
    setBlockScroll: (state, action: PayloadAction<boolean>) => {
      state.blockScroll = action.payload;
    },
    setFullCampData: (state, action: PayloadAction<any>) => {
      state.fullDataCamp = action.payload;
    },
    setApiFullCampData: (state, action: PayloadAction<any>) => {
      state.compApiDataCamp = action.payload;
    },
    storeLimitCampaignsData:(state, action)=>{
       state.campaignDataWithLimits= action.payload;
    },
    appendLimitCampaignsData: (state, action: PayloadAction<AdvertiserCampIn[]>) => {
      if(state.campFlag){
      state.campaignDataWithLimits = [...state.campaignDataWithLimits, ...action.payload];
      }else{
        state.campaignDataWithLimits = action.payload;
      }
    }
    
  },
});


export const {setFields,setAdvCampData,setFilterSearchAdvData,setCampLoading,setCurrentCampCursor,setHasCampMoreData,appendLimitCampaignsData,appendCampData,setCampFlag,setFrontEndSearchInput,setBlockScroll,setFetchFlagAdvCamp,setFullCampData,setApiFullCampData, storeLimitCampaignsData,setSearchFilterData, updateIsReload} = advertiserCampApiSlice.actions;
export const advertiserCampApi = advertiserCampApiSlice.reducer;

export const {setData,setLoading,setCurrentCursor,setHasMoreData,appendData,setFlag,setScrollNext,setNoDataFlag,setIsoLoading,setSearchInput,resetSearchInput,setFullData,setApiFullData} = advertiserAccApiSlice.actions;
export default advertiserAccApiSlice.reducer;