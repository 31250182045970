// Assume selectedEvents is an array containing the selected events from FullCalendar
import moment from 'moment';
import { JsonData, ProgramData } from '../Mock/ProgramTargetingData';
import { Key } from '@mui/icons-material';
import { createEventId } from '../Mock/Poc/FullCalender';
import 'moment-timezone';




interface ConvertedEvents {
  [dayOfWeek: string]: {
    [time: string]: {
      include: boolean;
    };
  };
}



//   export const formatEvents=(selectedEvents:any [])=>{
//     const convertedEvents: ConvertedEvents = {};
//     selectedEvents.forEach((event:any) => {
//         const start = moment(event.start)
//         const end = moment(event.end);

//         const currentDate = start.clone();
//         while (currentDate.isSameOrBefore(end, 'day')) {
//           const dayOfWeek = currentDate.format('dddd');
//           const time = currentDate.format('hA');

//           if (!convertedEvents[dayOfWeek]) {
//             convertedEvents[dayOfWeek] = {};
//           }

//           convertedEvents[dayOfWeek][time] = { include: true };

//           currentDate.add(1, 'day');
//         }
//       });
//       return convertedEvents; 

//   }


export const formatEvents = (selectedEvents: any[]) => {

  const convertedEvents: ConvertedEvents = {};

  selectedEvents.forEach((event: any) => {
    const start = moment(event.start);
    const end = moment(event.end);

    const dayOfWeek = start.format('dddd');
    const startTime = start.format('hA');
    const endTime = end.format('hA');

    if (!convertedEvents[dayOfWeek]) {
      convertedEvents[dayOfWeek] = {};
    }

    const hours = end.diff(start, 'hours');
    let currentTime = start.clone();

    for (let i = 0; i <= hours; i++) {
      const currentTimeStr = currentTime.format('hA');
      convertedEvents[dayOfWeek][currentTimeStr] = { include: true };
      currentTime.add(1, 'hour');
    }

    // Set include value to false for the end time slot
    if (convertedEvents[dayOfWeek][endTime]) {
      convertedEvents[dayOfWeek][endTime].include = false;
    }


  });
  return convertedEvents
}


export const createEvents = (jsonData: ConvertedEvents) => {
  // Assume the provided JSON data is stored in a variable called `jsonData`

  const events = [];

  for (const dayOfWeek in jsonData) {
    const timeSlots = jsonData[dayOfWeek];

    for (const time in timeSlots) {
      const include = timeSlots[time].include;

      if (include) {
        const startTime = moment(`${dayOfWeek} ${time}`, 'dddd hA');
        const endTime = startTime.clone().add(1, 'hour');
        // const eventStart = new Date(`${todayStr} ${time}`);
        // const eventEnd = new Date(eventStart.getTime() + 3600000); // Event duration: 1 hour
        events.push({
          id: createEventId(),
          title: 'Campaign On', // Set your own event title
          start: startTime.toISOString(),
          end: endTime.toISOString(),
          allDay:false,
          overlap:false
        });
      }
    }
  }
  return events
  // console.log(events);

}

export const createEvents1 = (jsonData: ConvertedEvents) => {
  // Assume the provided JSON data is stored in a variable called `jsonData`

  const events = [];

  for (const dayOfWeek in jsonData) {
    const timeSlots = jsonData[dayOfWeek];
    let startTime;
      let endTime;
    for (const time in timeSlots) {
      const include = timeSlots[time].include;
     
      if (include) {
        if(!startTime){
          startTime = moment(`${dayOfWeek} ${time}`, 'dddd hA');
        }
        
         endTime = startTime.clone().add(1, 'hour');
        // const eventStart = new Date(`${todayStr} ${time}`);
        // const eventEnd = new Date(eventStart.getTime() + 3600000); // Event duration: 1 hour
        // events.push({
        //   id: createEventId(),
        //   title: 'Campaign On', // Set your own event title
        //   start: startTime.toISOString(),
        //   end: endTime.toISOString(),
        //   allDay:false,
        //   overlap:false
        // });
       
      }else{
        startTime = moment(`${dayOfWeek} ${time}`, 'dddd hA');
        endTime = startTime.clone().add(1, 'hour');

        // console.log("start time , if not included  ", startTime);
        // console.log("endTime if not included", endTime)
      }
    }
    //  events.push({
    //       id: createEventId(),
    //       title: 'Campaign On', // Set your own event title
    //       start: startTime?.toISOString(),
    //       end: endTime?.toISOString()
    //       // allDay:false,
    //       // overlap:false
    //     });

    // console.log("start time , ", startTime?.format('YYYY-MM-DD h:mm:ss a'))
    // console.log("endTime", endTime?.format('YYYY-MM-DD h:mm:ss a'))
  }
  //  return events
  // console.log(events);

}

export function generateEvents(data: ConvertedEvents) {
  const events:any[] = [];
  let currentEvent:any = null;

  for (const day in data) {
    for (const time in data[day]) {
      const include = data[day][time].include;

      if (include) {
        if (!currentEvent) {
          currentEvent = {
            id: generateThreeDigitUniqueId(),
            title: "Campaigns On time",
            start: moment(`${day} ${time}`, "dddd hA").toISOString(),
          };
        }
      } else {
        if (currentEvent) {
          currentEvent.end = moment(`${day} ${time}`, "dddd hA").toISOString();
          events.push(currentEvent);
          currentEvent = null;
        }
      }
    }

    if (currentEvent) {
      currentEvent["end"] = moment(`${day} 11:59PM`, "dddd hA").toISOString();
      events.push(currentEvent);
      currentEvent = null;
    }
  }

  return events;
}



export function generateThreeDigitUniqueId() {
  const min = 100;
  const max = 999;
  const uniqueId = Math.floor(Math.random() * (max - min + 1)) + min;

  return uniqueId.toString();
}

// Example usage:
// const myThreeDigitUniqueId = generateThreeDigitUniqueId();
// console.log(myThreeDigitUniqueId);




// export function generateEvents(data :ConvertedEvents) {
//   const events:any[] = [];
//   let currentEvent:any = null;

//   for (const day in data) {
//     for (const time in data[day]) {
//       const include = data[day][time].include;

//       if (include) {
//         if (!currentEvent) {
//           currentEvent = {
//             id: events.length + 1,
//             title: "New Event",
//             start: moment(`${day} ${time}`, "dddd hA").toISOString(),
//           };
//         }
//       } else {
//         if (currentEvent) {
//           currentEvent.end = findNextIncludeTime(data, day, time) || moment(`${day} 11:59PM`, "dddd hA").toISOString();
//           events.push(currentEvent);
//           currentEvent = null;
//         }
//       }
//     }

//     if (currentEvent) {
//       // If the last event extends beyond midnight, set the end time to 11:59PM of the next day
//       const nextDay = moment(Object.keys(data)[0]).add(1, 'day').format('dddd');
//       currentEvent.end = findNextIncludeTime(data, day, '11PM') || moment(`${nextDay} 11:59PM`, "dddd hA").toISOString();
//       events.push(currentEvent);
//       currentEvent = null;
//     }
//   }

//   return events;
// }

// function findNextIncludeTime(data:any, currentDay:any, currentTime:any) {
//   const days = Object.keys(data);
//   const times = Object.keys(data[days[0]]);

//   let nextDay = days.indexOf(currentDay);
//   let nextTime = times.indexOf(currentTime) + 1;

//   while (nextDay < days.length) {
//     while (nextTime < times.length) {
//       if (data[days[nextDay]][times[nextTime]].include) {
//         return moment(`${days[nextDay]} ${times[nextTime]}`, "dddd hA").toISOString();
//       }
//       nextTime++;
//     }
//     nextTime = 0;
//     nextDay++;
//   }

//   return null;
// }


export const convertMprogramValueToPayload = (mPrograms: JsonData) => {
  const mProgramKey = Object.keys(mPrograms)

  // console.log("mProgram Keys",)
  const mProrgramsPayload: any = {}
  mProgramKey.forEach((item, index) => {
    const programByAreaStudy = mPrograms[item];
    programByAreaStudy.forEach((item: any, index: number) => {
      mProrgramsPayload[item.value] = item
    })
  })


  return mProrgramsPayload
}
interface program {
  text?: string;
  value?: string;
  id?: string;
  status?: boolean;
  trackingUrl?: string;
  areaStudy: string;
  bidPrice?: string;
}

type GroupedData = Record<string, program[]>;



export const mProgramResponseToComponentProps = (data: program[]) => {
  const grouped: GroupedData = {};

  for (const course of data) {
    if (!grouped[course.areaStudy]) {
      grouped[course.areaStudy] = [];
    }
    grouped[course.areaStudy].push(course);
  }

  return grouped;
}


export const mProgramResponseToComponentProps1 = (data: any) => {
  const grouped: any = {};
  // console.log("data ", data)
  for (const course of data) {
    if (!grouped[course.areaStudy]) {
      grouped[course.areaStudy] = [];
    }
    // console.log(`Index: ${index}, Value: ${course}`);
    // const updatedCourse={...course, "value":"100"}
    // console.log("updated course", updatedCourse)
    grouped[course.areaStudy].push(course);
  }

  return grouped;
};

export const addProgramValue = (mProgram: any) => {
  const output: any = {};
  for (const key in mProgram) {
    if (mProgram.hasOwnProperty(key)) {
      const originalItem = mProgram[key];
      const newItem = { ...originalItem, value: key, id:key };
      output[key] = newItem;
    }
  }
  return output
}

// interface InputDataItem {
//   text: string;
//   value: string;
//   id: string;
//   status: boolean;
//   bidPrice: string;
//   categories: string;
//   categoryId: number;
// }

// type InputData = Record<string, InputDataItem[]>;

// interface OutputDataItem {
//   bid: number;
//   enable: boolean;
// }

// type OutputDataCategory = Record<string, OutputDataItem>;

// interface OutputData {
//   [categoryId: string]: OutputDataCategory & { title: string };
// }

// function transformInputToOutput(inputData: InputData): OutputData {
//   const outputData: OutputData = {};

//   for (const categoryId in inputData) {
//       const categoryItems = inputData[categoryId];
//       const categoryOutput: OutputDataCategory = {};

//       for (const item of categoryItems) {
//           categoryOutput[item.value] = {
//               bid: 0,
//               enable: item.status,
//           };
//       }

//       outputData[categoryId] = {
//           ...categoryOutput,
//           title: categoryItems[0].categories,
//       };
//   }

//   return outputData;
// }

// // Example usage:
// const inputData: InputData = { /* Your input data here */ };
// const outputData: OutputData = transformInputToOutput(inputData);

// console.log(outputData);



// interface InputDataItem {
//   text: string;
//   value: string;
//   id: string;
//   status: boolean;
//   bidPrice: string;
//   categories: string;
//   categoryId: number;
// }

// type InputData = Record<string, InputDataItem[]>;

// interface OutputDataItem {
//   bid: number;
//   enable: boolean;
// }

// type OutputData = Record<string, Record<string, OutputDataItem> & { title: string }>;

// function transformInputToOutput(inputData: InputData): OutputData {
//   const outputData: OutputData = {};

//   for (const categoryId in inputData) {
//       const categoryItems = inputData[categoryId];
//       const categoryOutput: Record<string, OutputDataItem> = {};

//       for (const item of categoryItems) {
//           categoryOutput[item.value] = {
//               bid: 0,
//               enable: item.status,
//           };
//       }

//       outputData[categoryId] = {
//           ...categoryOutput,
//           title: categoryItems[0].categories,
//       };
//   }

//   return outputData;
// }

// // Example usage:
// const inputData: InputData = { /* Your input data here */ };
// const outputData: OutputData = transformInputToOutput(inputData);

// console.log(outputData);


// interface InputData {
//   [categoryId: string]: {
//       text: string;
//       value: string;
//       id: string;
//       status: boolean;
//       bidPrice: string;
//       categories: string;
//       categoryId: number;
//   }[];
// }

// interface OutputData {
//   [categoryId: string]: {
//       [value: string]: {
//           bid: number;
//           enable: boolean;
//       };
//       title: string;
//   };
// }

export function generateRequiredObjectForAudienceTargeting(inputData: any): any {
  const outputData: any = {};

  for (const categoryId in inputData) {
    const categoryItems = inputData[categoryId];
    const categoryOutput: { [value: string]: { bid: number; enable: boolean } } = {};

    for (const [index, item] of categoryItems.entries()) {
      categoryOutput[item.value] = {
        bid: item.bidPrice,
        enable: item.status,
      };
      //  categoryItems.forEach((item:any, index:any)=>{
      //   categoryOutput[index+1] = {
      //           bid: 0,
      //              enable: item.status,
      //          };
      //  })
      // Object.assign({...outputData}, )
    }
    const newObj: any = {

    }
    newObj[categoryItems[0].categories] = {
      ...categoryOutput,
      title: categoryItems[0].categories,
    };
    //  console.log("new obj", newObj)
    //  console.log("op", outputData)
    Object.assign(outputData, { ...newObj })
  }
  // console.log("outPutData", inputData)

  // const formatViceVersa=mapResponseForAudienceTargetingDataForEditOrCreateAdvCampaignsComponentProps(outputData,inputData)
  // console.log("Mapped the payload datat for audince targeting to component props simmilar to input data for this function::::::::::::", formatViceVersa)
  return outputData;
}


export const mapResponseForAudienceTargetingDataForEditOrCreateAdvCampaignsComponentProps = (
  apiResponse: any,
  inputDataRef: any
) => {
  let outputDataRef = inputDataRef;
  const demo = { ...inputDataRef }

  // console.log("demo", demo)
  // const adjustmentKeys = Object.keys(apiResponse);
  // console.log("keys adjustent", adjustmentKeys);
  // console.log("API rers", apiResponse);
  const adjustmentKeys = ['campusType', 'highestEducation', 'degreeInterest', 'startTimeframe', 'militaryAffiliation', 'mediaType', 'inventoryType',]
  // console.log("OutputData ref :::::::::::::", outputDataRef);
  adjustmentKeys.forEach((item, index) => {
    const inputDataEachKeyItem = apiResponse[item];
    const keysOfChildObject = Object.keys(inputDataEachKeyItem).filter(key => key !== "title");;
    // console.log("child object keys", keysOfChildObject);
    let valueOfEachAdjustmentKey = demo[index + 1];
    const updatedObjects: any = [];
    keysOfChildObject.forEach((key, iv) => {
      const valueOfKey = inputDataEachKeyItem[key];
      // console.log("EXCLUDE ADJUSTMENT;;;;;;:::", valueOfEachAdjustmentKey[iv])
      if(valueOfEachAdjustmentKey[iv]){
        const updatedObject = {
          ...valueOfEachAdjustmentKey[iv],
          bidPrice: valueOfKey.bid,
          status: valueOfKey.enable
        }
      
        updatedObjects.push(updatedObject);
      }
      
    });

    // valueOfEachAdjustmentKey=updatedObjects
    // outputDataRef[index+1]=updatedObjects
    // console.log("Updated objects of arrays of each key value ", updatedObjects);
    demo[index + 1] = updatedObjects
    // console.log("UPdated demo objects which is output ", demo)
  });
  // console.log("new input ref update", demo);
  return demo;
};



export function getCampaignStatus(schedule:any) {
  // Object.keys(schedule).length>1
  
  if(schedule){
    const currentTime = moment().tz('America/Los_Angeles');; // Current time in local time zone
    const currentDay = currentTime.format('dddd'); // Get the current day of the week
    const currentHour = currentTime.format('hA'); // Get the current hour in AM/PM format
   
    // console.log("Current time ", currentTime)
    // console.log("Current Day", currentDay)
    // console.log("Currnet hour", currentHour)
    if (schedule[currentDay] && schedule[currentDay][currentHour]) {
        return schedule[currentDay][currentHour].include ? 'Active' : 'Paused';
    } else {
        return 'Paused';
    }
  }else{
    return "Active"
  }
  

}