
import { AppBar, Box, Grid, Paper, Typography , useMediaQuery, useTheme} from '@mui/material'
import React, { useEffect, useState } from 'react'
import './overall.scss'
import MultipleSelectDropDown from '../../../../Component/DropDowns'
import { areaStudyDropdown, timeLineDropDown } from '../../../../Mock/Dashboard Data/OverallData'
// import { useAppDispatch, useAppSelector } from '../../../../Store/hooks'
// import { cleanUpState, getCampaignsThunk, updateSelectedCampaigns } from '../../../../Store/Slices/Publisher-role/Dashboard/publisherDashboardSlice'
import { calculateMetrics, createBarChartJson, debounce, overallPageBarOptions , advOverallPageBarOptions} from '../../../../Store/Slices/Publisher-role/Dashboard/utils'
import { Bar} from 'react-chartjs-2'

import {
  Chart as ChartJS,
  registerables
} from 'chart.js';

import BasicSelect from '../../../../Component/DropDowns/BasicSelect'
import { getPubDashboardOverallTableReports, getPubDashboardReports } from '../../../../service/Publisher'
import SkeletonLoader from '../../../../Component/Skeleton Loader'
import { getAdvDashboardReports, getAdvertiserDashboardOverallTableReports } from '../../../../service/Advertiser'
import { advColumns, advSummary, columns, overallAdvGroupItem, overallPubGroupItem, pubSummary } from './constant'
import DxGrid from '../../../../Component/Data Grid/DxGrid'

ChartJS.register(
  ...registerables
);
interface Row {
  id?: number;
  "Date/Time": string;
  "Total Commission": string;
  publisherName: string;
  Ad_Requests: number;
  campaignName: string;
  CLICKS: number;
  CPC: string;
  CTR: string;
  "Avg position": number;
}

interface dropdownMenus {
  label: string;
  value: string
}
interface Ioverall {
  campaignDropDownMenus: dropdownMenus[]
  defaultBarDataSet: any
  dashboardType?:'advertiser' | 'publisher'
  
}
const Overall = (props: Ioverall) => {
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[] | []>(props.campaignDropDownMenus.map(item => item.value))
  const [contentLoading, setContentLoading] = useState(true);
  const [rows, setRows] = useState<Row[] | any>()
  const [selectedAreaStudy, setSelectedAreaStudyValue] = useState(areaStudyDropdown.map(name => name.value));
  const [barChartData, setBarChartData] = useState<any>()
  const [dateRange, setDateRange] = useState(timeLineDropDown[0].value)
  const [summaryData, setSummaryData]=useState<any>()
  const [gridColumns,setGridColumns]=useState(columns);
  const [summaryItem,setSummaryItem]=useState(overallPubGroupItem);
  const [chartOptions,setChartOptions]=useState(overallPageBarOptions);
  const handelSelectedCampaigns = (value: string[]) => {
   setSelectedCampaigns(value)
  }

  
  const fetchReports = async (queryParams: any, barDataSet: any) => {
    const publisherChartApiPath = "publisher/dashboard"
    const publisherTableApiPath= "publisher/overall"
    
    const advertiserChartApiPath = "advertiser/dashboard"
    const advertiserTableApiPath= "advertiser/overall"
    setContentLoading(true)
    try {
let fetchRef:any[]=[]
      if(props.dashboardType==="publisher"){
        fetchRef=[
          getPubDashboardReports(publisherChartApiPath, queryParams),
          getPubDashboardOverallTableReports(publisherTableApiPath, queryParams)
        ]
        setGridColumns(columns)
        setChartOptions(overallPageBarOptions)
      }else if(props.dashboardType==="advertiser"){
// assign adv Dashboard
         fetchRef=[
            getAdvDashboardReports(advertiserChartApiPath , queryParams),
             getAdvertiserDashboardOverallTableReports(advertiserTableApiPath, queryParams)
       ]
       setChartOptions(advOverallPageBarOptions)
       setGridColumns(advColumns)
      }
       
      const [response, groupByDateRes] = await Promise.all(fetchRef)
      

  //  console.log("res ::::CHARTS::::", response);
      const apiResponse = response.map((item: any) => {
        //  var momentDate = moment.utc(item['Date/Time']);
        return {
          "Date": item['Date/Time'].replaceAll("/", "-"),
          "Commission": item["Total Commission"],
          "campaign": item.campaignName,
          "CLICKS": parseInt(item.CLICKS),
        }
      })
      const chartData = createBarChartJson(apiResponse, barDataSet)
      setBarChartData(chartData)
           const summaryData1:any=calculateMetrics(groupByDateRes.data)
      if(props.dashboardType==='advertiser'){

        const advTotal=advSummary.map((item)=>{
          return {
            ...item,
            label:item.label,
            value:item.value+""+summaryData1[item.key]
          }
        })
        const rows = groupByDateRes.data.map((item: any, index: number) => {
          const CTR=item['CTR']*100
          // console.log("ITEM OF ROW IN OVERALL", item)
           return {
            ID: index,
            ...item,
            "Date/Time": item['Date/Time'],
            "Total Spent": `$ ${item['Total Commission']}`,
            "CTR": `${CTR.toFixed(2)} %`,
            "RPC": `$ ${item['CPC']}`
          }
        })
        setRows(rows.filter((item:any) => item.campaignName !== ""))
        setSummaryItem(overallAdvGroupItem)  
        setSummaryData(advTotal)
      }else if(props.dashboardType==='publisher'){
        const pubTotal=pubSummary.map((item)=>{
          return {
            ...item,
            label:item.label,
            value:item.value+""+summaryData1[item.key]
          }
        })
        setSummaryData(pubTotal)
        const rows = groupByDateRes.data.map((item: any, index: number) => {
          const CTR=item['CTR']*100
          // console.log("ITEM OF ROW IN OVERALL", item)
           return {
            ID: item.campaignId?item.campaignId:index,
            ...item,
            "Date/Time": item['Date/Time'],
            "Total Commission": `$ ${item['Total Commission']}`,
            "CTR": `${CTR.toFixed(2)} %`,
            "CPC": `$ ${item['CPC']}`
          }
        })
        setRows(rows.filter((item:any) => item.campaignName !== ""))
        setSummaryItem(overallPubGroupItem)  
      }
      
       
      
      



      setContentLoading(false)
    } catch (err) {
      console.log("err", err)

    }
  }
  const debounceOnChange = React.useCallback(debounce((queryParams, barDataSet) => {
    fetchReports(queryParams, barDataSet);
  }, 600), []);
  useEffect(() => {
    const pubId = localStorage.getItem('userSortid');
    let lastFiveCharacters
    if (pubId) {
      lastFiveCharacters = pubId.slice(-5);
    }
    const queryParams: any = {
      mPubID: lastFiveCharacters,
      mPubCampaignID: selectedCampaigns.join(','),
      areaStudy: selectedAreaStudy.join(','),
      chartType: dateRange
    }
    const { mPubCampaignID, areaStudy } = queryParams
    if (dateRange && mPubCampaignID && areaStudy) {
      console.log("QP:::::::::::::::", queryParams)
      debounceOnChange(queryParams, props.defaultBarDataSet)
    }

  }, [selectedAreaStudy, dateRange, selectedCampaigns])
 
console.log("grid columns for the adv", gridColumns)
console.log("sumary data", summaryData)

  return (
    <Paper elevation={3} className="overall-page-container card">
      <Box p={3} >


        <Box display="flex" justifyContent="space-between" mb={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Box>
            <Typography className="card-heading">Overview</Typography>
            <Typography className="card-sub-heading">
              Reports
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} lg={4}>

            <BasicSelect value={dateRange} setValue={setDateRange} menuOptions={timeLineDropDown} selectLabel={"Time Frame"} />
          </Grid>
          <Grid item xs={12} lg={4}>

            <MultipleSelectDropDown dropdownLabel="Area Study" dropdownData={areaStudyDropdown} dropDownValue={selectedAreaStudy} setDropDownValue={setSelectedAreaStudyValue} />

          </Grid>
          {
            props.dashboardType==='advertiser' &&
            <Grid item xs={12} lg={4}>

            {/* <MultipleSelectDropDown dropdownLabel="Advertiser" dropdownData={areaStudyDropdown} dropDownValue={selectedAreaStudy} setDropDownValue={setSelectedAreaStudyValue} /> */}
            <BasicSelect value={dateRange} setValue={setDateRange} menuOptions={timeLineDropDown} selectLabel={"Advertiser"} />
          </Grid>
          }
          <Grid item xs={12} lg={4}>
            <MultipleSelectDropDown dropdownLabel="Campaign" dropdownData={props.campaignDropDownMenus} dropDownValue={selectedCampaigns} setDropDownValue={handelSelectedCampaigns} />

          </Grid>
        </Grid>
        <Box className="main-section-charts-table">
          <Paper className='chart-box' sx={{ marginBottom: "24px" }}>
            {
              contentLoading ? <SkeletonLoader variant={'rectangular'} width='100%' height='400px' /> :

              (barChartData.labels.length>0?
                <Bar data={barChartData} options={chartOptions} />:<Box width='100%' height='400px' display={'flex'} >
                   
                   No Data found
                </Box>)

            }
          </Paper>
          {
              contentLoading ? <SkeletonLoader variant={'rectangular'} width='100%' height='30px' /> :
              <Box mb={3}  >
              <MyGrid summaryData={summaryData}/>
            </Box>
          }
         
          <Paper className="data-grid"     >
            {
              contentLoading ? <SkeletonLoader variant={'rectangular'} width='100%' height='600px' /> :
                // <DataGridWithExport rows={rows} columns={gridColumns}  />
    
                <DxGrid rows={rows} columns={gridColumns} summaryItem={summaryItem} />

            }
          </Paper>


        </Box>
      </Box>
    </Paper>
  )
}

export default Overall


// import React from 'react';
// import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
interface summaryI{
  summaryData:{
   label:string;
   value:string
  }[]
}

export const MyGrid = (props:summaryI) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container spacing={2} sx={{justifyContent:"center"}}>
            {/* Five grid items in a single row */}
            {props.summaryData.map((item, index)=>{
             return (
             <Grid key={index} item xs={isSmallScreen ? 12 : 2} style={{ maxWidth: isSmallScreen ? '100%' : '20%' }}>
             <Box>
                <Typography component={'h4'} fontSize={18} mb={0} lineHeight={1}>{item.label}</Typography>
                   <Typography fontSize={22} component={'h3'} fontWeight={'bold'}>{item.value}</Typography>
                  </Box>
             </Grid> 
             ) 
            })}
        </Grid>
    );
};




// Sum of widths of fixed-width columns
const fixedWidths = columns.reduce((total, column) => {
  return column.width && typeof column.width === 'number' ? total + column.width : total;
}, 0);

// Calculate the remaining width available for flexible columns
const remainingWidth = 100 - fixedWidths;

// Count the number of flexible columns
const flexibleColumnsCount = columns.filter(column => !column.width).length;

// Calculate the width for each flexible column
const flexibleColumnWidth = remainingWidth / flexibleColumnsCount;

// Update the columns with dynamically calculated widths for flexible columns
export const updatedColumns = columns.map(column => {
  if (!column.width) {
    return {
      ...column,
      width: flexibleColumnWidth
    };
  }
  return column;
});

// console.log("updatedColumns", updatedColumns)
