import axios from 'axios'
import{ baseURLConfig, awsConfig }from '../../config/envConfig';
import {  generateHeader } from '../Auth';
import AWS from 'aws-sdk';

export const fetchUser=async ()=>{
    const baseURL = baseURLConfig.baseURl
    const headers=await generateHeader();
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: baseURL + `/users`,
      headers
    };
    const res=await axios.request(config)
    return res.data
}


export const fetchVendor=async ()=>{
  const baseURL = baseURLConfig.baseURl
  const headers=await generateHeader();
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: baseURL + `/vendors`,
    headers
  };
  const res=await axios.request(config)
  return res.data
}






export const fetchPublisher=async()=>{
    const baseURL=baseURLConfig.baseURl
    const headers=await generateHeader();
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url:`${baseURL}/publishers?ID=publisher`,
      headers
    };
      // const promise1= await axios.get(`${baseURL}/publishers?ID=publisher`);
    //   const promise2=await axios.get(`${baseURL}/advertisers?ID=advertiser`); 
    const res=await axios.request(config)
    return res.data
      // return promise1.data
     
    }

    export const fetchAdvertiser=async()=>{
        const baseURL=baseURLConfig.baseURl
        const headers=await generateHeader();
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url:`${baseURL}/advertisers?ID=advertiser`,
          headers
        };
          // const promise1= await axios.get(`${baseURL}/publishers?ID=publisher`);
        //   const promise2=await axios.get(`${baseURL}/advertisers?ID=advertiser`); 
        const res=await axios.request(config)
        return res.data
          

        //   const promise1= await axios.get(`${baseURL}/publishers?ID=publisher`);
          // const promise2=await axios.get(`${baseURL}/advertisers?ID=advertiser`); 
        
          // return promise2.data
         
        }



export const createUser=async(requestPayload:any)=>{
  const baseURL = baseURLConfig.baseURl
  const headers=await generateHeader();
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url:`${baseURL}/users`,
    headers,
    data:requestPayload
  };

  const response=await axios.request(config)
  return response.data
  // console.log("re [posts", res)
}

export const updateUserService=async(requestPayload:any)=>{
  const baseURL = baseURLConfig.baseURl
  const headers=await generateHeader();
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url:`${baseURL}/users`,
    headers,
    data:requestPayload
  };
  // const res= await axios.put(baseURL + `/users`, requestPayload)
  const res=await axios.request(config)
  return res.data
}

export const removeUserService=async(requestPayload:any)=>{
  const baseURL = baseURLConfig.baseURl
  const headers=await generateHeader();
  let config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url:`${baseURL}/users`,
    headers,
    data:requestPayload
  };
  // const res= await axios.post(baseURL + `/users`, requestPayload)
  const res=await axios.request(config)
  // const res= await axios.delete(baseURL + `/users`, {data:requestPayload})
return res.data
}




//For vendor feature

export const createVendorGroup=async(params:any)=>{
  try{
    const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
    const result = await cognitoIdentityServiceProvider.createGroup(params).promise();
    console.log('Group created successfully:', result);
    return result;
  }catch(error){
   console.log(error)
   return error;
  }
 
  
}
// interface IVendor {
//   GroupName: string;
//   UserPoolId: string;
//   Precedence: number;
//   LastModifiedDate: any;
//   CreationDate: any;
// }

export async function listAllGroups(): Promise<any []> {
  let allGroups:  any[] = [];
  
  const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
  // Define the params object with an optional NextToken property
  let params: AWS.CognitoIdentityServiceProvider.ListGroupsRequest & { NextToken?: string } = {
    UserPoolId: awsConfig.Auth.userPoolId?awsConfig.Auth.userPoolId:'',
    Limit: 60,
  };

  try {
    let response;
    do {
      response = await cognitoIdentityServiceProvider.listGroups(params).promise();
       console.log('response', response);
       
      if (response.Groups) {
        allGroups = allGroups.concat(response.Groups);
      }

      // Update the NextToken in the params for the next iteration, if it exists
      params.NextToken = response.NextToken;
      
    } while (params.NextToken);
    const filtered = allGroups.filter(vendor =>
      vendor.GroupName.toLowerCase().includes('vendor')
  );
    console.log("filtered data ", filtered)
    return filtered;

  } catch (error) {
    console.error('Error listing groups:', error);
    throw error;
  }
}

// export async function deleteGroup(groupName:string){
//   const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
//   const params = {
//     GroupName: groupName,
//     UserPoolId:  awsConfig.Auth.userPoolId?awsConfig.Auth.userPoolId:'',
//   };

//   try {
//     await cognitoIdentityServiceProvider.deleteGroup(params).promise();
//     console.log(`Group '${groupName}' deleted successfully.`);
//     return 'success'
//   } catch (error) {
//     console.error('Error deleting group:', error);
//     throw error;
//   }
// }



   