import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { CircleLoader } from 'react-spinners';
import BasicTextField from '../../../../Component/Basic TextField/BasicInput';
import AreaCard from '../../../../Component/Card';
import BasicSelect from '../../../../Component/DropDowns/BasicSelect';
import { areaStudyDropdown, mPrograms, degreeInterest, highestEducation, startTimeframe, campusType, militaryAffiliation } from '../../../../Mock/Tools data/testApi'
import { buildUrl, fetchAllPublisherCampaignsData, fetchListing } from '../../../../service/Publisher';

const TestAPI = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const [listingLoading, setListingLoading] = useState(false);
  const defaultAreaStudyValue: string = areaStudyDropdown[0]?.value || '';
  const defaultProgramValue = mPrograms[defaultAreaStudyValue]?.[0]?.value || '';

  const [selectedAreaStudy, setSelectedAreaStudy] = useState<string>(defaultAreaStudyValue);
  const [selectedProgram, setSelectedProgram] = useState<string>(defaultProgramValue);
  const [programOptions, setProgramOptions] = useState<any[]>(mPrograms[selectedAreaStudy])
  const [selectedCampusType, setSelectedCampusType] = useState<string>(campusType[0].value);
  const [selectedPublisher, setSelectedPublisher] = useState<string>('')
  const [publisherOptions, setPublisherOptions] = useState<any[]>([])

  const [selectedCampaign, setSelectedCampaigns] = useState<string>('')
  const [campaignOptions, setCampaignOptions] = useState<any[]>([])

  const [selectedDOI, setSelectedDOI] = useState<string>(degreeInterest[2].value)
  const [selectedHE, setSelectedHE] = useState<string>(highestEducation[0].value)
  const [selectedSTF, setSelectedSTF] = useState<string>(startTimeframe[0].value)
  const [gradYear, setGradYear] = useState<string>("2008")

  const [selectedMilitary, setSelectedMilitary] = useState<string>(militaryAffiliation[0].value)
  const [sub1, setSub1] = useState<string>("Test")
  const [sub2, setSub2] = useState<string>("Test")
  const [sub3, setSub3] = useState<string>("Test")
  const [zipCode, setzipCode] = useState<string>("33303")
  const [listingUrl, setListingUrl] = useState('')
  const [listingData, setListingData] = useState<any>()
  const handleSub = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'sub1':
        setSub1(value);
        break;
      case 'sub2':
        setSub2(value);
        break;
      case 'sub3':
        setSub3(value);
        break;
      default:
        setSub1(value)
        break

    }
  }
  // const [doiOptions, setDOIOptions]=useState<any[]>([])

  const handleCategoryChange = (value: string) => {
    setSelectedProgram(mPrograms[value]?.[0]?.value);
    setSelectedAreaStudy(value);
    setProgramOptions(mPrograms[value])


  };

  const handleProgramChange = (value: string) => {
    // console.log("selected Program", selectedProgram)
    setSelectedProgram(value);
  };
  const handleDOIChange = (value: string) => {
    setSelectedDOI(value);
  };
  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setzipCode(event.target.value);
  };
  const handleMilitaryChange = (value: string) => {
    setSelectedMilitary(value);
  };
  const handleSTFChange = (value: string) => {
    setSelectedSTF(value);
  };

  const handleCampusTypeChange = (value: string) => {
    setSelectedCampusType(value);
  };

  const handleGradYear = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGradYear(event.target.value)
  }
  const handleHighestEducationChange = (value: string) => {
    setSelectedHE(value);
  };

  const handleCampaignChange = (value: string) => {
    // console.log("selected Program", selectedProgram)
    // setSelectedProgram(value);
    setSelectedCampaigns(value)
  };

  const fetchData = async (pubID: string) => {
    const arg2 = {
      path: "publishers/campaign",
      queryParamsObject: {
        publisherID: pubID
      }
    }
    const response = await fetchAllPublisherCampaignsData(arg2.path, arg2.queryParamsObject)
    return response.data
  }


  const initializeData = async () => {
    try {
      setPageLoading(true); // Set loading state to true before fetching data
      const publisherData = localStorage.getItem('userSortid');
      let lastFiveCharacters = '';
      if (publisherData) {
        lastFiveCharacters = publisherData.slice(-5);
      }

      const publisherResponse = await fetchData(lastFiveCharacters);
      const campaignData = publisherResponse.map((campaign: any) => ({
        label: campaign.campaignname + ` ${campaign.SORTID}`,
        value: `${campaign.SORTID}`
      }));
      // console.log('campaignData::::Test API', campaignData)
      campaignData.sort((a:any, b:any) => {
        const labelA = a.label.toUpperCase(); // Ignore upper and lowercase
        const labelB = b.label.toUpperCase(); // Ignore upper and lowercase
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
    
        // labels must be equal
        return 0;
    });
      setPublisherOptions([
        {
          label: publisherData ? publisherData : '',
          value: lastFiveCharacters
        }
      ]);
      setCampaignOptions(campaignData);
      setSelectedPublisher(lastFiveCharacters);
      setSelectedCampaigns(campaignData[0].value);
      setPageLoading(false); // Set loading state to false after fetching data
    } catch (error) {
      console.error('Error fetching data:', error);
      setPageLoading(false); // Set loading state to false if an error occurs
    }
  };

  useEffect(() => {
    initializeData()
  }, [])

  const handleSubmit = async () => {
    setListingLoading(true)
    try {
      let queryParams = {
        degreeInterest:selectedDOI,
        areaStudy: selectedAreaStudy,
        mProgram:selectedProgram,
        highestEducation: selectedHE,
        gradYear: gradYear,
        campusType: selectedCampusType,
        militaryAffiliation: selectedMilitary,
        startTimeframe: selectedSTF,
        sub1: sub1,
        sub2: sub2,
        sub3: sub3,
        zipCode: zipCode,
       
        mPubID: selectedPublisher,
        mPubCampaignID: selectedCampaign
      };

      const newQP={
        ...queryParams,
        redirect: 0,
       }
      // console.log("query params", newQP)
     
      const response = await fetchListing(queryParams)
      // console.log("new qp", newQP)
    
      const listingAPIURL = buildUrl(queryParams)

      setListingData(response)
      setListingLoading(false)
      setListingUrl(listingAPIURL)
    } catch (err) {
      setListingLoading(true)

    }

  }

  // const handleReset=()=>{
  //   setSelectedAreaStudy(defaultAreaStudyValue);
  //   setSelectedProgram(defaultProgramValue);
  //   setSelectedDOI(degreeInterest[0].value)
  //   setSelectedHE(highestEducation[0].value)

  // }


  const handleReset = () => {
    setSelectedAreaStudy(defaultAreaStudyValue);
    setSelectedProgram(defaultProgramValue);
    setProgramOptions(mPrograms[defaultAreaStudyValue])
    setSelectedDOI(degreeInterest[0].value);
    setSelectedHE(highestEducation[0].value);
    setSelectedSTF(startTimeframe[0].value);
    setSelectedCampusType(campusType[0].value);
    setSelectedMilitary(militaryAffiliation[0].value);
    setSub1('Test');
    setSub2('');
    setSub3('');
    setzipCode('33303');
    setGradYear('2008');
    setListingUrl('')
  };

  return (
    <>
      <Paper elevation={3} className="card">
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Box>
            <Typography className="card-heading">Test API</Typography>
            <Typography className="card-sub-heading">
              Test your campaigns with multiple options
            </Typography>
          </Box>
        </Box>
        {pageLoading ? <Box className="loading-spinner-container" sx={{
          minHeight: "500px"
        }}>

          <CircleLoader color="#00BFFF" loading={pageLoading ? true : false} size={60} />
        </Box> :
          <Grid container spacing={2} sx={{ margin: '3px', width: '99%', padding: "5px" }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4} sm={6} md={3} >
                  <BasicSelect value={selectedPublisher} menuOptions={publisherOptions} setValue={() => { }} selectLabel='Publisher' isDisable={true} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} md={3} >
                  <BasicSelect value={selectedCampaign} menuOptions={campaignOptions} setValue={handleCampaignChange} selectLabel='Publisher Campaign' />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4} >
                  <BasicTextField name="zipCode" label="Zip Code" value={zipCode} changeHandler={handleZipChange} />
                </Grid>

              </Grid>
            </Grid>

            {/* 2nd row */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedDOI} menuOptions={degreeInterest} setValue={handleDOIChange} selectLabel='Degree of Interest' />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedAreaStudy} menuOptions={areaStudyDropdown} setValue={handleCategoryChange} selectLabel='Area study' />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedProgram} menuOptions={programOptions} setValue={handleProgramChange} selectLabel='M programs' />
                </Grid>


              </Grid>
            </Grid>
            {/* 3rd row */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedHE} menuOptions={highestEducation} setValue={handleHighestEducationChange} selectLabel='Highest Education' />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>

                  <BasicTextField name="gradYear" label="Grad Year" value={gradYear} changeHandler={handleGradYear} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedSTF} menuOptions={startTimeframe} setValue={handleSTFChange} selectLabel='Start Time Frame' />
                </Grid>

              </Grid>
            </Grid>

            {/* 4th row */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={6}>
                  <BasicSelect value={selectedMilitary} menuOptions={militaryAffiliation} setValue={handleMilitaryChange} selectLabel='Military' />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={6}>
                  <BasicSelect value={selectedCampusType} menuOptions={campusType} setValue={handleCampusTypeChange} selectLabel='Campus Type' />
                </Grid>

              </Grid>
            </Grid>

            {/* 5th row */}

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  {/* <BasicSelect value={selectedHE} menuOptions={highestEducation} setValue={handleHighestEducationChange} selectLabel='Highest Education' /> */}
                  <BasicTextField name="sub1" label="sub1" value={sub1} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>

                  <BasicTextField name="sub2" label="sub2" value={sub2} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  {/* <BasicSelect value={selectedSTF} menuOptions={startTimeframe} setValue={handleSTFChange} selectLabel='Start Time Frame'/> */}
                  <BasicTextField name="sub3" label="sub3" value={sub3} changeHandler={handleSub} />
                </Grid>

              </Grid>
            </Grid>

            {/* 6th row */}

            <Grid item xs={12} sx={{display:'flex', justifyContent:'end'}}>
              <Button variant="contained" sx={{ backgroundColor: "#0ae", '&.MuiButton-root:hover': { bgcolor: '#0ae' } }} onClick={handleSubmit}   disabled={listingLoading}>
                {listingLoading? "Loading": "Submit"}
              </Button>
              <Button variant="contained" style={{ marginLeft: '10px', backgroundColor: "#F5F5F5", color: "black" }} onClick={handleReset}>
                Reset
              </Button>
            </Grid>
          </Grid>
        }

        {
          listingUrl &&

          <Box
            p={3}
            className="listing-request-container card"
          >
            <Box className='api-status '>
              <Typography className="card-heading" sx={{ wordWrap: 'break-word', marginBottom:"4px" }} component='div' variant='h5'>
                {listingUrl}
              </Typography>
              <Typography className="card-sub-heading">
                Total Results: {listingData.total}
              </Typography>
            </Box>

            <Grid container className='list-card' spacing={2} sx={{ margin: '3px', width: '99%', padding: "5px" }} >
            {
              listingData.result.map((eachListItem:any,index:number)=>{
                return (
                  <Grid item xs={12}  lg={4} sm={6} md={3} key={index} sx={{display:'flex'}}>
                    <AreaCard imageUrl={eachListItem.imageURL} headerContent={eachListItem.headContent} description={eachListItem.bodyContent}/>
                  </Grid>
                )
              })
            }
           

            </Grid>
          </Box>
        }
      </Paper>

    </>
  )
}

export default TestAPI














// .replace(/<\/?ul>/g, '').replace(/<\/?li>/g, '')