import MMTabContent from "../View/Advertiser/Stepper Form/Step components/ProgramTargetingTabContent";
import mProgramJson from './Mprogram.json'
export interface IProgramUIData {
  label: string;
  content: React.FC<any>;
  value: string;
}
export const programTargetingData: IProgramUIData[] = [
  {
    label: 'Business & Finance',
    content: MMTabContent,
    value: "1"
  },
  {
    label: 'Criminal Justice & Legal Services',
    content: MMTabContent,
    value: "2"
  },
  {
    "label": "Psychology & Counseling",
    content: MMTabContent,
    value: "3"
  },
  {
    label: 'Computers & Technology',
    content: MMTabContent,
    value: "4"
  },

  {
    "label": "Healthcare & Medical Assisting",
    content: MMTabContent,
    value: "5"
  },
  {
    "label": "Education & Teaching",
    content: MMTabContent,
    value: "6"
  },
  {
    "label": "Trade, Vocational, Career",
    content: MMTabContent,
    value: "7"
  },
  {
    "label": "Liberal Arts",
    content: MMTabContent,
    value: "8"
  },
  {
    "label": "General",
    content: MMTabContent,
    value: "9"
  },
  {
    "label": "Nursing",
    content: MMTabContent,
    value: "10"
  },
  {
    "label": "Media & Communications",
    content: MMTabContent,
    value: "11"
  },
  {
    "label": "Visual Arts & Graphic Design",
    content: MMTabContent,
    value: "12"
  },
  {
    "label": "Religious Studies",
    content: MMTabContent,
    value: "13"
  }
]

export const reportTypeData: IProgramUIData[] = [
  {
    label: 'Customer Attribute Performance Report',
    content: MMTabContent,
    value: "1"
  },
]

export let mPrograms: any = {
  "1": [ { "text": "General Business", "value": 100 }, { "text": "Accounting", "value": 101 }, { "text": "Business Administration", "value": 102 }, { "text": "Business Analytics", "value": 103 }, { "text": "Communications", "value": 104 }, { "text": "Economics", "value": 105 }, { "text": "Finance", "value": 106 }, { "text": "Human Resources", "value": 107 }, { "text": "Management", "value": 108 }, { "text": "Marketing & Advertising", "value": 109 }, { "text": "Project Management", "value": 110 }, { "text": "Sports Management", "value": 111 }, { "text": "Healthcare Administration", "value": 112 },{ "text": "Entrepreneurship", "value": 113 }], 
  
    "2": [{ "text": "General Criminal Justice & Legal Services", "value": "200" }, { "text": "Corrections", "value": "201" }, { "text": "Crime Scence Investigation", "value": "202" }, { "text": "Criminal Justice", "value": "203" }, { "text": "Criminology", "value": "204" }, { "text": "Cyber Security", "value": "205" }, { "text": "Forensic Science", "value": "206" }, { "text": "Homeland Security", "value": "207" }, { "text": "Law Enforcement", "value": "208" }, { "text": "Legal & Paralegal Studies", "value": "209" }, { "text": "Public Safety Administration", "value": "211" }, { "text": "Security", "value": "212" },{ "text": "Fire Science", "value": "213" }],
  
  
    "3": [{ "text": "General Psychology & Counseling", "value": 300 }, { "text": "Addictions & Recovery", "value": 301 }, { "text": "Applied Psychology", "value": 302 }, { "text": "Behavioral Psychology", "value": 303 }, { "text": "Child and Adolescent Psychology", "value": 304 }, { "text": "Counseling", "value": 305 }, { "text": "Forensic Psychology", "value": 307 }, { "text": "Human Services", "value": 308 }, { "text": "Organizational Psychology", "value": 310 }, { "text": "Sociology", "value": 311 }],
  
    "4": [{ "text": "General Computers & IT", "value": "400" }, { "text": "Bootcamp Programs", "value": "401" }, { "text": "Computer Programming", "value": "402" }, { "text": "Data Science & Analytics", "value": "403" }, { "text": "Game Art Development", "value": "404" }, { "text": "Information Systems", "value": "405" }, { "text": "Information Technology", "value": "406" }, { "text": "IT Project Managment", "value": "407" }, { "text": "Network Security", "value": "408" }, { "text": "Product and Graphic Design", "value": "409" }, { "text": "Software Engineering", "value": "410" }, { "text": "Web Development", "value": "411" }, { "text": "CyberSecurity", "value": "412" }],
  
    "5": [{ "text": "General Healthcare & Medical Assisting", "value": "500" }, { "text": "Certified Nursing Assistant (CNA)", "value": "501" }, { "text": "Allied Health", "value": "502" }, { "text": "Emergency Management (EMT)", "value": "503" }, { "text": "Health Science", "value": "505" }, { "text": "Healthcare Administration", "value": "506" }, { "text": "Healthcare Management", "value": "507" }, { "text": "Dental, Medical, or Pharmacy Technician", "value": "508" }, { "text": "Medical Billing & Coding", "value": "509" }, { "text": "Medical Office Assistant", "value": "510" }, { "text": "Nutition, Fitness, and Sports Science", "value": "511" }, { "text": "Public Health", "value": "512" },{ "text": "Veterinary Technician", "value": "513" },{ "text": "X-Ray/Radiologic Technician", "value": "514" }],


  "6": [{ "text": "General Education & Teaching", "value": "600" }, { "text": "Adult Education/Learning", "value": "601" }, { "text": "Early Childhood Development", "value": "602" }, { "text": "Educational Administration", "value": "603" }, { "text": "K-12 Education", "value": "604" }, { "text": "Social Studies", "value": "605" }, { "text": "Special Education", "value": "606" }, { "text": "Teacher Liscensure", "value": "607" }],
  
  
  "7": [{ "text": "General Trade, Vocational, Career", "value": "700" }, { "text": "Automotive focused", "value": "701" }, { "text": "Aviation focused", "value": "702" }, { "text": "Certified Nursing Assistant (CNA)", "value": "703" }, { "text": "Construction management", "value": "704" }, { "text": "Electrical Technican", "value": "705" }, { "text": "Fire Science", "value": "706" }, { "text": "Truck Driver (CDL)", "value": "707" }, { "text": "HVAC", "value": "708" }, { "text": "Welding", "value": "709" }],
  
  
  "8": [{ "text": "General Liberal Arts", "value": "800" }, { "text": "Creative Writing & English", "value": "801" }, { "text": "Communications", "value": "802" }, { "text": "History", "value": "803" }, { "text": "Religious Studies", "value": "804" }, { "text": "Socialogy", "value": "805" }, { "text": "Humanities", "value": "806" }, { "text": "Political Science", "value": "807" }],
  
  "9": [{ "text": "General", "value": "900" }],
  
  "10": [{ "text": "General Nursing", "value": "1000" }, { "text": "Entry Level", "value": "1001" }, { "text": "RN Degree Programs", "value": "1002" }, { "text": "RN to BSN Bridge Programs", "value": "1003" }, { "text": "MSN Programs", "value": "1004" }],


  "11": [
    {
      "text": "General Multi-Media & Communications",
      "value": "1100"
    },
    {
      "text": "Advertising",
      "value": "1101"
    },
    {
      "text": "Animation & Video Graphic",
      "value": "1102"
    },
    {
      "text": "Commercial & Advertising Art",
      "value": "1103"
    },
    {
      "text": "Computer Media Applications",
      "value": "1104"
    },
    {
      "text": "Design & Visual Communication",
      "value": "1105"
    },
    {
      "text": "Film/Video & Cinematography",
      "value": "1106"
    },
    {
      "text": "Graphic Design",
      "value": "1107"
    },
    {
      "text": "Intermedia/Multimedia",
      "value": "1108"
    },
    {
      "text": "Photography",
      "value": "1109"
    },
    {
      "text": "Recording Arts Technology",
      "value": "1110"
    },
    {
      "text": "Web Design",
      "value": "1111"
    }
  ],
  "12": [
    {
      "text": "General Visual Arts & Graphic Design",
      "value": "1200"
    },
    {
      "text": "Design & Visual Communications",
      "value": "1201"
    },
    {
      "text": "Graphic Design",
      "value": "1202"
    },
    {
      "text": "Industrial Design",
      "value": "1203"
    },
    {
      "text": "Interior Design",
      "value": "1204"
    },
    {
      "text": "Photography",
      "value": "1205"
    },
    {
      "text": "Visual Arts",
      "value": "1206"
    }
  ],
  "13": [
    {
      "text": "General Religious Studies",
      "value": "1300"
    },
    {
      "text": "Christian Studies",
      "value": "1301"
    },
    {
      "text": "Ministry",
      "value": "1302"
    },
    {
      "text": "Religious Studies",
      "value": "1303"
    },
    {
      "text": "Theology",
      "value": "1304"
    }
  ]

}



export const generateAreaStudyName=(areaStudyId:string)=>{
const reqData=programTargetingData.find((item)=>{
 return item.value===areaStudyId
})

return reqData?.label;

}

// export const generateMProgramList = (value: string = "1") => {
//   //   const selectedValue=programTargetingData.filter((item)=>item.label===name);
//   // console.log("Selected values", selectedValue)


// console.log("m program data",mProgramJson[value])
//   if (mPrograms[value]) {
//     let data = mPrograms[value]
//     return data;
//   }
// }
export interface JsonData {
  [key: string]: Array<{
    text?: string;
    value?: string;
    id?: string;
    status?: boolean;
    trackingUrl?: string;
    areaStudy: string;
    bid?: string;
  }>;
}


interface Program {
  text?: string;
  value?: string;
  id?: string;
  status?: boolean;
  trackingUrl?: string;
  areaStudy: string;
  bid?: string;
}

export interface ProgramData {
  [key: string]: Program;
}

export function generateMProgramList(key: string = "1") {
  const jsonData: ProgramData = {
    ...mProgramJson
  }
  if (jsonData.hasOwnProperty(key)) {
    return jsonData[key];
  }

}