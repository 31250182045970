import { ThunkDispatch } from 'redux-thunk'; // Import the ThunkDispatch type
import { AnyAction } from '@reduxjs/toolkit';
import {setLoading, setCurrentCursor, setHasMoreData, appendData ,setFullData,setApiFullData, publisherCampApi, setFilterSearchPubData, setfilterSearchFlag} from '../Store/Slices/PublisherTabApisData';
import{setCampLoading,setCurrentCampCursor,setHasCampMoreData,appendCampData,setFullCampData,setApiFullCampData } from '../Store/Slices/PublisherTabApisData';
import axios from 'axios';
import { baseURLConfig } from '../config/envConfig';
import { setBlockScroll, setIsoLoading, setNoDataFlag } from '../Store/Slices/advertiserTabApisData';
import { useAppSelector } from '../Store/hooks';
import { fetchToken, generateHeader } from '../service/Auth';

type DispatchType = ThunkDispatch<{}, {}, AnyAction>;

const baseURL = baseURLConfig.baseURl;

const publisherAccApiUrl = `${baseURL}/publishers?limit=25&ID=publisher`;
// const publisherCampApiUrl50 = `${baseURL}/publishers/campaigns?ID=campaign&limit=45`
const publisherCampApiUrl = `${baseURL}/publishers/campaigns?ID=campaign&limit=25`;
const API_BASE_URL = `${baseURL}/publishers`;

export const fetchPublisherApiData = async (
  dispatch: DispatchType,
  currentCursor: string | null,
) => {
  // console.log("PUBLISHER ACC API URL", publisherAccApiUrl);
  // console.log("PUBLISHER camp  URL ::::", publisherCampApiUrl)
  try {
    dispatch(setHasMoreData(true));
    dispatch(appendData([]));
    const headers=await generateHeader();
    dispatch(setLoading(true));
    let url = publisherAccApiUrl;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }
    const response = await fetch(url,{headers});
    const jsonData = await response.json();
    const filteredData = jsonData.result.data.map((item:any) => ({
      Status: item?.status,
      Publisher_ID: item?.mPubID,
      Publisher_Name: item?.publisherName,
      Created_on: item.createdOn,
      Website: item?.website,
      
    }));
    // dispatch(appendFullData(jsonData.result.data))
    dispatch(appendData(filteredData));
    dispatch(setCurrentCursor(jsonData.result.meta.cursor));
    dispatch(setHasMoreData(jsonData.result.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createPublisher = async (payload:any) => {
  try {
    const headers=await generateHeader();
    const response = await axios.post(API_BASE_URL, payload,{headers});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePublisher = async ( payload:any) => {
  try {
    const headers=await generateHeader();
    const response = await axios.put(API_BASE_URL, payload, {headers});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePublisher = async (payload:any) => {
  try {
 const headers=  await generateHeader()
     let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url:API_BASE_URL,
      headers:headers,
      data:payload.data
    };
    console.log("header config", config)
    // const response = await axios.delete(API_BASE_URL, payload);
    const response=await axios.request(config)
    return response.data;
  } catch (error) {
    throw error;
  }
};
//for serch functionality fetching complete data withour limit and cursor.
export const fetchCompApiData =  async ( dispatch: DispatchType) => {
  try {
    const headers=await generateHeader();
   const response = await fetch(`${baseURL}/publishers?ID=publisher`,{headers});
  const jsonData = await response.json();
  const compApiData = jsonData.result.data.map((item:any) => ({
    Status: item?.status,
    Publisher_ID: item?.mPubID,
    Publisher_Name: item?.publisherName,
    Created_on: item?.createdOn,
    Website: item?.website,
    // Status: item?.status,
  }));
  dispatch(setApiFullData(compApiData));
  dispatch(setFullData(jsonData.result.data));
  } catch (error) {
    throw new Error('Failed to fetch Api data');
  }
};

//////publisher camp

export const fetchPublisherCampApiData = async (
  dispatch: DispatchType,
  currentCursor: string | null
) => {
  try {
    dispatch(appendCampData([]));
    const headers=await generateHeader();
    dispatch(setCampLoading(true));
    let url = publisherCampApiUrl;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }
    const response = await fetch(url,{headers});
    const jsonData = await response.json();
    // console.log('jsonData',jsonData)
    const filteredData = jsonData.result.data.map((item:any) => ({
      Status: item.status,
      campaign_id: item?.campaignID,
      campaign_name: item?.campaignname,
      Publisher_ID: item?.publisherID,
      Publisher_Name: item?.publisherName,
      vertical: 'Higher Education'
      
    }));
    dispatch(appendCampData(filteredData));
    dispatch(setFullCampData(jsonData.result.data));
    dispatch(setCurrentCampCursor(jsonData.result.meta.cursor));
    dispatch(setHasCampMoreData(jsonData.result.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setCampLoading(false));
  }
};


export const createPublisherCamp = async (payload:any) => {

  try {
    const headers=await generateHeader();
    const response = await axios.post(`${API_BASE_URL}/campaigns`, payload, {headers});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePublisherCamp = async ( payload:any) => {
  try {
    const headers=await generateHeader();
    const response = await axios.put(`${API_BASE_URL}/campaigns`, payload, {headers});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePublisherCamp = async (payload:any) => {
  try {
    const headers=  await generateHeader()
    let config = {
     method: 'delete',
     maxBodyLength: Infinity,
     url:`${API_BASE_URL}/campaigns?SORTID=${payload}`,
     headers:headers,
    //  data:payload.data
   };
   console.log("header config", config)
   // const response = await axios.delete(API_BASE_URL, payload);
   const response=await axios.request(config)
   return response.data;

    // const response = await axios.delete(`${API_BASE_URL}/campaigns?SORTID=${payload}`);
    // return response.data;
  } catch (error) {
    throw error;
  }
};


//for serch functionality fetching complete data withour limit and cursor.
// export const fetchComCampApiData =  async ( dispatch: DispatchType) => {
//   try {
//     const headers=await generateHeader();
//    const response = await fetch(`${baseURL}/publishers/campaigns?ID=campaign`,{headers});
//   const jsonData = await response.json();
//   const compApiData = jsonData.result.data.map((item:any) => ({
//     Status: item.status,
//     campaign_id: item?.campaignID,
//     campaign_name: item?.campaignname,
//     Publisher_ID: item?.publisherID,
//     Publisher_Name: item?.publisherName,
    
//   }));
//   dispatch(setApiFullCampData(compApiData));
//   dispatch(setFullCampData(jsonData.result.data));
//   } catch (error) {
//     throw new Error('Failed to fetch Api data');
//   }
// };


export const searchPublisherAccount=async(inputValue:string, dispatch: DispatchType,ID:string)=>{
  dispatch(setIsoLoading(true));
  const encodedValue: string = encodeURIComponent(inputValue);
  const headers=await generateHeader();
const response=await axios.get(`${baseURL}/search?ID=${ID}&key=${encodedValue}`, {headers});
// console.log("responsepubacc", response,)

const filteredData = response.data.data.map((item:any) => ({
  Status: item?.status,
  Publisher_ID: item?.mPubID,
  Publisher_Name: item?.GroupName,
  Created_on: item.createdOn,
  Website: item?.values?.website,
  
}));
if(encodedValue && filteredData.length==0){
  dispatch(setNoDataFlag(true));
}else{
  dispatch(setBlockScroll(true));
  dispatch(setNoDataFlag(false));
}
dispatch(setIsoLoading(false));
// dispatch(appendFullData(jsonData.result.data))
dispatch(appendData(filteredData));
}


export const searchPublisherCampaigns=async(inputValue:string, dispatch: DispatchType,ID:string)=>{
  dispatch(setIsoLoading(true));
  const encodedValue: string = encodeURIComponent(inputValue);
  const headers=await generateHeader();
const response=await axios.get(`${baseURL}/search?ID=${ID}&key=${encodedValue}&campaignType=publisherCampaign`, {headers});
//  console.log("response======pub camp", response)

// const filteredData = response.data.data.map((item:any) => ({
//   campaign_id: item?.SORTID,
//   campaign_name: item?.campaignname,
//   Publisher_ID: item?.publisherID,
//   Publisher_Name: item?.publisherName,
//   Status: item.status,
// }));

const filteredData = response.data.data.map((item: any) => {
  const publisherName = item?.publisherName;
  const formattedPublisherName = publisherName ? publisherName.replace(/^PUB-|-ADM-\d+$/g, '') : '';

  return {
    Status: item.status,
    campaign_id: item?.SORTID,
    campaign_name: item?.campaignname,
    Publisher_ID: item?.publisherID,
    Publisher_Name: item?.values?.companyName ? item?.values?.companyName :item?.publisher?.GroupName,
    vertical: 'Higher Education'
  };
});

if(encodedValue && filteredData.length==0){
  dispatch(setNoDataFlag(true));
}else{
  dispatch(setNoDataFlag(false));
  dispatch(setBlockScroll(true));
}
dispatch(setIsoLoading(false));
dispatch(appendCampData(filteredData));
dispatch(setFullCampData(response.data.data));
}

export const filterPublisherSearch = async(palyoad:Object, dispatch: DispatchType)=>{
  dispatch(setfilterSearchFlag(true));
  dispatch(setIsoLoading(true));
  // const encodedValue: string = encodeURIComponent(inputValue);
  const headers=await generateHeader();
const response=await axios.post(`${baseURL}/filter?campaignType=publisherCampaign`,palyoad,{headers});
//  console.log("response======filter pub camp", response)
const filteredData = response.data.map((item: any) => {
  const publisherName = item?.publisherName;
  const formattedPublisherName = publisherName ? publisherName.replace(/^PUB-|-ADM-\d+$/g, '') : '';

  return {
    Status: item.status,
    campaign_id: item?.SORTID,
    campaign_name: item?.campaignname,
    Publisher_ID: item?.publisherID,
    Publisher_Name: item?.values?.companyName ? item?.values?.companyName :item?.publisher?.GroupName,
    vertical: 'Higher Education'
    
  };
});
 console.log('filteredData',filteredData)
if(palyoad && filteredData.length==0){
  dispatch(setNoDataFlag(true));
}else{
  dispatch(setNoDataFlag(false));
  dispatch(setBlockScroll(true));
}
dispatch(setFilterSearchPubData(filteredData))
dispatch(appendCampData(filteredData));
dispatch(setFullCampData(response.data));
dispatch(setIsoLoading(false));
}

