import React, { useEffect, useMemo, useState } from 'react'
import { RootState } from '../../Store/index';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Snackbar, Switch, TableCell, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import './AdvertiserList.css'
import { Button, Paper } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import MMTable, { IOSSwitch } from '../../Component/Table/MMTable';
import { advAccountHeadCells, advRows } from '../../Component/Table/tableInterface';
import Search from '../../Component/Search/Search';
import { useAppDispatch, useAppSelector } from "../../Store/hooks"
import { setFlag, fetchBrandNames, setFullData, updateAdvertiserAsync, setIsoLoading, setSearchInput, setNoDataFlag, setScrollNext, setBlockScroll, setSearchFilterData, fetchBrandNamesNew,  fetchAllVendorData } from '../../Store/Slices/advertiserTabApisData';
import { setAdvertiserField, resetForm, toggleStatus, togglePayPerLead, toggleApplicationFlag, toggleEnrollmentFlag, toggleLeadFlag, toggleStartFlag, setEditFlag } from '../../Store/Slices/CreateAdvButtonDialogSlice';
import { fetchAdvertAccApiData, fetchCompApiData, searchAdvAccount } from '../../Services/advertiserTabApis';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIconPop } from './Action';
import { createAdvertiserAsync } from '../../Store/Slices/advertiserTabApisData';
import CloseIcon from '@mui/icons-material/Close';
import { getDisplayName } from '../User Accounts/Vendors/Vendors';
import { useLocation } from 'react-router-dom';

const Account = () => {
  return (
    <AccountMainSection />
  )
}
export default Account


const AccountMainSection = () => {

  const [openPopup, setOpenPopup] = React.useState(false);
  // const [searchInput, setSearchInput] = useState<string>('')
  const location = useLocation();
  const dispatch = useAppDispatch();
  const advertiserTableData = useAppSelector((state) => state.advertiserAccApi.data);
  const compApiData = useAppSelector((state) => state.advertiserAccApi.compApiData);
  const isLoading = useAppSelector((state) => state.advertiserAccApi.loading);
  const currentCursor = useAppSelector((state) => state.advertiserAccApi.currentCursor);
  const hasMoreData = useAppSelector((state) => state.advertiserAccApi.hasMoreData);
  const websiteOptions = useAppSelector((state: RootState) => state.advertiserAccApi);
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  const advertiserState = useAppSelector((state: RootState) => state.createAdvButton);


  const handleFieldChange = (field: keyof typeof advertiserState, value: string | boolean) => {
    dispatch(setAdvertiserField({ field, value }));
  };
  // console.log('websiteOptions====',websiteOptions)
  useEffect(() => {
    dispatch(setFlag(false));
     if (!searchInput) {
      dispatch(setBlockScroll(false));
      // If searchInput is empty, fetch the initial data
      dispatch(setScrollNext(false));
      setDebouncedInputValue('');
      fetchInitialData();
      fetchCompApiData(dispatch);
      dispatch(setNoDataFlag(false));
     }else{
      const debounceTimer = setTimeout(() => {
        setDebouncedInputValue(searchInput);
      }, 600);
  
      // Clear the timer on every key press to restart the countdown
      return () => clearTimeout(debounceTimer);
    }
  }, [dispatch,searchInput]);


  useEffect(() => {
    dispatch(setSearchFilterData([]));
    // dispatch(setSearchInput(''));
  
    fetchCompApiData(dispatch);
  }, [])

  useEffect(() => {
    // dispatch(fetchBrandNames());
    // fetchVendor()
    dispatch(fetchAllVendorData())
    dispatch(fetchBrandNamesNew());
    
  }, [dispatch]);


  const fetchInitialData = () => {
    fetchAdvertAccApiData(dispatch, null,location).then(() => {
     dispatch(setScrollNext(true));
    });
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setFlag(true));
      fetchAdvertAccApiData(dispatch, currentCursor,location);
    }
  };

  
const handelSearch=(event: React.ChangeEvent<HTMLInputElement>)=>{
  // console.log("event", event.target.value)
  // setSearchInput(event.target.value)
  dispatch(setSearchInput(event.target.value));
}



useEffect(()=>{
  if (debouncedInputValue !== '' && debouncedInputValue !== null) {
  searchAdvAccount(debouncedInputValue, dispatch, 'advertiser',location)
  }
},[debouncedInputValue])

useEffect(() => {
  if(searchInput){
  dispatch(setSearchInput(''));
  }
 }, []);
  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' }}}>
          <Box>
            <Typography className="card-heading">Advertiser List</Typography>
            <Typography className="card-sub-heading">
              Advertiser Summary
            </Typography>
          </Box>
          <Box  display="flex" gap="16px" sx={{flexDirection: { xs: 'column', sm: 'row' }, mt:{xs:'16px', md:'0'}, alignItems:{xs:'flex-start', md:'center'} }}>
            <Search value={searchInput} searchHandler={handelSearch} />
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{backgroundColor:"#0ae"}}
              onClick={() => { setOpenPopup(true); dispatch(setEditFlag(false)) }} >
              Create Advertiser
            </Button>
          </Box>
        </Box>
        <Box display="flex" gap="8px">
        <FormControl style={{ width: '98%', marginBottom: '0.4%',paddingLeft: '1%'}}>
    <InputLabel style={{paddingLeft:'19px'}} >Filter by Vertical</InputLabel>
    <Select
     label="Filter by Vertical"
      value={advertiserState.verticalFilter} // Assuming you have this value in your state
      onChange={(e) =>  handleFieldChange("verticalFilter", e.target.value)} // Update your handler
      style={{ height: '50px' }}
    >
      {/* <MenuItem value="Select">Select Vertical</MenuItem> */}
      <MenuItem value="AutoInsurance">Auto Insurance</MenuItem>
      <MenuItem value="HigherEducation">Higher Education</MenuItem>
    </Select>
  </FormControl>
  </Box>
        <MMTable
          tableData={advertiserTableData}
          tableHeadCell={advAccountHeadCells}
          isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          fetchInitialData={fetchInitialData}
        />
      </Paper>
      <CreateAdver openPopup={openPopup} setOpenPopup={setOpenPopup} websiteOptions={websiteOptions}
        fetchInitialData={fetchInitialData} />
    </React.Fragment>
  )
}
const CreateAdver = (props: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const advertiserState = useAppSelector((state: RootState) => state.createAdvButton);
  const { openPopup, setOpenPopup, websiteOptions, fetchInitialData, rowData } = props;
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [advertiserNameError, setAdvertiserNameError] = useState(false);
  const [brandNameError, setbrandNameError] = useState(false);
  const [vendorNameError, setVendorNameError] = useState(false);
  // const 
  const fullData = useAppSelector((state) => state.advertiserAccApi.fullData);
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  const handleFieldChange = (field: keyof typeof advertiserState, value: string | boolean) => {
    dispatch(setAdvertiserField({ field, value }));
  };



// console.log("vendor name ", advertiserState.vendorName)
  const cancelButton = () => {
    setOpenPopup(false);
    dispatch(resetForm()); 
    setAdvertiserNameError(false);
    setbrandNameError(false);
    setVendorNameError(false);
  };

  const isAdvertiserNameExists = fullData.some(
    (data: any) => data.advertiserName?.trim() === advertiserState.GroupName?.trim()
  );  

  const handleCreateAdvBu = async () => {
    setSuccessMessage('');
    setSuccessMessage('');
    setErrorMessage('');
    if (!advertiserState.GroupName.trim() || !advertiserState.brandName.trim() || !advertiserState.vendorName.trim() ) {
      setAdvertiserNameError(!advertiserState.GroupName.trim());
      setbrandNameError(!advertiserState.brandName.trim());
      setVendorNameError(!advertiserState.vendorName.trim())
     }else if (advertiserState.GroupName.includes(',')) {
        setErrorMessage('Comma is not allowed in Advertiser Name');
        setSnackbarOpen(true);
        return;
      } else {
      if (advertiserState.GroupName.includes(',')) {
        setErrorMessage('Comma is not allowed in Advertiser Name');
        setSnackbarOpen(true);
        return;
      }
      if (advertiserState.GroupName.toLowerCase().includes('vendor')) {
        setErrorMessage('Vendor name  is not allowed in Advertiser Name');
        setSnackbarOpen(true);
        return;
      }
   

      if (isAdvertiserNameExists && advertiserState.editFlag == false) {
        setErrorMessage('Advertiser with this name already exists');
        setSnackbarOpen(true);
        return;
      }
      setOpenPopup(false);
      dispatch(setIsoLoading(true));
      setAdvertiserNameError(false);
      setbrandNameError(false);
      setVendorNameError(false)

      const payload = {
        GroupName: advertiserState.GroupName,
        brand: advertiserState.brandName,
        status: advertiserState.status,
        type: 'Advertiser',
         vendorName:advertiserState.vendorName,
        values: {
          address: advertiserState.advertiser,
          advertiser: advertiserState.advertiser,
          applicationFlag: advertiserState.applicationFlag,
          city: advertiserState.city,
          companyDescription: advertiserState.companyDescription,
          companyName: advertiserState.companyName,
          enrollmentFlag: advertiserState.enrollmentFlag,
          leadFlag: advertiserState.leadFlag,
          phNo: advertiserState.phNo,
          ppl: advertiserState.ppl,
          primaryContact: advertiserState.primaryContact,
          rpl: advertiserState.rpl,
          startsFlag: advertiserState.startsFlag,
          state: advertiserState.state,
          brand: advertiserState.brandName,
        },
      };

      let requestData
      if (rowData) {
        requestData = {
          ID: rowData.ID,
          SORTID: rowData.SORTID,
          createdOn: rowData.createdOn,
          GroupName: advertiserState.GroupName,
          mPubID: rowData.advertiserID,
          status: advertiserState.status,
          updatedOn: rowData.updatedOn,
          type: 'Advertiser',
          brand: advertiserState.brandName,
           vendorName:advertiserState.vendorName,
          values: {
            address: advertiserState.advertiser,
            advertiser: advertiserState.advertiser,
            applicationFlag: advertiserState.applicationFlag,
            city: advertiserState.city,
            companyDescription: advertiserState.companyDescription,
            companyName: advertiserState.companyName,
            enrollmentFlag: advertiserState.enrollmentFlag,
            leadFlag: advertiserState.leadFlag,
            phNo: advertiserState.phNo,
            ppl: advertiserState.ppl,
            primaryContact: advertiserState.primaryContact,
            rpl: advertiserState.rpl,
            startsFlag: advertiserState.startsFlag,
            state: advertiserState.state,
            website: rowData.values.website,
            brand: advertiserState.brandName,
          },
        };
      }

      try {

        if (advertiserState.editFlag) {
          try {
            // For editing an existing record, send a PUT request with the ID
            await dispatch(updateAdvertiserAsync(requestData));
           setSuccessMessage('Advertiser Updated Successfully') 
          } catch (error) {
            console.error('Error updating advertiser:', error);
          }
        } else {
          try {
            // For creating a new record, send a POST request
            const response = await dispatch(createAdvertiserAsync(payload));
            response && setSuccessMessage('Advertiser Created Successfully');
          } catch (error) {
            console.error('Error Creating advertiser:', error);
          }

        }

        dispatch(setFlag(false));
        setSnackbarOpen(true);
        // fetchInitialData();
        fetchCompApiData(dispatch);
        if (searchInput !== '' && searchInput !== null) {
          searchAdvAccount(searchInput, dispatch, 'advertiser',location)
          }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('Failed to create advertiser');
        setSnackbarOpen(false);
      }
      dispatch(resetForm());
      dispatch(setIsoLoading(false));
      // dispatch(setSearchInput(''));
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const brandNameOptions = useMemo(() => {
    return websiteOptions.brandNamesNew.map((brandName: any, index: any) => (
      <MenuItem key={index} value={brandName}>
        {brandName}
      </MenuItem>
    ));
  }, [websiteOptions.brandNamesNew]);

  const vendorNameOptions = useMemo(() => {

    return websiteOptions.vendorList.map((item: any, index: any) => (
      <MenuItem key={index} value={item.GroupName}>
        {getDisplayName(item.GroupName)}
      </MenuItem>
    ));
  }, [websiteOptions.vendorList]);
    // console.log('websiteOptionss::::::::::',websiteOptions)
  //   console.log("advertiser State ", advertiserState);
  // console.log("Vendor options ", websiteOptions.vendorList);
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  
  return (
    <div>
      <Dialog  open={openPopup}  className='backdrop' fullWidth  maxWidth="md">
        <DialogTitle>{advertiserState.editFlag ? "Update" : 'Create'} Advertiser</DialogTitle>
        <div className="close-button" onClick={cancelButton}><CloseIcon style={{ fontSize: '32px' }} /></div>
        <DialogContent style={{ paddingTop: "5px" }}>
          <Grid container spacing={2}>
          <Grid item xs={12} md={isSmallScreen ? 12 : 7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <FormControl fullWidth >
            <InputLabel>
                Select Vertical
                </InputLabel>
                <Select
                label='Select Vertical'
                  // className={
                  //   advertiserState.vertical == "" ? "custom-selectver" : ""
                  // }
                  fullWidth
                  value={advertiserState.vertical} // Assuming this is managed in your state
                  onChange={(e: { target: { value: any } }) => {
                    handleFieldChange("vertical", e.target.value); // Replace with the appropriate handler
                  }}>
                  <MenuItem value="Auto Insurance">Auto Insurance</MenuItem>
                  <MenuItem value="Higher Education">Higher Education</MenuItem>
                </Select>
              </FormControl>
               </Grid>
          <Grid item xs={12}>
              <TextField 
              sx={{ marginBottom:'8px'}}
              disabled={advertiserState.editFlag}
              label="Advertiser Name*" fullWidth
                value={advertiserState.GroupName}
                onChange={(e) => {
                  setAdvertiserNameError(false);
                  handleFieldChange('GroupName', e.target.value)
                }}
                error={advertiserNameError}
                helperText={advertiserNameError ? 'Advertiser Name is mandatory' : ''}
                FormHelperTextProps={{
                  sx: {
                   marginTop:'-3px',
                    marginLeft: '10px !important' // Adjust this value to shift the helper text right
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth >
            <InputLabel>
                Select Vendor
                </InputLabel>
              <Select
              label='Select Vendor'
              // sx={{marginTop:'10px'}}
                fullWidth
                value={advertiserState.vendorName}
                onChange={(e) => {
                 setVendorNameError(false);
                  handleFieldChange('vendorName', e.target.value)
                }}
                // className={(advertiserState.vendorName === '') ? "vendor-select" : ''}
                error={vendorNameError}
              >
                {vendorNameOptions}
              </Select>
              </FormControl>
              {vendorNameError && <div style={{ color: '#d32f2f', fontSize: '13px', paddingLeft: '12px' }}>Please select Vendor</div>}
            </Grid> 
            <Grid item xs={12}>
            <FormControl fullWidth > 
            <InputLabel>
                Select Brand
                </InputLabel>
              <Select
              label='Select Brand'
                fullWidth
                value={advertiserState.brandName}
                disabled={advertiserState.editFlag && Boolean(advertiserState.brandName)}
                onChange={(e) => {
                  setbrandNameError(false);
                  handleFieldChange('brandName', e.target.value)
                }}
                // className={(advertiserState.brandName === '') ? "custom-select" : ''}
                error={brandNameError}
              >
                {brandNameOptions}
              </Select>
              </FormControl>
              {brandNameError && <div style={{ color: '#d32f2f', fontSize: '13px', paddingLeft: '12px' }}>Please select Brand</div>}
            </Grid>
            <Grid item xs={12}>
              <TextField label="Advertiser Address" fullWidth variant="outlined" rows={3} multiline
                value={advertiserState.advertiser}
                onChange={(e) => handleFieldChange('advertiser', e.target.value)} />
            </Grid>
            {/* <Grid item xs={6}>
              <TextField label="Advertiser Description" fullWidth variant="outlined" rows={4} multiline
                value={advertiserState.companyDescription}
                onChange={(e) => handleFieldChange('companyDescription', e.target.value)} />
            </Grid>
            */}
            
{/*              
            <Grid item xs={6}>
              <TextField label="Contact Person Name" fullWidth
                value={advertiserState.primaryContact}
                onChange={(e) => handleFieldChange('primaryContact', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="City" fullWidth
                value={advertiserState.city}
                onChange={(e) => handleFieldChange('city', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Phone Number" fullWidth
                value={advertiserState.phNo}
                onChange={(e) => handleFieldChange('phNo', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="State" fullWidth
                value={advertiserState.state}
                onChange={(e) => handleFieldChange('state', e.target.value)} />
            </Grid> */}
            <Grid item xs={12}>
              <TextField label="RPL" fullWidth
                value={advertiserState.rpl}
                onChange={(e) => handleFieldChange('rpl', e.target.value)}
                disabled={!advertiserState.ppl}
                InputProps={{
                  style: {
                    backgroundColor: !advertiserState.ppl ? '#f2f2f2' : 'inherit', // Set the background color when disabled
                  },
                }}
                 />
            </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12} md={isSmallScreen ? 12 : 5}>
              <Paper elevation={3} style={{ padding: "16px" }}>
                <Typography sx={{textAlign:'center'}} variant="h6" gutterBottom>
                  Flags and Status
                </Typography>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
              <FormControlLabel
                control={<Switch checked={advertiserState.status === 'Active'} onChange={() => dispatch(toggleStatus())} />}
                label="Status"
                labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={advertiserState.ppl} onChange={() => dispatch(togglePayPerLead())} />}
                label="Pay Per Lead"
                labelPlacement="end"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={advertiserState.leadFlag} onChange={() => dispatch(toggleLeadFlag())} />}
                label="Lead Flag"
                labelPlacement="end"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={advertiserState.applicationFlag} onChange={() => dispatch(toggleApplicationFlag())} />}
                label="Application Flag"
                labelPlacement="end"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={advertiserState.enrollmentFlag} onChange={() => dispatch(toggleEnrollmentFlag())} />}
                label="Enrollment Flag"
                labelPlacement="end"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={advertiserState.startsFlag} onChange={() => dispatch(toggleStartFlag())} />}
                label="Start Flag"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
          </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Button onClick={cancelButton} style={{ color: 'red' }}>CANCEL</Button>
          <Button variant="contained" color="primary" onClick={handleCreateAdvBu}>{advertiserState.editFlag ? "UPDATE" : 'CREATE'}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}


export const AdvActionIcons = React.memo((props: any) => {
  const location = useLocation();
  const { AdvID, fetchInitialData } = props;
  const [openPopup, setOpenPopup] = React.useState(false);
  const dispatch = useAppDispatch();
  const fullData = useAppSelector((state) => state.advertiserAccApi.fullData);
  const brandNameList = useAppSelector((state: RootState) => state.advertiserAccApi);
  const brandNameListNew =  useAppSelector((state: RootState) => state.advertiserAccApi.brandNamesNew);
  const [openDeletePopup, setDeletePopup] = React.useState(false);
  const advertiserState = useAppSelector((state: RootState) => state.createAdvButton);
  const getRowDataById = (advId: string) => {
    return fullData.find((row: any) => row.advertiserID === advId);
  };
  const rowData = getRowDataById(AdvID);
  function setDefaultVendor(companyData:any, vendors:any) {
    let vendorName=companyData.vendorName
    if (!vendorName) {
        let defaultVendor = vendors.find((vendor:any) => vendor.GroupName === "Vendor-Media-Matchers");
        if (defaultVendor) {
            vendorName = defaultVendor.GroupName; // Set the vendorName to the default one
        }
    }
    return vendorName;
}
  const editFetch = () => {
    if (advertiserState.editFlag && rowData) {
      const fieldsToUpdate = [
        'companyDescription',
        'advertiser',
        'primaryContact',
        'city',
        'phNo',
        'state',
        'vendorName'
        // 'rpl',
        // 'ppl',
        // 'leadFlag',
        // 'applicationFlag',
        // 'enrollmentFlag',
        // 'startsFlag'
      ];

 

      const defaultVendorName=setDefaultVendor(rowData,brandNameList.vendorList );
      fieldsToUpdate.forEach((field: any) => {
        dispatch(setAdvertiserField({ field, value: rowData.values?.[field] || '' }));
      });

      const booleanFields = [
        'ppl',
        'leadFlag',
        'applicationFlag',
        'enrollmentFlag',
        'startsFlag'
      ]
      booleanFields.forEach((field: any) => {
        dispatch(setAdvertiserField({ field, value: rowData?.values?.[field] || false }));
      });

      dispatch(setAdvertiserField({ field: 'rpl', value: rowData?.values?.rpl || '' }));
      dispatch(setAdvertiserField({ field: 'GroupName', value: rowData.advertiserName || '' }));
      dispatch(setAdvertiserField({ field: 'brandName', value: rowData.brand || '' }));
      dispatch(setAdvertiserField({ field: 'status', value: rowData.status || 'Inactive' }));
      dispatch(setAdvertiserField({ field: 'vendorName', value: defaultVendorName}));
    }
  };

  const handleEditButtonClick = () => {
    editFetch();
    setOpenPopup(true);
  };

  const fetchData = () => {
    fetchAdvertAccApiData(dispatch, null,location);
    fetchCompApiData(dispatch);
  };


  let delPayload: { ID: any; mPubID: any; }
  delPayload = {
    ID: rowData?.ID,
    mPubID: rowData?.advertiserID,
  }


  return (
    <div>
      <Tooltip title="Edit">
        <EditIcon onClick={handleEditButtonClick} color="primary" /></Tooltip>
      {/* <DeleteIcon onClick={() => console.log("delete ", AdvID,rowData, fullData)} color="error" /> */}
      <Tooltip title="Delete"><DeleteIcon onClick={() => setDeletePopup(true)} color="error" /></Tooltip>
      <CreateAdver openPopup={openPopup} setOpenPopup={setOpenPopup}
        websiteOptions={brandNameList} rowData={rowData} fetchInitialData={fetchInitialData}
      />
      <DeleteIconPop
        openDeletePopup={openDeletePopup}
        setDeletePopup={setDeletePopup}
        rowData={rowData}
        delPayload={delPayload}
        fetchData={fetchData} />
    </div>
  )
});





