import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Fab,
  useMediaQuery,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, {
  FC,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
// import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuIcon from '@mui/icons-material/Menu';
import isEqual from 'lodash/isEqual';
import ManageDiaolgMapping from './ManageTcpaAudiDialogMapping';
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { createTcpaCampAsync, setSnackbarMessage, setSnackbarOpen, setSnackbarType, setTcpaCreateJson, setTcpaEditJson, storeTcpaEndYearError, storeTcpaStartYearError, tcpaCampSlice, updateTcpaGradYear } from '../../../../Store/Slices/tcpaCampSlice';
import { CAMPAIGN_CREATE_SUCCESS, CAMPAIGN_UPDATE_SUCCESS } from '../../../../Mock/MessageContstant';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


export const TcpaGradYearSection = () => {
  const dispatch = useAppDispatch()
  const advCampaignFormState = useAppSelector((state) => state.tcpaCampSlice);
  const campaignFormData = useAppSelector((state) => state.tcpaCampSlice);
  const handleInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      dispatch(updateTcpaGradYear({ type: name, value: value }))
      
      if( name==='startYear' && value >= '1900' && value <= '2050'){
          dispatch(storeTcpaStartYearError(''))
      } if(name==='endYear' && value <= '2050' && value>='1900'){
          dispatch(storeTcpaEndYearError(''))
      }
     

  }
  //console.log("advCampaignFormState", advCampaignFormState.value)
  return (
      <Paper>
          <Box paddingBottom={5}>
              <FormGroup sx={{ m: 1, minWidth: "100%", p: 1 }}>
                  <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} checked={advCampaignFormState.gradYear.unknown} name='unknown' onChange={(event) => {
                          dispatch(updateTcpaGradYear({ type: 'unknown', value: event.target.checked }))
                      }} />}
                      label="Unknown"
                  />
              </FormGroup>
              <TextField
                  id="outlined-number"
                  label="Start year"
                  name='startYear'
                  type="number"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  error={campaignFormData.startYearError.length > 1}
                  helperText={campaignFormData.startYearError.length > 1 ? campaignFormData.startYearError : ""}
                  value={advCampaignFormState.gradYear.startYear}
                  onChange={handleInputField}
                  sx={{ mr: 2 , ml: 1.8}} 
              />
          
              <TextField
                  id="outlined-number"
                  label="End year"
                  type="number"
                  name='endYear'
                  InputLabelProps={{
                      shrink: true,
                  }}
                  error={campaignFormData.endYearError.length > 1}
                  helperText={campaignFormData.endYearError.length > 1 ? campaignFormData.endYearError : ""}
                  value={advCampaignFormState.gradYear.endYear}
                  onChange={handleInputField}
              />
          </Box>
      </Paper>

  )
}


const tabOptions: any = [
  {
    "title": "campus Type",
    "key": "campusType",
    "values": [
      {
        "campusID": 1,
        "label": "Either",
        "enable": false,
        "postValue": ""
      },
      {
        "campusID": 2,
        "label": "Online",
        "enable": false,
        "postValue": ""
      },
      {
        "campusID": 3,
        "label": "Campus",
        "enable": false,
        "postValue": ""
      },
      {
        "campusID": 4,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      }
    ]
  },
  {
    "title": "highest Level Of Education",
    "key": "highestEducation",
    "values": [
      {
        "highestEduId": 1,
        "label": "high school diploma",
        "enable": false,
        "postValue": ""
      },
      {
        "highestEduId": 2,
        "label": "No high school diploma",
        "enable": false,
        "postValue": ""
      },
      {
        "highestEduId": 3,
        "label": "GED",
        "enable": false,
        "postValue": ""
      },
      {
        "highestEduId": 4,
        "label": "0-12 college credits",
        "enable": false,
        "postValue": ""
      },
      {
        "highestEduId": 5,
        "label": "more than 12 college credits",
        "enable": false,
        "postValue": ""
      },
      {
        "highestEduId": 6,
        "label": "associates degree",
        "enable": false,
        "postValue": ""
      },
      {
        "highestEduId": 7,
        "label": "bachelors degree",
        "enable": false,
        "postValue": ""
      },
      {
        "highestEduId": 8,
        "label": "Graduate Degree",
        "enable": false,
        "postValue": ""
      },
      {
        "highestEduId": 9,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      }
    ]
  },
  {
    "title": "degree of Interest",
    "key": "degreeInterest",
    "values": [
      {
        "degreeInterestId": 1,
        "label": "Certificate",
        "enable": false,
        "postValue": ""
      },
      {
        "degreeInterestId": 2,
        "label": "Associates",
        "enable": false,
        "postValue": ""
      },
      {
        "degreeInterestId": 3,
        "label": "Bachelors",
        "enable": false,
        "postValue": ""
      },
      {
        "degreeInterestId": 4,
        "label": "Graduate Programs(Certifications, Master & Doctorate)",
        "enable": false,
        "postValue": ""
      },
      {
        "degreeInterestId": 5,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      }
    ]
  },
  {
    "title": "degree start Time frame",
    "key": "startTimeframe",
    "values": [
      {
        "startTimeframeId": 1,
        "label": "immediately",
        "enable": false,
        "postValue": ""
      },
      {
        "startTimeframeId": 2,
        "label": "1-3 Months",
        "enable": false,
        "postValue": ""
      },
      {
        "startTimeframeId": 3,
        "label": "4-6 Months",
        "enable": false,
        "postValue": ""
      },
      {
        "startTimeframeId": 4,
        "label": "7-12 Months",
        "enable": false,
        "postValue": ""
      },
      {
        "startTimeframeId": 5,
        "label": "Later than 1 year",
        "enable": false,
        "postValue": ""
      },
      {
        "startTimeframeId": 6,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      }
    ]
  },
  {
    "title": "military Affiliation",
    "key": "militaryAffiliation",
    "values": [
      {
        "militaryId": 1,
        "label": "None",
        "enable": false,
        "postValue": ""
      },
      {
        "militaryId": 2,
        "label": "Active Duty",
        "enable": false,
        "postValue": ""
      },
      {
        "militaryId": 3,
        "label": "Veteran",
        "enable": false,
        "postValue": ""
      },
      {
        "militaryId": 4,
        "label": "Guard",
        "enable": false,
        "postValue": ""
      },
      {
        "militaryId": 5,
        "label": "Spouse",
        "enable": false,
        "postValue": ""
      },
      {
        "militaryId": 6,
        "label": "Dependent",
        "enable": false,
        "postValue": ""
      },
      {
        "militaryId": 7,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      }
    ]
  },
  {
    "title": "media Type",
    "key": "mediaType",
    "values": [
      {
        "mediaId": 1,
        "label": "Social",
        "enable": false,
        "postValue": ""
      },
      {
        "mediaId": 2,
        "label": "Email",
        "enable": false,
        "postValue": ""
      },
      {
        "mediaId": 3,
        "label": "Organic",
        "enable": false,
        "postValue": ""
      },
      {
        "mediaId": 4,
        "label": "SEM",
        "enable": false,
        "postValue": ""
      },
      {
        "mediaId": 5,
        "label": "SMS",
        "enable": false,
        "postValue": ""
      },
      {
        "mediaId": 6,
        "label": "Display",
        "enable": false,
        "postValue": ""
      },
      {
        "mediaId": 7,
        "label": "Affiliate",
        "enable": false,
        "postValue": ""
      },
      {
        "mediaId": 8,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      }
    ]
  },
  {
    "title": "inventory Type",
    "key": "inventoryType",
    "values": [
      {
        "inventoryId": 1,
        "label": "Thank you page",
        "enable": false,
        "postValue": ""
      },
      {
        "inventoryId": 2,
        "label": "No Match",
        "enable": false,
        "postValue": ""
      },
      {
        "inventoryId": 3,
        "label": "Content Page",
        "enable": false,
        "postValue": ""
      },
      {
        "inventoryId": 4,
        "label": "Lightbox",
        "enable": false,
        "postValue": ""
      },
      {
        "inventoryId": 5,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      }
    ]
  },
  {
    "title": "gender",
    "key": "gender",
    "values": [
      {
        "genderId": 1,
        "label": "Male",
        "enable": false,
        "postValue": ""
      },
      {
        "genderId": 2,
        "label": "Female",
        "enable": false,
        "postValue": ""
      },
      {
        "genderId": 3,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      },
    ]
  },
  {
    "title": "best contact time",
    "key": "bestContactTime",
    "values": [
      {
        "contactId": 1,
        "label": "Anytime",
        "enable": false,
        "postValue": ""
      },
      {
        "contactId": 2,
        "label": "Morning",
        "enable": false,
        "postValue": ""
      },
      {
        "contactId": 3,
        "label": "Afternoon",
        "enable": false,
        "postValue": ""
      },
      {
        "contactId": 4,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      },
    ]
  },
  {
    "title": "interest Level",
    "key": "interestLevel",
    "values": [
      {
        "interestId": 1,
        "label": "1",
        "enable": false,
        "postValue": ""
      },
      {
        "interestId": 2,
        "label": "2",
        "enable": false,
        "postValue": ""
      },
      {
        "interestId": 3,
        "label": "3",
        "enable": false,
        "postValue": ""
      },
      {
        "interestId": 4,
        "label": "4",
        "enable": false,
        "postValue": ""
      },
      {
        "interestId": 5,
        "label": "5",
        "enable": false,
        "postValue": ""
      },
      {
        "interestId": 6,
        "label": "6",
        "enable": false,
        "postValue": ""
      },
      {
        "interestId": 7,
        "label": "7",
        "enable": false,
        "postValue": ""
      },
      {
        "interestId": 8,
        "label": "8",
        "enable": false,
        "postValue": ""
      },
      {
        "interestId": 9,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      },
    ]
  },
  {
    "title": "has Internet",
    "key": "hasInternet",
    "values": [
      {
        "hasInternetId": 1,
        "label": "Yes",
        "enable": false,
        "postValue": ""
      },
      {
        "hasInternetId": 2,
        "label": "No",
        "enable": false,
        "postValue": ""
      },
      {
        "hasInternetId": 3,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      },
    ]
  },
  {
    "title": "has RN",
    "key": "hasRN",
    "values": [
      {
        "hasRNId": 1,
        "label": "Yes",
        "enable": false,
        "postValue": ""
      },
      {
        "hasRNId": 2,
        "label": "No",
        "enable": false,
        "postValue": ""
      },
      {
        "hasRNId": 3,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      },
    ]
  },
  {
    "title": "has Teaching",
    "key": "hasTeaching",
    "values": [
      {
        "hasTeachingId": 1,
        "label": "Yes",
        "enable": false,
        "postValue": ""
      },
      {
        "hasTeachingId": 2,
        "label": "No",
        "enable": false,
        "postValue": ""
      },
      {
        "hasTeachingId": 3,
        "label": "Unknown",
        "enable": false,
        "postValue": ""
      },
    ]
  },
  {
    "title": "Grad Year",
    "key": "gradYear",
    "content": "TcpaGradYearSection",
  },
]

// const TabPanel = ({ program, upDatedValue }: any) => {
//   const [programContent, setProgramContent] = useState(program);
//   const [isSelectAll, setSelectAll] = useState(
//     program?.values?.every((item: any) => item.enable === true)
//   );

//   const [mappingDialog, setMappingDialog] = useState(false);
//   const handelSelectAllCheckBox = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     const { checked } = event.target;
//     const updatedSubPrograms = programContent?.values?.map((item: any) => ({
//       ...item,
//       enable: checked,
//     }));
//     setProgramContent((prevData: any) => ({
//       ...prevData,
//       values: updatedSubPrograms,
//     }));
//   };

//   useEffect(() => {
//     setSelectAll(
//       programContent?.values?.every((item: any) => item.enable === true)
//     );
//     !isEqual(programContent, program) && upDatedValue(programContent);
//   }, [programContent]);

//   const handleStatus = (
//     event: React.ChangeEvent<HTMLInputElement>,
//     label: string
//   ) => {
//     const { name, checked } = event.target;

//     const updatedSubPrograms = programContent.values.map((item: any) =>
//       item.label === label ? { ...item, enable: checked } : item
//     );
//     setProgramContent((prevData: any) => ({
//       ...prevData,
//       values: updatedSubPrograms,
//     }));
//   };

//   const handlePostValue = (
//     event: React.ChangeEvent<HTMLInputElement>,
//     label: string
//   ) => {
//     // const { value  } = event.target;

//     const updatedSubPrograms = programContent.values.map((item: any) =>
//       item.label === label ? { ...item, postValue: event.target.value } : item
//     );
//     setProgramContent((prevData: any) => ({
//       ...prevData,
//       values: updatedSubPrograms,
//     }));
//   };

//   const handleAreaStudyStatus = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     const { checked } = event.target;
//     setProgramContent((prevData: any) => ({
//       ...prevData,
//       enable: checked,
//     }));
//   };
//   useEffect(() => {
//     setProgramContent(program);
//   }, [program]);
// if(program.content){
// return <TcpaGradYearSection/>
// }else{
//   return (
//     <div role="tabpanel" style={{ width: '100%' }}>
//       <Paper>
//         <Grid container justifyContent="space-between">
//           <Grid item>
//             <FormGroup sx={{ m: 1, minWidth: '100%', p: 1 }}>
//                 <FormControlLabel
//                   control={
//                     <IOSSwitch
//                       sx={{ m: 1 }}
//                       name="status"
//                       checked={isSelectAll}
//                       onChange={handelSelectAllCheckBox}
//                     />
//                   }
//                   label="Select All"
//                 />
//               </FormGroup>
//           </Grid>
//         </Grid>
//         <Box className="no-more-tables responsive-tbl">
//           <Table size="small">
//             <TableHead>
//               <TableRow>
//               <TableCell>Status</TableCell>
//                 <TableCell >Type</TableCell>
//                 <TableCell sx={{ textAlign: 'center' }}>Advertiser Key</TableCell>
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {programContent?.values?.map((item: any, index: number) => {
//                 return (
//                   <TableRow key={index}>
                   
//                     <TableCell data-title="Status">
//                       {' '}
//                       <FormGroup>
//                         <FormControlLabel
//                           control={
//                             <IOSSwitch
//                               sx={{ m: 1 }}
//                               name={'enable'}
//                               checked={item.enable}
//                               onChange={event => {
//                                 handleStatus(event, item.label);
//                               }}
//                             />
//                           }
//                           label=""
//                         />
//                       </FormGroup>
//                     </TableCell>
//                     <TableCell data-title="Program Name"  >
//                       {item.label}
//                     </TableCell>
//                      <TableCell data-title="Advertiser Key" sx={{ textAlign: 'center' }}>
//                       <TextField
//                         type="text"
//                         variant="outlined" // Ensure the variant is set correctly
//                         value={item.postValue}
//                         placeholder='Post Value'
//                         InputProps={{
//                           style: {
//                             padding: '6px', // Remove internal padding
//                             height: '41px', // Set your desired height
//                           },
//                         }}
//                         sx={{
//                           backgroundColor: '#FFF',
//                           border: '1px solid #0AE',
//                           boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
//                           borderRadius: '8px',
//                           color: '#00AAEE',
//                         }}
//                         onChange={(event:any) => {
//                           handlePostValue(event, item.label);
//                         }}
//                       />
//                     </TableCell>
//                   </TableRow>
//                 );
//               })}
//             </TableBody>
//           </Table>
//         </Box>
//       </Paper>
//     </div>
//   );
// }
  
// };


const TabPanel = ({ program, upDatedValue,submitRef }: any) => {
  const [programContent, setProgramContent] = useState(program);
  const [isSelectAll, setSelectAll] = useState(
    program?.values?.every((item: any) => item.enable === true)
  );
  const [errors, setErrors] = useState<any>({});

  const [mappingDialog, setMappingDialog] = useState(false);

  const handelSelectAllCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;

    if (!checked) {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        programContent.values.forEach((item: any) => {
          if (newErrors[item.label]) {
            delete newErrors[item.label]; // Remove the error for each field
          }
        });
        return newErrors;
      });
    }

    const updatedSubPrograms = programContent?.values?.map((item: any) => ({
      ...item,
      enable: checked,
    }));
    setProgramContent((prevData: any) => ({
      ...prevData,
      values: updatedSubPrograms,
    }));
  };

  useEffect(() => {
    setSelectAll(
      programContent?.values?.every((item: any) => item.enable === true)
    );
    !isEqual(programContent, program) && upDatedValue(programContent);
  }, [programContent]);

  const handleStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string
  ) => {
    const { name, checked } = event.target;

    if (!checked) {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        if (newErrors[label]) {
          delete newErrors[label]; // Remove the error for the specific field
        }
        return newErrors;
      });
    }

    const updatedSubPrograms = programContent.values.map((item: any) =>
      item.label === label ? { ...item, enable: checked } : item
    );
    setProgramContent((prevData: any) => ({
      ...prevData,
      values: updatedSubPrograms,
    }));
  };

  const handlePostValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string
  ) => {

    const { value } = event.target;
  
    // Clear the error for this field if user starts typing
    setErrors((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      if (newErrors[label] && value.trim() !== "") {
        delete newErrors[label]; // Remove the error for the specific field
      }
      return newErrors;
    });

    const updatedSubPrograms = programContent.values.map((item: any) =>
      item.label === label ? { ...item, postValue: event.target.value } : item
    );
    setProgramContent((prevData: any) => ({
      ...prevData,
      values: updatedSubPrograms,
    }));
  };

  const handleAreaStudyStatus = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setProgramContent((prevData: any) => ({
      ...prevData,
      enable: checked,
    }));
  };

  const validateFields = () => {
    const newErrors: any = {};
    programContent.values.forEach((item: any) => {
      if (item.enable && !item.postValue) {
        newErrors[item.label] = "Advertiser Key cannot be empty";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  useEffect(() => {
    setProgramContent(program);
  }, [program]);

  useEffect(() => {
    if (submitRef) {
      submitRef.current = validateFields;
    }
  }, [programContent]);


  if (program.content) {
    return <TcpaGradYearSection />;
  } else {
    return (
      <div role="tabpanel" style={{ width: '100%' }}>
        <Paper>
          <Grid container justifyContent="space-between">
            <Grid item>
              <FormGroup sx={{ m: 1, minWidth: '100%', p: 1 }}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      name="status"
                      checked={isSelectAll}
                      onChange={handelSelectAllCheckBox}
                    />
                  }
                  label="Select All"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Box className="no-more-tables responsive-tbl">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    Advertiser Key
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {programContent?.values?.map((item: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell data-title="Status">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                name={'enable'}
                                checked={item.enable}
                                onChange={event => {
                                  handleStatus(event, item.label);
                                }}
                              />
                            }
                            label=""
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell data-title="Program Name">
                        {item.label}
                      </TableCell>
                      <TableCell
                        data-title="Advertiser Key"
                        sx={{ textAlign: 'center' }}
                      >
                        <TextField
                          type="text"
                          variant="outlined" // Ensure the variant is set correctly
                          value={item.postValue}
                          placeholder="Post Value"
                          disabled={!item.enable} // Disable if switch is off
                          InputProps={{
                            style: {
                              padding: '6px', // Remove internal padding
                              height: '41px', // Set your desired height
                            },
                              // Add error icon conditionally using InputAdornment
      endAdornment: errors[item.label] ? (
        <InputAdornment position="end">
          <ErrorOutlineIcon color="error" />
        </InputAdornment>
      ) : null,
    }}
                          sx={{
                            backgroundColor: '#FFF',
                            border: '1px solid #0AE',
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                            borderRadius: '8px',
                            color: '#00AAEE',
                          }}
                          onChange={(event: any) => {
                            handlePostValue(event, item.label);
                          }}
                          error={!!errors[item.mProgramId]} // true if error exists for this field
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </div>
    );
  }
};


const TCPAAudiTarg: FC<any> = ({ submitRef, handleNext }) => {
  const dispatch: any = useAppDispatch();
  const [showTabs, setShowTabs] = useState(true);
  const [value, setValue] = React.useState(0);
  const [selectedValue, setSelectedValue] = React.useState<any>('');
  const tcpaCampId = useAppSelector((state) => state.tcpaCampSlice.payloadTcpaId);
  const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
  const { tcpaEditJsonData,tcpaEditFlag,tcpaCampaignIdOnEdit,gradYear,tcpaCreateJsonData} = tcpaCampSlice
  const [subProgram, setSubProgram] = useState<any>(tcpaEditFlag ? (tcpaEditJsonData?.audienceTargeting?.[0] || tabOptions[0] ) : tabOptions[0]);
  const [tabContent, setTabContent] = useState(tcpaEditFlag ?( tcpaEditJsonData?.audienceTargeting || tabOptions) : tabOptions);
  const submitRefs = useRef<any>(null);

  useEffect(()=> {
    console.log('3333',tcpaCreateJsonData)
    if(tcpaCreateJsonData?.audienceTargeting){
      tcpaCreateJsonData?.audienceTargeting?.[0] && setSubProgram(tcpaCreateJsonData?.audienceTargeting?.[0] ||  tabOptions[0])
      tcpaCreateJsonData?.audienceTargeting && setTabContent(tcpaCreateJsonData?.audienceTargeting || tabOptions)
    }
  },[])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {

    setValue(newValue);
    setSubProgram(tabContent[newValue]);
  };

  // console.log('tabContent==', tabContent)

  const toggleTabsVisibility = () => {
    setShowTabs(!showTabs);
  };

  // Using useMediaQuery to detect screen width
  const isScreenAbove1300 = useMediaQuery('(min-width:1300px)');

  function a11yProps(index: number, name: string) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      name: name,
    };
  }

  const setUpDatedValue = (updateValue: any) => {
    const updatedTabContent = tabContent.map((item: any, index: number) =>
      item.title === updateValue.title ? updateValue : item
    );
    // console.log('updateValue', updatedTabContent);
    setTabContent(updatedTabContent);
    setSubProgram(updatedTabContent[value]);
  };

  useEffect(() => {
    // Automatically close the tabs if the screen width is below 1300px
    if (!isScreenAbove1300) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
  }, [isScreenAbove1300]);

  useEffect(() => {
    submitRef.current = () => {
      handleSubmit();
    };
  }, [tabContent,gradYear]);

  const handleSubmit =async ()=>{
    if(submitRefs.current){
      const isValid = submitRefs.current();
      if(isValid){
        const payload={
          audienceTargeting:tabContent,
          campaignType: "tcpaCampaign",
        tcpaCampaignId: tcpaEditFlag ? tcpaCampaignIdOnEdit : tcpaCampId,
        gradYear: gradYear,
        }
        try{
          if(tcpaEditFlag){
            const response = await dispatch(createTcpaCampAsync({ payload: payload, method: 'put' }));
            if(response){
              dispatch(setTcpaEditJson(response.payload.data))
              dispatch(setSnackbarOpen(true))
              dispatch(setSnackbarType("success"))
              dispatch(setSnackbarMessage(CAMPAIGN_UPDATE_SUCCESS))
              handleNext()
            }
            
            if (response.error) {
              setSnackbarOpen(true)
              setSnackbarType("error")
              setSnackbarMessage(response.error?.message)
            }
          }else{
            const response = await dispatch(createTcpaCampAsync({ payload: payload, method: 'put' }));
            if(response){
              dispatch(setTcpaCreateJson(response.payload.data))
              dispatch(setSnackbarOpen(true))
              dispatch(setSnackbarType("success"))
              dispatch(setSnackbarMessage(CAMPAIGN_CREATE_SUCCESS))
              handleNext();
            }
            if (response.error) {
              setSnackbarOpen(true)
              setSnackbarType("error")
              setSnackbarMessage(response.error?.message)
            }
          }
        
      }catch(err){
        console.error(err);
      }
      }else{
        console.log("Validation failed.");
      }
    }
    
  }

  return (
    <>
      <Box sx={{ position: 'relative', display: 'flex' }}>
        {showTabs && (
          <Box
            sx={{
              height: '70vh',
              flexBasis: '20%',
              padding: '10px',
            }}
            className="mm-tab-container"
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              {tabContent.map((item: any, index: any) => {
                return (
                  <Tab
                    label={item.title}
                    {...a11yProps(index, item.title)}
                    className={`mm-tab ${index === value ? 'active' : ''} `}
                  />
                );
              })}
            </Tabs>
          </Box>
        )}

        <Box
          className="mm-tabpanel-container"
          sx={{
            height: '75vh',
            flexBasis: '100%',
            padding: '11.5px',
          }}
        >
          <TabPanel program={subProgram} upDatedValue={setUpDatedValue} submitRef={submitRefs} />
        </Box>

        <Box
          sx={{
            position: 'fixed',
            bottom: '92px',
            right: '12px',
            zIndex: 999, // Ensure it's above other elements
          }}
        >
          {!isScreenAbove1300 && (
            <Fab
              onClick={toggleTabsVisibility}
              variant="extended"
              size="small"
              color="primary"
            >
              <MenuIcon sx={{ mr: 1 }} />
              Program
              {/* {tabContent[0].label == 'Business & Finance' ? 'Programs' : (tabContent[0].label == 'Campus Type' ? 'Audience' : (tabContent[0].label == '[ISM] Online College Guide' ? 'Media' : 'Rules'))} */}
            </Fab>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TCPAAudiTarg;




