import { staging, production, prodAwsAuthConfig, stageAwsAuthConfig} from "."

// console.log("Hosting env::::::::::::::MM_ADMIN_UI",process.env.REACT_APP_BUILD_ENV )
    const baseURLConfig =  process.env.REACT_APP_BUILD_ENV === "PROD" ?  production : staging;
   //   const baseURLConfig =  process.env.REACT_APP_BUILD_ENV === "PROD" ?  production : production;

    const awsConfig =  process.env.REACT_APP_BUILD_ENV === "PROD" ?  prodAwsAuthConfig : stageAwsAuthConfig

      // const awsConfig =  process.env.REACT_APP_BUILD_ENV === "PROD" ?  prodAwsAuthConfig : prodAwsAuthConfig
   //   console.log('process.env.REACT_APP_BUILD_ENV',process.env.REACT_APP_BUILD_ENV,awsConfig)
export { awsConfig, baseURLConfig} 