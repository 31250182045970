import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { websiteNames } from '../../validation';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import WebSiteInfo from './webSiteInfo';
import ProgramContent from './programContent';
import DefaultValueInfo from './defaultValueInfo';
import { setTempData } from '../../../../Store/Slices/SEOWebsiteInfoApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import Widget from './widget';
import HeaderFooterContent from './headerFooterContent';
import MuiAlert from '@mui/material/Alert';
import { setSnackbarOpen } from '../../../../Store/Slices/SEOHandlerApisSlice';

const steps = [
  'Website Info',
  'Header & Footer',
  'Widgets',
  'Default Value',
  'Program Content',
];
const SmallHorizontalActiveBar = styled('div')(({ theme }) => ({
  backgroundColor: '#34C759', // Change to your preferred color
  borderRadius: '100px',
  width: '100%',
  height: 4,
}));

const SmallHorizontalBar = styled('div')(({ theme }) => ({
  backgroundColor: '#C1C9D2;', // Change to your preferred color
  borderRadius: '100px',
  width: '100%',
  height: 4,
}));

const AddEditContent = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const websiteInfoFormData = useAppSelector(state => state.websiteInfoApi);
  const submitRef = useRef(() => { });
  const [currentStepperIndex, setCurrentStepperIndex] = useState(0);
  const validationss = useAppSelector(state => state.seoHandlerApi);
  const { rowData, fetchInitialData } = location.state;
  const apiLoading = useAppSelector((state) => state.seoHandlerApi.apiLoading);
  const navigate = useNavigate();
  
  const getCurrentStepperChild = () => {
    switch (currentStepperIndex) {
      case 0:
        return <WebSiteInfo submitRef={submitRef} handleNext={handleNext} />;
      case 1:
        return (
          <HeaderFooterContent submitRef={submitRef} handleNext={handleNext} />
        );
      case 2:
        return <Widget submitRef={submitRef} handleNext={handleNext} />;
      case 3:
        return (
          <DefaultValueInfo submitRef={submitRef} handleNext={handleNext} />
        );
      case 4:
        return <ProgramContent submitRef={submitRef} handleNext={handleNext} />;
      default:
        return null;
    }
  };

  const handleNext = () => {
    setCurrentStepperIndex(currentStepperIndex + 1);
  };
  const handleBack = () => {
    if (currentStepperIndex > 0) {
      setCurrentStepperIndex(currentStepperIndex - 1);
    }else{
      navigate('/cms-website');
    }
  };

  // const handleBack = () => {
  //   dispatch(
  //     setCurrentStepperIndex(websiteInfoFormData.currentStepperIndex - 1)
  //   );
  // };

  // useEffect(() => {
  //   console.log('rowData', rowData);
  //   dispatch(setTempData(rowData));
  // },[]);

  const handleCloseSnackbar = () => {
    dispatch(setSnackbarOpen(false));
  };

  return (
    <>
      <Box className="card">
        <Box display="flex" mb={2.5}>
          <Box>
            <Typography className="card-heading">Edit Website</Typography>
            <Typography className="card-sub-heading">
              Fill the details below to edit website
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={4}>
          <FormControl fullWidth>
            {/* <InputLabel>Websites *</InputLabel>
            <Select
              label="Websites *"
              name="website"
              onChange={() => {}}
              value={rowData ? rowData.website : ''}
              onBlur={() => {}}
              disabled
            >
              {websiteNames.map((website: any, index: any) => (
                <MenuItem value={website.name} key={index}>
                  {website.name}
                </MenuItem>
              ))}
            </Select> */}
            <TextField
              fullWidth
              sx={{ background: '#ffffff' }}
              label="Websites"
              onChange={() => { }}
              value={rowData ? rowData.websiteName : ''}
              onBlur={() => { }}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Box>{' '}
        <div className="stepper-container">
          <Stepper
            sx={{ overflow: 'auto', paddingBottom: '16px' }}
            activeStep={currentStepperIndex}
            alternativeLabel
          >
            {steps.map((label, index) => (
              <Step
                key={label}
                className="root-step-test"
                onClick={index => {
                  console.log('index', label);
                  // dispatch(setCurrentStepperIndex(steps.indexOf(label)));
                  setCurrentStepperIndex(steps.indexOf(label));
                }}
              >
                <StepLabel
                  StepIconComponent={({ active }) =>
                    active ? (
                      <SmallHorizontalActiveBar />
                    ) : (
                      <SmallHorizontalBar />
                    )
                  }
                  className="step-label-test"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ marginTop: '10px' }}>{getCurrentStepperChild()}</Box>
          <Box
            className="mProgram-btn-container"
            sx={{
              textAlign: 'right',
              paddingRight: '30px',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            {/* {currentStepperIndex !== 0 && ( */}
              <Button
                onClick={handleBack}
                sx={{
                  backgroundColor: '#FFF',
                  border: '1px solid #0AE',
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  borderRadius: '8px',
                  color: '#00AAEE',
                  width: '100px',
                  height: '45px',
                }}
              >
                Back
              </Button>
            {/* )} */}
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                submitRef.current();
              }}
              // sx={{
              //   backgroundColor: ' #00AAEE',
              //   '&.MuiButtonBase-root:hover': {
              //     bgcolor: '#00AAEE !important',
              //   },

              //   '&:hover': { backgroundColor: '#00AAEE' },
              //   border: '1px solid #0AE',
              //   boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              //   borderRadius: '8px',
              //   color: '#ffff',
              //   height: '45px',
              //   marginLeft: '10px',
              // }}
              sx={{
                backgroundColor: '#1976d2',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#1976d2 !important',
                },

                '&:hover': { backgroundColor: '#1976d2' },
                border: '1px solid #1976d2',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                borderRadius: '8px',
                // color: '#ffff',
                // width:'110px',
                height: '45px',
                marginLeft: '10px',
              }}
            >
              {
          apiLoading ? <CircularProgress color="inherit" size={24} /> :

            (currentStepperIndex === 4) ? "Finish" : "Save & Next"
        }
              {/* SAVE & NEXT */}
            </Button>
          </Box>
        </div>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={validationss.snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={validationss.successMessage ? 'success' : 'error'}
        >
          {validationss.successMessage || validationss.errorMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default AddEditContent;
