
import BidPrice from "../View/Advertiser/Stepper Form/Step components/BidPrice";
import GeoTargeting from "../View/Advertiser/Stepper Form/Step components/GeoTargeting";
import ZipRules from "../View/Advertiser/Stepper Form/Step components/ZipRules";
import TcpaGeoTargeting from "../View/TCPAcampaign/addTcpaEditDetails/tcpaGeoTarg/TcpaGeoTargeting";
import TcpaZipRules from "../View/TCPAcampaign/addTcpaEditDetails/tcpaGeoTarg/TcpaZipRules";
import SchedulingTable from "./Poc/CellSelectionTable";

export interface StateData {
    id: number;
    name: string;
    key: string
}

export const statesTargeting: StateData[] = [
    {
        id: 1,
        name: "Alabama (AL)",
        key: "AL"
    },
    {
        id: 2,
        name: "Alaska (AK)",
        key: 'AK'
    },
    {
        id: 3,
        name: "Arizona (AZ)",
        key: "AZ"
    },
    {
        id: 4,
        name: "Arkansas (Ar)",
        key: "AR"
    },
    {
        key: "CA",
        id: 5,
        name: "California (CA)"
    },
    {
        key: "CO",
        id: 7,
        name: "Colorado (CO)"
    },
    {
        key: "CT",
        id: 9,
        name: "Connecticut (CT)"
    },
    {
        key: "DE",
        id: 10,
        name: "Delaware (DE)"
    },
    {
        key: "DC",
        id: 11,
        name: "District of Columbia (DC)"
    },
    {
        key: "FL",
        id: 12,
        name: "Florida (FL)"
    },
    {
        key: "GA",
        id: 13,
        name: "Georgia (GA)"
    },
    {
        key: "HI",
        id: 14,
        name: "Hawaii (HI)"
    },
    {
        key: "ID",
        id: 15,
        name: "Idaho (ID)"
    },
    {
        key: "IL",
        id: 16,
        name: "Illinois (IL)"
    }, {
        key: "IA",
        id: 17,
        name: "Iowa (IA)"
    },
    {
        key: "KS",
        id: 18,
        name: "Kansas (KS)"
    },
    {
        key: "KY",
        id: 19,
        name: "Kentucky (KY)"
    },
    {
        key: "LA",
        id: 20,
        name: "Louisiana (LA)"
    },
    {
        key: "ME",
        id: 21,
        name: "Maine (ME)"
    },
    {
        key: "MD",
        id: 22,
        name: "Maryland (MD)"
    },
    {
        key: "IN",
        id: 53,
        name: "Indiana (IN)"
    },
    {
        key: "MA",
        id: 23,
        name: "Massachusetts (MA)"
    },
    {
        key: "MI",
        id: 24,
        name: "Michigan (MI)"
    },
    {
        key: "MN",
        id: 25,
        name: "Minnesota (MN)"
    }, {

        key: "MS",
        id: 26,
        name: "Mississippi (MS)"
    }, {
        key: "MO",
        id: 27,
        name: "Missouri (MO)"
    }, {
        key: "MT",
        id: 28,
        name: "Montana (MT)"
    }, {
        key: "NE",
        id: 29,
        name: "Nebraska (NE)"
    }, {
        key: "NV",
        id: 30,
        name: "Nevada (NV)"
    }, {
        key: "NH",
        id: 31,
        name: "New Hampshire (NH)"
    },

    {
        key: "NJ",
        id: 32,
        name: "New Jersey (NJ)"
    },
    {
        key: "NM",
        id: 33,
        name: "New Mexico (NM)"
    },
    {
        key: "NY",
        id: 34,
        name: "New York (NY)"
    },
    {
        key: "NC",
        id: 35,
        name: "North Carolina (NC)"
    },
    {
        key: "ND",
        id: 36,
        name: "North Dakota (ND)"
    },
    {
        key: "OH",
        id: 37,
        name: "Ohio (OH)"
    },
    {
        key: "OK",
        id: 38,
        name: "Oklahoma (OK)"
    },
    {
        key: "OR",
        id: 39,
        name: "Oregon (OR)"
    },
    {
        key: "PA",
        id: 40,
        name: "Pennsylvania (PA)"
    },
    {
        key: "RI",
        id: 41,
        name: "Rhode Island (RI)"
    },
    {
        key: "SC",
        id: 42,
        name: "South Carolina (SC)"
    },
    {
        key: "SD",
        id: 43,
        name: "South Dakota (SD)"
    },
    {
        key: "TN",
        id: 44,
        name: "Tennessee (TN)"
    },
    {
        key: "TX",
        id: 45,
        name: "Texas (TX)"
    },
    {
        key: "UT",
        id: 46,
        name: "Utah (UT)"
    },
    {
        key: "VT",
        id: 47,
        name: "Vermont (VT)"
    },

    {
        key: "VA",
        id: 48,
        name: "Virginia (VA)"
    },
    {
        key: "WA",
        id: 49,
        name: "Washington (WA)"
    },
    {
        key: "WV",
        id: 50,
        name: "West Virginia (WV)"
    }, {
        key: "WI",
        id: 51,
        name: "Wisconsin (WI)"
    },
    {
        key: "WY",
        id: 52,
        name: "Wyoming (WY)"
    },

]

export const stateRulesData=[
    {
        "label":"State Rules",
        content:GeoTargeting,
        "value":"1"

    },
    {
        "label":"Zip Rules",
        content:ZipRules,
        "value":"2"

    },
]


export const tcpaStateRulesData=[
    {
        "label":"State Rules",
        content: TcpaGeoTargeting,
        "value":"1"

    },
    {
        "label":"Zip Rules",
        content:TcpaZipRules,
        "value":"2"

    },
]

export const schedulingComponentData=[
    {
        "label":"Scheduling",
        content:SchedulingTable,
        "value":"1"

    },
    {
        "label":"Bid Price",
        content:BidPrice,
        "value":"2"

    },
]