import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "../../..";
import { fetchAllPublisherCampaignsData } from "../../../../service/Publisher";
import { createCampaignBarDatasets } from "./utils";

// const initialState={

//     overall:{

//     },
//     click
// }
interface  overallProps{
  overallPageLoading:boolean,
  campaignData:any[],
  campaignDropdownData:any[],
  campaignBarData:any[]
  selectedCampaigns:any[]
  publisherId:string | any
  // contentLoading:boolean
}

const initialState:overallProps={
   overallPageLoading:true,
   campaignData:[],
   campaignDropdownData:[],
   campaignBarData:[],
   selectedCampaigns:[],
   publisherId:localStorage.getItem('userSortid')?.slice(-5)
   
}
// const fetchCampaignsData= async()=>{
//   const arg2={
//     path:"publishers/campaigns",
//     queryParamsObject:{
//       ID:"campaign"
//     }
//   }
//  const response= await fetchAllPublisherCampaignsData(arg2.path, arg2.queryParamsObject)
// //  dispatch(storeCampaignData(response))
// }

export const getCampaignsThunk=createAsyncThunk('/get pub campaigns',async()=>{
  const userId=localStorage.getItem('userSortid')
  console.log('user ID ', userId)
  let  lastFiveCharacters
  if(userId){
   lastFiveCharacters = userId.slice(-5);

    console.log("Last 5 characters:", lastFiveCharacters);
  }
  
  const arg2={
    path:"publishers/campaign",
    queryParamsObject:{
      publisherID:lastFiveCharacters
    }
  }
 const response= await fetchAllPublisherCampaignsData(arg2.path, arg2.queryParamsObject)
//  dispatch(storeCampaignData(response))
console.log("response",response)
return response.data
} )
export const publisherOverallDashboardSlice=createSlice({
    name: "Publisher Overall dashboard slice",
  initialState: initialState,
  reducers:{
   storeCampaignData(state, action){
    state.campaignData=action.payload
   },

   storeCampaignDropdownData:(state, action)=>{
    state.campaignDropdownData=action.payload
   },
   updateSelectedCampaigns:(state, action)=>{
 state.selectedCampaigns=action.payload
   },
   cleanUpState:()=>{
    return initialState;
   }
  },
  extraReducers(builder) {
    builder.addCase(getCampaignsThunk.pending, (state)=>{
         state.overallPageLoading=true
    }).addCase(getCampaignsThunk.fulfilled, (state, action)=>{

      
      //  create chart bar data and drop down data.

      const ddData:any=action.payload.map((campaign:any)=>{
         return {
          label:campaign.campaignname,          
          value:`${campaign.SORTID}`
         }
      })
     state.selectedCampaigns=ddData.map((item:any)=>item.value)
      // create chart bar data using campaigns 
      const campaignBarDataSets=createCampaignBarDatasets(action.payload)
      console.log("campaignBarDataSet", campaignBarDataSets)
 
      state.campaignData=action.payload
      state.campaignDropdownData=ddData
      state.campaignBarData=campaignBarDataSets
      // console.log(ddData)
      state.overallPageLoading=false
    }).addCase(getCampaignsThunk.rejected,(state, action)=>{
        console.log("error in overall slice get campaigns by publisher", action.payload)
    })
  },

})



export const { storeCampaignData, storeCampaignDropdownData, updateSelectedCampaigns , cleanUpState} =
  publisherOverallDashboardSlice.actions;
// export const publisherOverallDashboardSelector = (state: RootState) => state.publisherDashboardOverallData
export default publisherOverallDashboardSlice.reducer;