// import { Auth } from 'aws-amplify'
// import { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
// import { NoUserRoutes, ROLE, mmRoutes, publisherRoutes } from './routes';
import PrivateRoutes from './Component/Auth/PrivateRoutes';
import { redirect, Route, Routes } from "react-router-dom";
import { Login1 } from "./Component/Auth/Login1";
import { ForgotPassword } from "./Component/Auth/Reset Password/ResetPassword";
import ResponsiveDrawerDemo from './Component/MMDrawer';
import theme from './MM_Theme';
import './App.scss';
import './App.css';
import { NoUserRoutes, ROLE, advertiserRoutes, mmRoutes, publisherRoutes } from './routes';
import pubCamp from './Mock/publisherCampaigns.json'
import { generateExcludePublisherData00 } from './Store/Slices/CreateAdvCampaignSlice';
function App() {
  let userRole = localStorage.getItem("userRole")
//   // let userRole = ''
//   const data = generateExcludePublisherData00(pubCamp,ep)
// console.log("data", data)
  return (
    <ThemeProvider theme={theme}>

      <div className="App">
        <Routes>
          <Route path="/login" element={<Login1 />} />
          <Route path="/reset-password" element={<ForgotPassword />} />

          {(userRole === 'admin' || userRole === 'Super-Admin') && (<Route element={<PrivateRoutes roles={[ROLE.admin]} />}>
            {mmRoutes.map((route:any, index:number) => {
              return <Route key={index} path={route.path} element={
                <ResponsiveDrawerDemo>
                  <route.element />
                </ResponsiveDrawerDemo>
              } />
            })}
          </Route>)}
          {userRole == 'Publisher' && (<Route element={<PrivateRoutes roles={[ROLE.publisher]} />}>
            {publisherRoutes.map((route:any, index:number) => {
              return <Route key={index} path={route.path} element={
                <ResponsiveDrawerDemo>
                  <route.element />
                </ResponsiveDrawerDemo>
              } />
            })}
          </Route>)}
          {userRole === 'Vendor' && (<Route element={<PrivateRoutes roles={[ROLE.advertiser]} />}>
            {advertiserRoutes.map((route:any, index:number) => {
              return <Route key={index} path={route.path} element={
                <ResponsiveDrawerDemo>
                  <route.element />
                </ResponsiveDrawerDemo>
              } />
            })}
          </Route>)}

          {!userRole && (<Route element={<PrivateRoutes roles={[]} />}>
            {NoUserRoutes.map((route:any, index:number) => {
              return <Route key={index} path={route.path} element={

                <route.element />


              } />
            })}
          </Route>)}

          {/* <Route
        path="user-dashboard"
        element={
          <PrivateRoute roles={[ROLE.User]}>
            <UserDashboard />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </div>

    </ThemeProvider>
  );
};
export default App;



const ep={
  "10476": {
      "title": "Media.net - 10476",
      "campaigns": {
          "36660": {
              "title": "Dynamic Redirect - 36660",
              "bid": 0,
              "enable": false
          },
          "40495": {
              "title": "Direct Linkout - 40495",
              "bid": 0,
              "enable": false
          },
          "59811": {
              "title": "API - Starter Campaign - 59811",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "10476",
              "SORTID": "36660",
              "title": "Dynamic Redirect - 36660",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "10476",
              "SORTID": "40495",
              "title": "Direct Linkout - 40495",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "10476",
              "SORTID": "59811",
              "title": "API - Starter Campaign - 59811",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "13060": {
      "publisherID": "13060",
      "title": "[PUB] compareonlinecolleges.org - 13060",
      "campaigns": {
          "48106": {
              "SORTID": "48106",
              "title": "default listings-48106",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": true,
      "newCampaigns": [
          {
              "publisherId": "13060",
              "SORTID": "48106",
              "title": "default listings-48106",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "18073": {
      "title": "Nextstepdegree.com - 18073",
      "campaigns": {
          "33627": {
              "title": "Clicks Page - 33627",
              "bid": 0,
              "enable": false
          },
          "34182": {
              "title": "Thank You PAge - 34182",
              "bid": 0,
              "enable": false
          },
          "41198": {
              "title": "SubPub - Revenue Ads - 41198",
              "bid": 0,
              "enable": false
          },
          "44480": {
              "title": "No Match - 44480",
              "bid": 0,
              "enable": false
          },
          "77087": {
              "title": "SUBPUB - Revenue Ads - 77087",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "18073",
              "SORTID": "33627",
              "title": "Clicks Page - 33627",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "18073",
              "SORTID": "34182",
              "title": "Thank You PAge - 34182",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "18073",
              "SORTID": "41198",
              "title": "SubPub - Revenue Ads - 41198",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "18073",
              "SORTID": "44480",
              "title": "No Match - 44480",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "18073",
              "SORTID": "77087",
              "title": "SUBPUB - Revenue Ads - 77087",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "19709": {
      "title": "CompareOnlinePsychologyDegrees.com - 19709",
      "campaigns": {
          "35153": {
              "publisherId": "19709",
              "bid": 0,
              "title": "COMPLIANT",
              "enable": false
          },
          "57082": {
              "publisherId": "19709",
              "bid": 0,
              "title": "Google-SEM",
              "enable": false
          },
          "61496": {
              "publisherId": "19709",
              "bid": 0,
              "title": "On-Page Listings",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "19709",
              "SORTID": "35153",
              "title": "COMPLIANT - 35153",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "19709",
              "SORTID": "57082",
              "title": "Google-SEM - 57082",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "19709",
              "SORTID": "61496",
              "title": "On-Page Listings - 61496",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "25708": {
      "publisherID": "25708",
      "title": "[MM] Find Career Colleges - 25708",
      "campaigns": {
          "21068": {
              "publisherId": "25708",
              "bid": 6,
              "title": "COMPLIANT-21068",
              "enable": false
          },
          "40595": {
              "publisherId": "25708",
              "bid": 6,
              "title": "On-Page Listings-40595",
              "enable": false
          },
          "49300": {
              "publisherId": "25708",
              "bid": 6,
              "title": "Google-SEM-49300",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "25708",
              "SORTID": "21068",
              "title": "COMPLIANT-21068",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "25708",
              "SORTID": "40595",
              "title": "On-Page Listings-40595",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "25708",
              "SORTID": "49300",
              "title": "Google-SEM-49300",
              "bid": 6,
              "enable": false
          }
      ]
  },
  "26730": {
      "title": "Stacked Media - 26730",
      "campaigns": {
          "21943": {
              "title": "GRANT - Thank you Page - 21943",
              "bid": 0,
              "enable": false
          },
          "23546": {
              "title": "[Pub] Sorry Page - 23546",
              "bid": 0,
              "enable": false
          },
          "29533": {
              "title": "Sorry Page (No Match) - 29533",
              "bid": 0,
              "enable": false
          },
          "86515": {
              "title": "[Pub] GRANT - Thank you Page - 86515",
              "bid": 0,
              "enable": false
          },
          "87506": {
              "title": "Thank You Page - 87506",
              "bid": 0,
              "enable": false
          },
          "88107": {
              "title": "[Pub] GRANT - Sorry Page (No Match) - 88107",
              "bid": 0,
              "enable": false
          },
          "04797": {
              "title": "[Pub] Thank You Page - 04797",
              "bid": 0,
              "enable": false
          },
          "05632": {
              "title": "GRANT - Sorry Page (No Match) - 05632",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "26730",
              "SORTID": "21943",
              "title": "GRANT - Thank you Page - 21943",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "26730",
              "SORTID": "23546",
              "title": "[Pub] Sorry Page - 23546",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "26730",
              "SORTID": "29533",
              "title": "Sorry Page (No Match) - 29533",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "26730",
              "SORTID": "86515",
              "title": "[Pub] GRANT - Thank you Page - 86515",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "26730",
              "SORTID": "87506",
              "title": "Thank You Page - 87506",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "26730",
              "SORTID": "88107",
              "title": "[Pub] GRANT - Sorry Page (No Match) - 88107",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "26730",
              "SORTID": "04797",
              "title": "[Pub] Thank You Page - 04797",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "26730",
              "SORTID": "05632",
              "title": "GRANT - Sorry Page (No Match) - 05632",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "26730",
              "SORTID": "14701",
              "title": "TCPA Redirect Page-14701",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "26730",
              "SORTID": "19900",
              "title": "[GOOG] COC - Form Redirect-19900",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "26730",
              "SORTID": "83450",
              "title": "REDIRECT LINK-83450",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "30840": {
      "title": "Lightning Bolt Media - 30840",
      "campaigns": {
          "38161": {
              "title": "Alt-Campaign - 38161",
              "bid": 0,
              "enable": false
          },
          "75468": {
              "title": "Thank You Page - 75468",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "30840",
              "SORTID": "38161",
              "title": "Alt-Campaign - 38161",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "30840",
              "SORTID": "75468",
              "title": "Thank You Page - 75468",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "35776": {
      "title": "Birddog Media - 35776",
      "campaigns": {
          "75617": {
              "publisherId": "35776",
              "bid": 0,
              "title": "NSD",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "35776",
              "SORTID": "75617",
              "title": "NSD - Listings Page - 75617",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "36984": {
      "publisherID": "36984",
      "title": "[ISM] Online College Guide - 36984",
      "campaigns": {
          "27152": {
              "publisherId": "36984",
              "bid": "0",
              "title": "On-Page Listings-27152",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "36984",
              "SORTID": "27152",
              "title": "On-Page Listings-27152",
              "bid": "0",
              "enable": false
          }
      ]
  },
  "38174": {
      "title": "Cygnus Edu - 38174",
      "campaigns": {
          "26860": {
              "publisherId": "38174",
              "bid": 0,
              "title": "Redirect",
              "enable": false
          },
          "67183": {
              "publisherId": "38174",
              "bid": 0,
              "title": "Thank You Page",
              "enable": false
          },
          "04627": {
              "publisherId": "38174",
              "bid": 0,
              "title": "No Match Page",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "38174",
              "SORTID": "26860",
              "title": "Redirect - 26860",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "38174",
              "SORTID": "67183",
              "title": "Thank You Page - 67183",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "38174",
              "SORTID": "04627",
              "title": "No Match Page - 04627",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "44210": {
      "title": "Ventrix Advertising - 44210",
      "campaigns": {
          "61401": {
              "publisherId": "44210",
              "bid": 0,
              "title": "Publisher Sourced Media",
              "enable": false
          },
          "73249": {
              "publisherId": "44210",
              "bid": 0,
              "title": "Grant Related Traffic",
              "enable": false
          },
          "85463": {
              "publisherId": "44210",
              "bid": 0,
              "title": "Internal Media",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "44210",
              "SORTID": "61401",
              "title": "Publisher Sourced Media - 61401",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "44210",
              "SORTID": "73249",
              "title": "Grant Related Traffic - 73249",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "44210",
              "SORTID": "85463",
              "title": "Internal Media - 85463",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "44210",
              "SORTID": "00776",
              "title": "SEM No Grant-00776",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "44210",
              "SORTID": "42633",
              "title": "SEM Catchall-42633",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "44210",
              "SORTID": "65525",
              "title": "SEM Grant Related-65525",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "44968": {
      "publisherID": "44968",
      "title": "[MM] Online Teaching Colleges - 44968",
      "campaigns": {
          "12295": {
              "SORTID": "12295",
              "title": "On-Page Listings-12295",
              "bid": 6,
              "enable": false
          },
          "14625": {
              "SORTID": "14625",
              "title": "Google-SEM-14625",
              "bid": 6,
              "enable": false
          },
          "27329": {
              "SORTID": "27329",
              "title": "COMPLIANT-27329",
              "bid": 6,
              "enable": false
          }
      },
      "bid": 0,
      "enable": true,
      "newCampaigns": [
          {
              "publisherId": "44968",
              "SORTID": "12295",
              "title": "On-Page Listings-12295",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "44968",
              "SORTID": "14625",
              "title": "Google-SEM-14625",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "44968",
              "SORTID": "27329",
              "title": "COMPLIANT-27329",
              "bid": 6,
              "enable": false
          }
      ]
  },
  "50301": {
      "title": "Banner Edge Media - 50301",
      "campaigns": {
          "14709": {
              "publisherId": "50301",
              "bid": 0,
              "title": "DIRECT MATCH | South University",
              "enable": true
          },
          "15260": {
              "publisherId": "50301",
              "bid": 0,
              "title": "BSO Premium",
              "enable": true
          },
          "18027": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Highest Bidder Redirect",
              "enable": true
          },
          "20568": {
              "publisherId": "50301",
              "bid": 0,
              "title": "DIRECT MATCH | BSO",
              "enable": true
          },
          "23629": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Business (Dynamic Linkout)",
              "enable": true
          },
          "24227": {
              "publisherId": "50301",
              "bid": 0,
              "title": "UT at Austin | Premium",
              "enable": true
          },
          "32655": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Targeted Advertiser Match",
              "enable": true
          },
          "33664": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Associates | Psychology (Dynamic Linkout)",
              "enable": true
          },
          "36756": {
              "publisherId": "50301",
              "bid": 0,
              "title": "[NEW] Dynamic URL",
              "enable": true
          },
          "36771": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Saint Leo",
              "enable": true
          },
          "41053": {
              "publisherId": "50301",
              "bid": 0,
              "title": "BSO Premium",
              "enable": true
          },
          "41842": {
              "publisherId": "50301",
              "bid": 0,
              "title": "BSO Premium",
              "enable": true
          },
          "47996": {
              "publisherId": "50301",
              "bid": 0,
              "title": "College-Matcher",
              "enable": true
          },
          "49468": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Associates | Healthcare (Dynamic Linkout)",
              "enable": true
          },
          "50562": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Associates | Education (Dynamic Linkout)",
              "enable": true
          },
          "56174": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Associates | Criminal Justice (Dynamic Linkout)",
              "enable": true
          },
          "58519": {
              "publisherId": "50301",
              "bid": 0,
              "title": "DIRECT MATCH | BSO",
              "enable": true
          },
          "61436": {
              "publisherId": "50301",
              "bid": 0,
              "title": "DIRECT MATCH | BSO",
              "enable": true
          },
          "63298": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Healthcare (Dynamic Linkout)",
              "enable": true
          },
          "65680": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Bachelors | Education (Dynamic Linkout)",
              "enable": true
          },
          "70850": {
              "publisherId": "50301",
              "bid": 0,
              "title": "UT at Austin | Premium",
              "enable": true
          },
          "72921": {
              "publisherId": "50301",
              "bid": 0,
              "title": "General (Dynamic Linkout)",
              "enable": true
          },
          "76625": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Associates | Computers (Dynamic Linkout)",
              "enable": true
          },
          "77288": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Associates | Business (Dynamic Linkout)",
              "enable": true
          },
          "83792": {
              "publisherId": "50301",
              "bid": "0",
              "title": "South U",
              "enable": true
          },
          "84261": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Masters | Business (Dynamic Linkout)",
              "enable": true
          },
          "86546": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Computers & IT (Dynamic Linkout)",
              "enable": true
          },
          "87498": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Psychology (Dynamic Linkout)",
              "enable": true
          },
          "89794": {
              "publisherId": "50301",
              "bid": 0,
              "title": "Bachelors | Criminal Justice",
              "enable": true
          },
          "96216": {
              "publisherId": "50301",
              "bid": "0",
              "title": "BSO",
              "enable": true
          }
      },
      "bid": 0,
      "enable": true,
      "newCampaigns": [
          {
              "publisherId": "50301",
              "SORTID": "14709",
              "title": "DIRECT MATCH | South University - General - 14709",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "15260",
              "title": "BSO Premium - Education - 15260",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "18027",
              "title": "Highest Bidder Redirect - 18027",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "20568",
              "title": "DIRECT MATCH | BSO - Education & Teaching - 20568",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "23629",
              "title": "Business (Dynamic Linkout) - 23629",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "24227",
              "title": "UT at Austin | Premium - MS Nutrition - 24227",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "32655",
              "title": "Targeted Advertiser Match - 32655",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "33664",
              "title": "Associates | Psychology (Dynamic Linkout) - 33664",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "36756",
              "title": "[NEW] Dynamic URL - General - 36756",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "36771",
              "title": "Saint Leo - Direct - 36771",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "41053",
              "title": "BSO Premium - Criminal Justice/Legal - 41053",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "41842",
              "title": "BSO Premium - Healthcare - 41842",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "47996",
              "title": "College-Matcher - Redirect - 47996",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "49468",
              "title": "Associates | Healthcare (Dynamic Linkout) - 49468",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "50562",
              "title": "Associates | Education (Dynamic Linkout) - 50562",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "56174",
              "title": "Associates | Criminal Justice (Dynamic Linkout) - 56174",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "58519",
              "title": "DIRECT MATCH | BSO - Criminal Justice - 58519",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "61436",
              "title": "DIRECT MATCH | BSO - Healthcare - 61436",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "63298",
              "title": "Healthcare (Dynamic Linkout) - 63298",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "65680",
              "title": "Bachelors | Education (Dynamic Linkout) - 65680",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "70850",
              "title": "UT at Austin | Premium - MS Computers - 70850",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "72921",
              "title": "General (Dynamic Linkout) - 72921",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "76625",
              "title": "Associates | Computers (Dynamic Linkout) - 76625",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "77288",
              "title": "Associates | Business (Dynamic Linkout) - 77288",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "83792",
              "title": "South U - Direct - 83792",
              "bid": "0",
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "84261",
              "title": "Masters | Business (Dynamic Linkout) - 84261",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "86546",
              "title": "Computers & IT (Dynamic Linkout) - 86546",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "87498",
              "title": "Psychology (Dynamic Linkout) - 87498",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "89794",
              "title": "Bachelors | Criminal Justice - 89794",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "50301",
              "SORTID": "96216",
              "title": "BSO - Direct - 96216",
              "bid": "0",
              "enable": true
          }
      ]
  },
  "54979": {
      "title": "FindOnlineCodingBootcamps.com - 54979",
      "campaigns": {
          "28721": {
              "title": "Google-SEM - 28721",
              "bid": 0,
              "enable": false
          },
          "44982": {
              "title": "On-Page Listings - 44982",
              "bid": 0,
              "enable": false
          },
          "88027": {
              "title": "COMPLIANT - 88027",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "54979",
              "SORTID": "28721",
              "title": "Google-SEM - 28721",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "54979",
              "SORTID": "44982",
              "title": "On-Page Listings - 44982",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "54979",
              "SORTID": "88027",
              "title": "COMPLIANT - 88027",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "61568": {
      "title": "CompareOnlineCriminologyDegrees.com - 61568",
      "campaigns": {
          "16277": {
              "publisherId": "61568",
              "bid": 0,
              "title": "On-Page Listings",
              "enable": false
          },
          "62441": {
              "publisherId": "61568",
              "bid": 0,
              "title": "COMPLIANT",
              "enable": false
          },
          "66814": {
              "publisherId": "61568",
              "bid": 0,
              "title": "Google-SEM",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "61568",
              "SORTID": "16277",
              "title": "On-Page Listings - 16277",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "61568",
              "SORTID": "62441",
              "title": "COMPLIANT - 62441",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "61568",
              "SORTID": "66814",
              "title": "Google-SEM - 66814",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "62453": {
      "title": "CM-TEST - 62453",
      "campaigns": {
          "56543": {
              "title": "Archer EDU Test - 56543",
              "bid": 0,
              "enable": false
          },
          "61124": {
              "title": "Version 2 - 61124",
              "bid": 0,
              "enable": false
          },
          "83538": {
              "title": "ASD Test - 83538",
              "bid": 0,
              "enable": false
          },
          "98820": {
              "title": "CM-Test-Campaign - 98820",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "62453",
              "SORTID": "56543",
              "title": "Archer EDU Test - 56543",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "62453",
              "SORTID": "61124",
              "title": "Version 2 - 61124",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "62453",
              "SORTID": "83538",
              "title": "ASD Test - 83538",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "62453",
              "SORTID": "98820",
              "title": "CM-Test-Campaign - 98820",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "63873": {
      "title": "Internal | Facebook - 63873",
      "campaigns": {
          "22674": {
              "title": "Direct Advertiser - 22674",
              "bid": 0,
              "enable": false
          },
          "38378": {
              "title": "Facebook - Psychology - 38378",
              "bid": 0,
              "enable": false
          },
          "48656": {
              "title": "OCM | Listings - 48656",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "63873",
              "SORTID": "22674",
              "title": "Direct Advertiser - 22674",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "63873",
              "SORTID": "38378",
              "title": "Facebook - Psychology - 38378",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "63873",
              "SORTID": "48656",
              "title": "OCM | Listings - 48656",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "67468": {
      "publisherID": "67468",
      "title": "[MM] Find Medical Career Colleges - 67468",
      "campaigns": {
          "15640": {
              "publisherId": "67468",
              "bid": 6,
              "title": "COMPLIANT-15640",
              "enable": false
          },
          "18166": {
              "publisherId": "67468",
              "bid": 6,
              "title": "On-Page Listings-18166",
              "enable": false
          },
          "46006": {
              "publisherId": "67468",
              "bid": 6,
              "title": "Google-SEM-46006",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "67468",
              "SORTID": "15640",
              "title": "COMPLIANT-15640",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "67468",
              "SORTID": "18166",
              "title": "On-Page Listings-18166",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "67468",
              "SORTID": "46006",
              "title": "Google-SEM-46006",
              "bid": 6,
              "enable": false
          }
      ]
  },
  "75454": {
      "publisherID": "75454",
      "title": "Archer EDU - 75454",
      "campaigns": {
          "98750": {
              "SORTID": "98750",
              "title": "Test Offer-98750",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": true,
      "newCampaigns": [
          {
              "publisherId": "75454",
              "SORTID": "98750",
              "title": "Test Offer-98750",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "77241": {
      "title": "Perform Media - 77241",
      "campaigns": {
          "93371": {
              "title": "Highest Bidder - 93371",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "77241",
              "SORTID": "93371",
              "title": "Highest Bidder - 93371",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "79557": {
      "title": "Prod_Publisher - 79557",
      "campaigns": {
          "13557": {
              "publisherId": "79557",
              "bid": 0,
              "title": "Prod_Publisher_Camp",
              "enable": true
          },
          "48382": {
              "publisherId": "79557",
              "bid": 0,
              "title": "New Prod Publisher Camp",
              "enable": true
          },
          "71382": {
              "publisherId": "79557",
              "bid": 0,
              "title": "New Prod Pub Camp",
              "enable": true
          }
      },
      "bid": 0,
      "enable": true,
      "newCampaigns": [
          {
              "publisherId": "79557",
              "SORTID": "13557",
              "title": "Prod_Publisher_Camp - 13557",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "79557",
              "SORTID": "48382",
              "title": "New Prod Publisher Camp - 48382",
              "bid": 0,
              "enable": true
          },
          {
              "publisherId": "79557",
              "SORTID": "71382",
              "title": "New Prod Pub Camp - 71382",
              "bid": 0,
              "enable": true
          },
          {
              "title": "New camp-ms-test-36974",
              "bid": 0,
              "enable": false,
              "SORTID": "36974",
              "publisherId": "79557"
          }
      ]
  },
  "83176": {
      "title": "Organic - Morrow Technology - 83176",
      "campaigns": {
          "11203": {
              "title": "MBAGuide.org - 11203",
              "bid": 0,
              "enable": false
          },
          "74412": {
              "title": "psychologyonlinedegrees.org - 74412",
              "bid": 0,
              "enable": false
          },
          "78380": {
              "title": "rncareers.org - 78380",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "83176",
              "SORTID": "11203",
              "title": "MBAGuide.org - 11203",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "83176",
              "SORTID": "74412",
              "title": "psychologyonlinedegrees.org - 74412",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "83176",
              "SORTID": "78380",
              "title": "rncareers.org - 78380",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "83687": {
      "title": "College-Matcher.com - 83687",
      "campaigns": {
          "18722": {
              "title": "BEM - Listing Redirect - 18722",
              "bid": 0,
              "enable": false
          },
          "32359": {
              "title": "FB - Business - 32359",
              "bid": 0,
              "enable": false
          },
          "86658": {
              "title": "Default Listings - 86658",
              "bid": 0,
              "enable": false
          },
          "92434": {
              "title": "Lead Form Page - 92434",
              "bid": 0,
              "enable": false
          },
          "95901": {
              "title": "Internal | Pubs in UI - 95901",
              "bid": 0,
              "enable": false
          },
          "08167": {
              "title": "TEST PUB CAMPAIGN - 08167",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "83687",
              "SORTID": "18722",
              "title": "BEM - Listing Redirect - 18722",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "83687",
              "SORTID": "32359",
              "title": "FB - Business - 32359",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "83687",
              "SORTID": "86658",
              "title": "Default Listings - 86658",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "83687",
              "SORTID": "92434",
              "title": "Lead Form Page - 92434",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "83687",
              "SORTID": "95901",
              "title": "Internal | Pubs in UI - 95901",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "83687",
              "SORTID": "08167",
              "title": "TEST PUB CAMPAIGN - 08167",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "87389": {
      "title": "CompareOnlineBusinessDegrees.com - 87389",
      "campaigns": {
          "38967": {
              "publisherId": "87389",
              "bid": 0,
              "title": "COMPLIANT",
              "enable": false
          },
          "48183": {
              "publisherId": "87389",
              "bid": 0,
              "title": "Google-SEM",
              "enable": false
          },
          "80525": {
              "publisherId": "87389",
              "bid": 0,
              "title": "On-Page Listings",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "87389",
              "SORTID": "38967",
              "title": "COMPLIANT - 38967",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "87389",
              "SORTID": "48183",
              "title": "Google-SEM - 48183",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "87389",
              "SORTID": "80525",
              "title": "On-Page Listings - 80525",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "92670": {
      "title": "Allstar Directories (PUB) - 92670",
      "campaigns": {
          "16160": {
              "publisherId": "92670",
              "bid": 0,
              "title": "Sorry Page",
              "enable": false
          },
          "91927": {
              "publisherId": "92670",
              "bid": 0,
              "title": "Direct Match",
              "enable": false
          },
          "07399": {
              "publisherId": "92670",
              "bid": 0,
              "title": "Thank You Page",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "92670",
              "SORTID": "16160",
              "title": "Sorry Page - 16160",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "92670",
              "SORTID": "91927",
              "title": "Direct Match - 91927",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "92670",
              "SORTID": "07399",
              "title": "Thank You Page - 07399",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "92670",
              "SORTID": "67104",
              "title": "ASD Internal Sources-67104",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "93253": {
      "title": "MBA Guide - 93253",
      "campaigns": {
          "48724": {
              "title": "SEM Linkout v1 - 48724",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "93253",
              "SORTID": "48724",
              "title": "SEM Linkout v1 - 48724",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "93426": {
      "publisherID": "93426",
      "title": "[MM] Find Online Nursing Colleges - 93426",
      "campaigns": {
          "12588": {
              "SORTID": "12588",
              "title": "On-Page Listings-12588",
              "bid": 6,
              "enable": false
          },
          "15055": {
              "SORTID": "15055",
              "title": "COMPLIANT-15055",
              "bid": 6,
              "enable": false
          },
          "80256": {
              "SORTID": "80256",
              "title": "Google-SEM-80256",
              "bid": 6,
              "enable": false
          }
      },
      "bid": 0,
      "enable": true,
      "newCampaigns": [
          {
              "publisherId": "93426",
              "SORTID": "12588",
              "title": "On-Page Listings-12588",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "93426",
              "SORTID": "15055",
              "title": "COMPLIANT-15055",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "93426",
              "SORTID": "80256",
              "title": "Google-SEM-80256",
              "bid": 6,
              "enable": false
          }
      ]
  },
  "93552": {
      "title": "CompareOnlineDegrees.com - 93552",
      "campaigns": {
          "17019": {
              "publisherId": "93552",
              "bid": 0,
              "title": "Bing-SEM-17019",
              "enable": false
          },
          "21512": {
              "publisherId": "93552",
              "bid": 0,
              "title": "COMPLIANT",
              "enable": false
          },
          "25134": {
              "publisherId": "93552",
              "bid": 0,
              "title": "On-Page Listings",
              "enable": false
          },
          "45438": {
              "publisherId": "93552",
              "bid": 0,
              "title": "Lead Form TY/Sorry",
              "enable": false
          },
          "82422": {
              "publisherId": "93552",
              "bid": 0,
              "title": "Google-SEM",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "93552",
              "SORTID": "21512",
              "title": "COMPLIANT - 21512",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "93552",
              "SORTID": "25134",
              "title": "On-Page Listings - 25134",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "93552",
              "SORTID": "45438",
              "title": "Lead Form TY/Sorry - 45438",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "93552",
              "SORTID": "82422",
              "title": "Google-SEM - 82422",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "93552",
              "SORTID": "17019",
              "title": "Bing-SEM-17019",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "99369": {
      "title": "Revenue Ads - 99369",
      "campaigns": {
          "18792": {
              "title": "Internal Email - 18792",
              "bid": "-50",
              "enable": false
          },
          "38925": {
              "title": "Direct Adv - 38925",
              "bid": "-50",
              "enable": false
          },
          "00572": {
              "title": "Internal Facebook - 00572",
              "bid": "-50",
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "99369",
              "SORTID": "18792",
              "title": "Internal Email - 18792",
              "bid": "-50",
              "enable": false
          },
          {
              "publisherId": "99369",
              "SORTID": "38925",
              "title": "Direct Adv - 38925",
              "bid": "-50",
              "enable": false
          },
          {
              "publisherId": "99369",
              "SORTID": "00572",
              "title": "Internal Facebook - 00572",
              "bid": "-50",
              "enable": false
          }
      ]
  },
  "99784": {
      "publisherID": "99784",
      "title": "Zeta Global - 99784",
      "campaigns": {
          "39980": {
              "SORTID": "39980",
              "title": "Email-39980",
              "bid": 6,
              "enable": false
          },
          "83314": {
              "SORTID": "83314",
              "title": "Social-83314",
              "bid": 6,
              "enable": false
          }
      },
      "bid": 0,
      "enable": true,
      "newCampaigns": [
          {
              "publisherId": "99784",
              "SORTID": "39980",
              "title": "Email-39980",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "99784",
              "SORTID": "83314",
              "title": "Social-83314",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "99784",
              "SORTID": "01775",
              "title": "Affiliate-01775",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "00659": {
      "title": "Adhere - 00659",
      "campaigns": {
          "63178": {
              "title": "MAO Integration - 63178",
              "bid": 0,
              "enable": false
          },
          "73108": {
              "title": "Short Form Listings - 73108",
              "bid": 0,
              "enable": false
          },
          "07219": {
              "title": "Long Form Listings - 07219",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "00659",
              "SORTID": "63178",
              "title": "MAO Integration - 63178",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "00659",
              "SORTID": "73108",
              "title": "Short Form Listings - 73108",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "00659",
              "SORTID": "07219",
              "title": "Long Form Listings - 07219",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "05322": {
      "title": "MAO Clickout - 05322",
      "campaigns": {
          "12927": {
              "title": "Adhere | Psychology | MAO - 12927",
              "bid": 0,
              "enable": false
          },
          "14521": {
              "title": "Adhere | Computer Technology | MAO - 14521",
              "bid": 0,
              "enable": false
          },
          "32371": {
              "title": "Adhere | Healthcare | MAO - 32371",
              "bid": 0,
              "enable": false
          },
          "47797": {
              "title": "Adhere | Business | MAO - 47797",
              "bid": 0,
              "enable": false
          },
          "65285": {
              "title": "MAO Payable Clickout - 65285",
              "bid": 0,
              "enable": false
          },
          "84890": {
              "title": "Adhere | Education | MAO - 84890",
              "bid": 0,
              "enable": false
          }
      },
      "bid": 0,
      "enable": false,
      "newCampaigns": [
          {
              "publisherId": "05322",
              "SORTID": "12927",
              "title": "Adhere | Psychology | MAO - 12927",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "05322",
              "SORTID": "14521",
              "title": "Adhere | Computer Technology | MAO - 14521",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "05322",
              "SORTID": "32371",
              "title": "Adhere | Healthcare | MAO - 32371",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "05322",
              "SORTID": "47797",
              "title": "Adhere | Business | MAO - 47797",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "05322",
              "SORTID": "65285",
              "title": "MAO Payable Clickout - 65285",
              "bid": 0,
              "enable": false
          },
          {
              "publisherId": "05322",
              "SORTID": "84890",
              "title": "Adhere | Education | MAO - 84890",
              "bid": 0,
              "enable": false
          }
      ]
  },
  "04399": {
      "publisherID": "04399",
      "title": "[MM] Online Humanities Degrees - 04399",
      "campaigns": {
          "40825": {
              "SORTID": "40825",
              "title": "Google-SEM-40825",
              "bid": 6,
              "enable": false
          },
          "41248": {
              "SORTID": "41248",
              "title": "COMPLIANT-41248",
              "bid": 6,
              "enable": false
          },
          "89963": {
              "SORTID": "89963",
              "title": "On-Page Listings-89963",
              "bid": 6,
              "enable": false
          }
      },
      "bid": 0,
      "enable": true,
      "newCampaigns": [
          {
              "publisherId": "04399",
              "SORTID": "40825",
              "title": "Google-SEM-40825",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "04399",
              "SORTID": "41248",
              "title": "COMPLIANT-41248",
              "bid": 6,
              "enable": false
          },
          {
              "publisherId": "04399",
              "SORTID": "89963",
              "title": "On-Page Listings-89963",
              "bid": 6,
              "enable": false
          }
      ]
  }
}