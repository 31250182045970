import { configureStore } from '@reduxjs/toolkit';
import CreateAdvCampaignReducer from './Slices/CreateAdvCampaignSlice';
import publisherReducer from './Slices/CreatePublisherSlice';
import campaignReducer from './Slices/PublisherCampaignSlice';
import createAdvertiserButton from './Slices/CreateAdvButtonDialogSlice'
import {publisherCampApi} from './Slices/PublisherTabApisData'
import publisherAccApiSlice from './Slices/PublisherTabApisData';
import advertiserAccApiSlice from './Slices/advertiserTabApisData';
import {advertiserCampApi} from './Slices/advertiserTabApisData'
import userSlice from './Slices/Auth/userSlice';
import forgetPasswordSlice from './Slices/Auth/forgetPasswordSlice';
import { seoHandlerApi } from './Slices/SEOHandlerApisSlice';
import { websiteInfoApi } from './Slices/SEOWebsiteInfoApiSlice';
import { programContentApi } from './Slices/CmsProgramContentDataSlice';
import { mBrandHandlerApi } from './Slices/ExternalApiSlice';
import { publisherOverallDashboardSlice } from './Slices/Publisher-role/Dashboard/publisherDashboardSlice';
import rawDataReportSlice from './Slices/RawDataReportSlice';
import { tcpaCampSlice } from './Slices/tcpaCampSlice';

export const store = configureStore({
  reducer: {
    // our reducers goes here
    publisher: publisherReducer,
    CreateAdvCampaignReducer,
    campaign: campaignReducer,
    createAdvButton:createAdvertiserButton,
    publisherAccApi:publisherAccApiSlice,
    publisherCampApi:publisherCampApi,
    advertiserAccApi:advertiserAccApiSlice,
    userAuthData:userSlice,
    advertiserCampApi:advertiserCampApi,
    forgetPasswordAuth:forgetPasswordSlice,
    seoHandlerApi:seoHandlerApi,
    websiteInfoApi:websiteInfoApi,
    programContentApi:programContentApi,
    mBrandHandlerApi:mBrandHandlerApi,
    rawDataReportSlice:rawDataReportSlice,
    tcpaCampSlice:tcpaCampSlice,
    // publisherOverallDashboardSlice:publisherOverallDashboardSlice
    overallData:publisherOverallDashboardSlice.reducer
  },
//  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck:false})
 middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    immutableCheck: false, // Disable the immutability check
    serializableCheck: false, // Disable the serializability check (optional)
  }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;