import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
// import DateRange from '../../../../Component/DateRange picker/DateRange';
import {
  areaStudyDropdown,
  reportType,
  timeLineDropDown,
} from '../../../../Mock/Dashboard Data/OverallData';
import MultipleSelectDropDown from '../../../../Component/DropDowns';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { fetchMBrandApiData } from '../../../../Services/externalApis';
import moment from 'moment';
import {
  getCampaignsThunk,
  updateSelectedCampaigns,
} from '../../../../Store/Slices/Publisher-role/Dashboard/publisherDashboardSlice';
import {
  programTargetingData,
  reportTypeData,
} from '../../../../Mock/ProgramTargetingData';
import { updateSelectedMbrands } from '../../../../Store/Slices/ExternalApiSlice';
import MMTable from '../../../../Component/Table/MMTable';
import {
  brandNameDummy,
  rawReportHeadCell,
} from '../../../../Component/Table/tableInterface';
import BasicSelect from '../../../../Component/DropDowns/BasicSelect';
import CachedIcon from '@mui/icons-material/Cached';
import {
  fetchRawReportTableData,
  generateRawDataReport,
} from '../../../../Services/dashBoardApi';
import { RootState } from '../../../../Store';
import {Snackbar} from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';

const RawDataReport = () => {
  const dispatch = useAppDispatch();
  const mBrandData = useAppSelector(state => state.mBrandHandlerApi.data);
  const [selectedAreaStudy, setSelectedAreaStudyValue] = useState(
    areaStudyDropdown.map(name => name.value)
  );
  const [selectedReportType, setSelectedReportValue] = useState(
    reportTypeData.map(name => name.value)
  );
  const overallPageData = useAppSelector(state => state.overallData);
  const mBrandPageData = useAppSelector(state => state.mBrandHandlerApi);
  const [reportName, setReportName] = useState('');
  const [dateRange, setDateRange] = useState(timeLineDropDown[0].value);
  const [pubId, setPubId] = useState('');
  const [reportNameError, setReportNameError] = useState(false);
  const rawReportdata = useAppSelector((state: RootState) => state.rawDataReportSlice.data);
  const latest = useAppSelector((state: RootState) => state.rawDataReportSlice.rawStatus);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  // const [dateRange, setDateRange] = useState(timeLineDropDown[0].value)
  const location = useLocation();
  let userName = localStorage.getItem('userName');

  useEffect(() => {
    const pubId = localStorage.getItem('userSortid');
    // console.log('user ID ', pubId);
    let lastFiveCharacters;
    if (pubId) {
      lastFiveCharacters = pubId.slice(-5);
      // console.log('pub id', pubId);
      setPubId(lastFiveCharacters);
    }
    fetchMBrandApiData(dispatch);
    dispatch(getCampaignsThunk());
  }, []);

  const handelSelectedMbrands = (value: string[]) => {
    dispatch(updateSelectedMbrands(value));
  };

  const handelSelectedCampaigns = (value: string[]) => {
    dispatch(updateSelectedCampaigns(value));
  };

console.log('rawReportdata==',rawReportdata[0]?.rawStatus,latest )

let payload: any = {};
const areaStudyLabels = selectedAreaStudy.map(value => {
  const target = programTargetingData.find(item => item.value === value);
  return target ? target.label : '';
});

location.pathname === '/raw-report' ? payload = {
  mPubId: pubId,
  mPubCampaignId: overallPageData.selectedCampaigns.join(','),
  // areaStudy: selectedAreaStudy.join(','),
  areaStudy: areaStudyLabels.join(','),
  timeFrame: dateRange,
  // reportType: selectedReportType.join(','),
  reportType: 'Customer Attribute Performance Report',
  reportName: reportName,
  mBrandId: mBrandPageData.selectedMbrand.join(','),
  chartType: 'rawReport',
  userName: userName,
} :

 payload = {
  mAdvId: pubId,
  mAdvCampaignId: overallPageData.selectedCampaigns.join(','),
  // areaStudy: selectedAreaStudy.join(','),
  areaStudy: areaStudyLabels.join(','),
  timeFrame: dateRange,
  // reportType: selectedReportType.join(','),
  reportType: 'Customer Attribute Performance Report',
  reportName: reportName,
  // mBrandId: mBrandPageData.selectedMbrand.join(','),
  chartType: 'rawReport',
  userName: userName,
};

  const handleGenReport = async () => {
    if (!reportName.trim()) {
      setReportNameError(!reportName.trim());
    } else {

      function jsonToQueryString(json: any) {
        return Object.keys(json)
          .map(function (key) {
            return (
              encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
            );
          })
          .join('&');
      }
      var queryString = jsonToQueryString(payload);

      try {
        setSnackbarOpen(true);
        setSuccessMessage('Report generation is in progress. Click the refresh icon to fetch the updated table data.');
      generateRawDataReport(dispatch, queryString, payload);
      } catch (error) {
        console.error('Error fetching generateRawDataReport:', error);
      }

        setTimeout(async () => {
          try {
            await fetchRawReportTableData(dispatch, userName, payload);
          } catch (error) {
              console.error('Error fetching fetchRawReportTableData', error);
          }
      }, 3000); // 3 seconds

const intervalId = setInterval(async () => {
 
    try {
       const response:any =  await fetchRawReportTableData(dispatch, userName, payload);
      // const  latestStatus = rawReportdata[0]?.rawStatus;
        //  console.log('get:', response[0]?.rawStatus);
        if (response.length && (response[0]?.rawStatus === 'Generated' || response[0]?.rawStatus === 'No Data Found')) {
            console.log('Status is "Generated". Clearing interval.');
            clearInterval(intervalId);
        }
    } catch (error) {
        console.error('Error in fetchRawReportTableData:', error);
    }
    
}, 20000); // 30 seconds

      
    }
  };

  const handleRefresh = () => {
    console.log('payload==r==', payload);
  fetchRawReportTableData(dispatch, userName, payload);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Paper elevation={3} className="card">
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Box>
            <Typography className="card-heading">Raw Data Report</Typography>
            <Typography className="card-sub-heading">
              Raw Data Summary
            </Typography>
          </Box>
        </Box>
        {/* <Paper elevation={3} className="card">
      <h2>Raw Data Report</h2> */}
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12} lg={4}>
            <Box sx={{  width: '100%' }}>
              <BasicSelect
                value={dateRange}
                setValue={setDateRange}
                menuOptions={timeLineDropDown}
                selectLabel={'Time Frame'}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              label="Create report name*"
              fullWidth
             // sx={{ width: 'auto' }}
              value={reportName}
              onChange={e => {
                setReportNameError(false);
                setReportName(e.target.value);
              }}
              error={reportNameError}
              helperText={reportNameError ? 'Report Name is mandatory' : ''}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box className="areaStudy-dropdown-container">
              <MultipleSelectDropDown
                dropdownLabel="Report Type"
                dropdownData={reportType}
                dropDownValue={selectedReportType}
                setDropDownValue={setSelectedReportValue}
              />

              {/* <MultipleSelectDropDown dropdownLabel="Selact Report Type" dropdownData={areaStudyDropdown} dropDownValue={undefined} setDropDownValue={undefined} /> */}
            </Box>
          </Grid>
         
          {/* Second row */}
          <Grid item xs={12} lg={4}>
            <Box className="campaigns-dropdown-container">
              <MultipleSelectDropDown
                dropdownLabel="Campaigns"
                dropdownData={overallPageData.campaignDropdownData}
                dropDownValue={overallPageData.selectedCampaigns}
                setDropDownValue={handelSelectedCampaigns}
              />

              {/* <MultipleSelectDropDown dropdownLabel="Capmaigns" dropdownData={areaStudyDropdown} dropDownValue={undefined} setDropDownValue={undefined} /> */}
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box className="campaigns-dropdown-container">
              <MultipleSelectDropDown
                dropdownLabel="Area Study"
                dropdownData={areaStudyDropdown}
                dropDownValue={selectedAreaStudy}
                setDropDownValue={setSelectedAreaStudyValue}
              />
            </Box>
          </Grid>
         {location.pathname === '/raw-report' ?  <Grid item xs={12} lg={4}>
            <Box className="campaigns-dropdown-container">
              <MultipleSelectDropDown
                dropdownLabel="MBrands"
                dropdownData={mBrandPageData.mBrandDropdownData}
                dropDownValue={mBrandPageData.selectedMbrand}
                setDropDownValue={handelSelectedMbrands}
              />
            </Box>
          </Grid>:  null  }
          
          <Grid
            gap={5}
            display="flex"
            item
            xs={12}
            lg={12}
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              // marginRight: '10%',
              padding: '30px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenReport}
            >
              Generate Report
            </Button>
            <Box
              className="refresh-button"
              sx={{ marginTop: 'auto' }}
              onClick={handleRefresh}
            >
              <Tooltip title="Refresh">
                <CachedIcon sx={{ color: 'primary' }} />
              </Tooltip>
            </Box>
          </Grid>
          {/* Add more Grid items as needed */}
        </Grid>
        {rawReportdata.length > 0 && (
          <MMTable
            tableData={rawReportdata}
            tableHeadCell={rawReportHeadCell}
            // isLoadingMore={isLoading}
            // fetchMoreData={() => fetchMoreData()}
            // hasMoreData={hasMoreData}
            // fetchInitialData={fetchInitialData}
          />
        )}
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={20000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default RawDataReport;
