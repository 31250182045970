import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { campaignFormSelector, programCsvRadioToggle, storeCampaignCSVData, storeErrorTrackURLmProgramId, storeProgramCSVFileName } from '../../Store/Slices/CreateAdvCampaignSlice';
import { createAdvCampaigns } from '../../service/Advertiser';
import { generateRequiredObjectForAudienceTargeting } from '../../Utils/utils';
import { updateIsReload } from '../../Store/Slices/advertiserTabApisData';
import { Box, CircularProgress, FormControlLabel, Radio, RadioGroup, } from '@mui/material'
import defaultMProgram from '../../Mock/Mprogram.json';
import { validateUrls } from '../Button/FileUploadBtn';
import { generateAreaStudyName } from '../../Mock/ProgramTargetingData';

interface IDialog {
  open: boolean;
  children: (props: any) => JSX.Element;
  openHandler: () => void;
  closeHandler: () => void;
  dialogTitle: string;
  snackbarOpenHandler: (flag: boolean) => void;
  snackbarTypeHandler: (type: string) => void;
  snackbarMessageHandler: (message: string) => void;
  isUploadError: boolean;
  setFileError: (flag: boolean) => void;
}
export default function AlertMMDialog(props: IDialog) {

  const dispatch = useAppDispatch();
  const advCampaignData = useAppSelector(campaignFormSelector);
  const [selectedOption, setSelectedOption] = React.useState('remove');
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption((event.target as HTMLInputElement).value);
    dispatch(programCsvRadioToggle((event.target as HTMLInputElement).value))
  };

  const [alertOpen, setAlertOpen] = React.useState<boolean>(false)



  const [isLoading, setIsLoading] = React.useState(false)

  const generatePayload = () => {
    const audiencPayload = generateRequiredObjectForAudienceTargeting(advCampaignData.value.excludeAdjustment)

    const mPrograms: any = {
      ...advCampaignData.value.mPrograms
    }
    // console.log("mPrograms before", mPrograms);

    let requestPayloadForEdit = {
      "advertiser": advCampaignData.advertiserProp[0],
      "ID": "campaign",
      "campaignID": advCampaignData.SORTID,
      "createdOn": advCampaignData.createdOn,
      "updatedOn": "",
      "SORTID": advCampaignData.SORTID,
      "campaignType": "advertiserCampaign",
      "campaignname": advCampaignData.campaignName,
      "GroupName": advCampaignData.selectedAdvertiser[0]?.SORTID,
      "mPubID": advCampaignData.selectedAdvertiser[0]?.SORTID.slice(-5),
      "type": "Advertiser",
      values: {
        "advertisername": advCampaignData.selectedAdvertiser[0]?.SORTID,
        "Headline": "",
        // "source": "Test for v3  Description 1",
        "displayUrl": advCampaignData.adCopy.adDescription.displayUrl,
        "trackingUrl": advCampaignData.value.trackingUrl,
        // "bonusDesc": "Test for v3  bonus Desc..",
        "imageUrl": advCampaignData.adCopy.adDescription.imageUrl,
        "status": advCampaignData.advertiserStatus,
        "excludeAdjustment": audiencPayload,
        "publisherName": advCampaignData.selectedAdvertiser[0]?.SORTID,
        "brand": advCampaignData.adCopy.adDescription.brandName,
        "headline": advCampaignData.adCopy.adDescription.headContent,
        "source": advCampaignData.adCopy.adContent.bulletInput1,
        "source1": advCampaignData.adCopy.adContent.bulletInput2,
        "source2": advCampaignData.adCopy.adContent.bulletInput3,
        "zipRules": advCampaignData.value.zipRules,
        // "mProgram":advCampaignData.value.mPrograms,
        "mProgram": mPrograms,
        "gradYear": advCampaignData.value.gradYear,
        "excludePublishers": advCampaignData.value.excludePublishers,
        "excludeStates": advCampaignData.value.excludeStates,
        "stateRules": advCampaignData.value.stateRules,
        "anyState": advCampaignData.value.anyState,
        "configTimeOfDay": advCampaignData.value.scheduling,

        "programCSVConfig": {
          "programCSVData": advCampaignData.campaignCSVData,
          "csvDataError": advCampaignData.errorTrackURLmProgramID.length > 0,
          "csvFileName": advCampaignData.campaignProgramCSVFileName
        }
      },
      "publisherID": advCampaignData.selectedAdvertiser[0]?.SORTID.slice(-5),
      "publisherName": advCampaignData.selectedAdvertiser[0]?.SORTID,
      "status": advCampaignData.status,
      "advertiserStatus": advCampaignData.advertiserStatus
    }

    return { requestPayloadForEdit, mPrograms }
  }

  const handleSubmit = async () => {
    // Create payload based on the 
    props.setFileError(false)
    dispatch(storeErrorTrackURLmProgramId([]))
    setIsLoading(true);
    console.log("advCampaignData upload data ", advCampaignData.campaignCSVData)
    console.log("Last Uploaded data", advCampaignData.lastUploadedCSVFileData)
    const { requestPayloadForEdit, mPrograms } = generatePayload()
    if (advCampaignData.csvRadioSelected === 'add') {

      const csvData = advCampaignData.isProgramCsvUploaded ? advCampaignData.campaignCSVData : Object.values(advCampaignData.lastUploadedCSVFileData).map((item: any) => ({
        "Area Study": generateAreaStudyName(item.areaStudy),
        id: item.areaStudy,
        mPrograms: item.label,
        mProgramID: item.value,
        Status: item.enable ? 'on' : 'off',
        "tracking url": item.trackingUrl || '',
        CPC: item.bid,
        Description: item.bonusDescription ? item.bonusDescription : ""
      }));
    console.log("csv data", csvData);

      const {updatedData, invalidProgramsList}=validateUrls(csvData)
      requestPayloadForEdit.values.programCSVConfig.csvDataError=invalidProgramsList.length > 0
      csvData.forEach((csvItem: any) => {
        const programID = csvItem.mProgramID;
        const program = mPrograms[programID];
        if (program) {
          mPrograms[programID] = {
            ...program, // Copy existing properties
            bid: parseInt(csvItem.CPC), // Convert CPC to integer if needed
            enable: csvItem.Status === "on",
            trackingUrl: csvItem['tracking url'],
            bonusDescription: csvItem.Description,
            trackUrlError: csvItem.trackUrlError ? csvItem.trackUrlError : ""
          };
        }
      });
      // console.log("mPrograms After", mPrograms);
      requestPayloadForEdit.values.programCSVConfig.programCSVData=csvData;
    } else if (advCampaignData.csvRadioSelected === 'remove') {
      // alert("You have selected remove options all csv data will remove from campaign")
      setAlertOpen(true)
      return;


    }



    // console.log("request payload for edit", requestPayloadForEdit)


    // Make API call to PUT CALL to adv campaigns API.

    const { response, error } = await createAdvCampaigns(requestPayloadForEdit, "PUT")
    if (error) {
      // alert("err")
      props.snackbarOpenHandler(true);
      props.snackbarTypeHandler('error')
      props.snackbarMessageHandler(error.message)
      dispatch(programCsvRadioToggle('remove'))
    } else if (response) {
      // alert("Success")
      dispatch(programCsvRadioToggle('remove'))
      props.snackbarOpenHandler(true);
      props.snackbarTypeHandler("success")
      setIsLoading(false)
      props.snackbarMessageHandler(`Successfully updated the ${advCampaignData.SORTID} campaign programs configuration`)
      dispatch(updateIsReload(true))
      props.closeHandler();
    




    }
  }


  const handleAlertAgree = async () => {
    const { requestPayloadForEdit, mPrograms } = generatePayload()
    requestPayloadForEdit.values.mProgram = defaultMProgram
    requestPayloadForEdit.values.programCSVConfig = {
      programCSVData: [],
      csvFileName: '',
      csvDataError: false
    }

    const { response, error } = await createAdvCampaigns(requestPayloadForEdit, "PUT")
    // console.log("RESPONSE ", response)

    if (error) {
      // alert("err")
      props.snackbarOpenHandler(true);
      props.snackbarTypeHandler('error')
      props.snackbarMessageHandler(error.message)
      dispatch(programCsvRadioToggle('remove'))
    } else if (response) {
      // alert("Success")
      props.snackbarOpenHandler(true);
      props.snackbarTypeHandler("success")
      setIsLoading(false)
      props.snackbarMessageHandler(`Successfully updated the ${advCampaignData.SORTID} campaign programs configuration`)
      setAlertOpen(false)
      dispatch(updateIsReload(true))
      dispatch(programCsvRadioToggle('remove'))
      setTimeout(() => {
        dispatch(updateIsReload(true))
        props.closeHandler();
      }, 1000)




    }
  };

  const handleAlertDisAgree = () => {
    setAlertOpen(false)
    dispatch(programCsvRadioToggle('remove'))
    setIsLoading(false);
  }


  const handleDialougeClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      dispatch(storeCampaignCSVData(null))
      dispatch(storeErrorTrackURLmProgramId([]))
      dispatch(storeProgramCSVFileName(''))
      dispatch(programCsvRadioToggle('remove'))
      // before close we make sure to clean up the data of upload or any error basically reset the the data.
      props.closeHandler()
    }

  };


  const handleClose = () => {
    setIsLoading(false)
    // console.log("reason on dialoge close", reason)
    dispatch(storeCampaignCSVData(null))
    dispatch(storeErrorTrackURLmProgramId([]))
    dispatch(storeProgramCSVFileName(''))
    dispatch(programCsvRadioToggle('remove'))
    props.closeHandler()
  };



  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleDialougeClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ style: { opacity: '1' } }}
      // onBackdropClick={handleBackdropClick}
      >
        <DialogTitle id="alert-dialog-title">
          {props.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <Box sx={{paddingLeft:'20px'}}>
            <RadioGroup row name="option" value={advCampaignData.csvRadioSelected} onChange={handleOptionChange}>
              <FormControlLabel value="add" control={<Radio />} label="Include  file" />
              <FormControlLabel value="remove" control={<Radio />} label="Remove file" />
            </RadioGroup>
          </Box>
          {<props.children  {...props} />}

        </DialogContent>
        <DialogActions>

          <Button color='error' onClick={handleClose}>
            CANCEL
          </Button>
          <Button sx={{
            backgroundColor: "#0ae",

            "&.MuiButtonBase-root:hover": {
              bgcolor: "#1976d2 !important"
            },
            "&:hover": { backgroundColor: "#1976d2" },
            "&:disabled": { backgroundColor: "#ffff" },
            border: "1px solid #0ae",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            borderRadius: "8px",
            color: "#ffff",
            height: "45px"
          }}
            // disabled={advCampaignData.campaignCSVData?.length <= 0}
            onClick={handleSubmit}
          >
            {isLoading ? <CircularProgress color="inherit" size={24} /> : "SAVE"}
          </Button>
        </DialogActions>

      </Dialog>

      <SimpleDialog open={alertOpen}
        closeHandler={handleAlertDisAgree}
        agreeHandler={handleAlertAgree}
      />
    </React.Fragment>
  );
}

interface IsampleDilog {
  open: boolean,
  closeHandler: any,
  agreeHandler: any
}

const SimpleDialog = (props: IsampleDilog) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Remove existing CSV file
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You have selected remove options all csv data will remove from campaign
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeHandler}>Disagree</Button>
        <Button onClick={props.agreeHandler} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}