import { ROLE } from '../../routes';
// import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, useLocation } from "react-router-dom";
// import AdminDashboard from '../AdminUser';
// import AccessDenied from './AccessDenied';
// import { Auth } from 'aws-amplify'

 const PrivateRoutes = ({
  // children,
  roles,
  // isAuthenticated,
}: {
  // isAuthenticated:boolean;
  // children: JSX.Element;
  roles: Array<ROLE>;
}) => {
  // let userRole=localStorage.getItem("userRole")
  // const fetchUser = async () => {
  //   const currentUser = await Auth.currentAuthenticatedUser()
  //   console.log("current User ", currentUser);
  //   return currentUser
  // }

  // useEffect(() => {
  //   const fetchCurrentUser = async () => {
  //     // setLoading(true)
  //     fetchUser().then((user) => {
  //       console.log("user", user)
  //       // dispatch(storeUserInfo(user))
  //       // setLoading(false);
  //     }).catch((err) => {
  //       // redirect("/login")
  //     })
  //     // console.log("user", user);
  //   }
  //   fetchCurrentUser()
  // }, [])


  // const userHasRequiredRole = userRole && roles.map(role => role.toLowerCase()).includes(userRole.toLowerCase())
  let location = useLocation();
  let localStorageItem=localStorage.getItem("userId")
// console.log("userRole===",localStorageItem)

//  const userHasRequiredRole = userRole && roles.map(role => role.toLowerCase()).includes(userRole.toLowerCase())
if (!localStorageItem) {
  return <Navigate to="/login" state={{ from: location }} />;
}

// else if (localStorageItem && !userHasRequiredRole) {
//   return <AccessDenied />; 
// }

else {return localStorageItem ? (
  <Outlet />
) : (
  <Navigate to={"/login"} replace={true} />
);}
};


export default PrivateRoutes;