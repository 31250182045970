import React, { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { RootState } from "../../Store";
import { setCapSettingsField, setFields } from "../../Store/Slices/PublisherCampaignSlice";
import { fetchPublisherCampApiData } from "../../Services/publisherTabApis";
 import {  Tooltip} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIconPop } from "../Advertiser/Action";
import { Link } from 'react-router-dom';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { setIsoLoading } from "../../Store/Slices/advertiserTabApisData";
import { generateHeader } from "../../service/Auth";
import axios from "axios";
import { baseURLConfig } from "../../config/envConfig";
import { CreatePubliCampaigns } from "./Campaigns";

export const PubCamActionIcons = React.memo((props: any) => {
    const { CamID, fetchInitialData, searchInputDe } = props;
    const [openPopup, setOpenPopup] = React.useState(false);
    const dispatch = useAppDispatch();
    const fullData = useAppSelector((state) => state.publisherCampApi.fullDataCamp);
    const [openDeletePopup, setDeletePopup] = React.useState(false);
    const campaignState = useAppSelector((state: RootState) => state.campaign);
    const getRowDataById = (camId: string) => {
      return fullData.find((row: any) => row.SORTID === camId);
    };
     const rowData = getRowDataById(CamID);
  
    const editFetch = (data: any) => {
      if (campaignState.editFlag && data) {
        const fieldsToUpdate = [
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',
        ];
  
        fieldsToUpdate.forEach((field: any) => {
          dispatch(setCapSettingsField({ field, value: data[0].capSettings?.dayCap?.[field] || 0 }));
        });
  
        const fields = [
          'campaignname',
          'status',
          'excludeMbrandID'
          // 'publisherName',
        ]
        fields.forEach((field: any) => {
          dispatch(setFields({ field, value: data[0]?.[field] || '' }));
        });
  
        const dayCap = [
          'daily',
          'weekly',
          'monthly',
          // 'enableDayCap',
        ]
        dayCap.forEach((field: any) => {
          dispatch(setCapSettingsField({ field, value: data[0].capSettings?.[field] || 0 }));
        });
        //console.log('excludeMbrandID', campaignState.excludeMbrandID);
        if (data[0].excludeMbrandID && Object.keys(data[0].excludeMbrandID).length > 0) {
          dispatch(setFields({ field: 'excludedBrandIDAction', value: 'true' }));
        } else {
          dispatch(setFields({ field: 'excludedBrandIDAction', value: 'false' }));
        }
        // dispatch(setFields({ field: 'excludedBrandIDAction', value: rowData?.excludedBrandIdToggle || 'false' }));
  
        dispatch(setFields({ field: 'excludeMbrandID', value: data[0]?.excludeMbrandID || {} }));
        dispatch(setFields({ field: 'description', value: data[0].source || '' }));
        dispatch(setFields({ field: 'publisherName', value: data[0].publisher?.SORTID || '' }))
        dispatch(setFields({ field: 'enableDayCap', value: data[0].capSettings?.enableDayCap || false }));
        ;
      }
    };
  
    const fetchData = () => {
    fetchPublisherCampApiData(dispatch, null)
    };
  
  
    const handleDeleteButtonClick = useCallback(() => {
      
      setDeletePopup(true);
    }, []);
    
    const handleEditButtonClick = async () => {
        try{
            dispatch(setIsoLoading(true));
            const encodedValue: string = encodeURIComponent(CamID);
            const headers= await generateHeader();
          const response= await axios.get(`${baseURLConfig.baseURl}/search?ID=campaign&key=${encodedValue}&campaignType=publisherCampaign`, {headers});
          editFetch(response.data.data);
        setOpenPopup(true);
        }catch(error){
            dispatch(setIsoLoading(false));
            throw error;
        }
        finally{
            dispatch(setIsoLoading(false));
        }
      };

   


    const delPayload = rowData?.SORTID;
    return (
      <div>
        <Tooltip title="Edit">
          <EditIcon onClick={handleEditButtonClick} color="primary" /></Tooltip>
        <Tooltip title="Delete"><DeleteIcon onClick={handleDeleteButtonClick} color="error" /></Tooltip>
  
        <Tooltip title="configure-Media Source">
          <Link to='/configure-MediaSource' state={{ rowData: rowData }}>
            <AdUnitsIcon color="primary" />
          </Link>
        </Tooltip>
  
  
        <CreatePubliCampaigns openPopup={openPopup} setOpenPopup={setOpenPopup}
          rowData={rowData} CamID={CamID} fetchInitialData={fetchInitialData} searchInput={searchInputDe} />
        <DeleteIconPop
          openDeletePopup={openDeletePopup}
          setDeletePopup={setDeletePopup}
          rowData={rowData}
          delPayload={delPayload}
          fetchData={fetchData}
          searchInputDe={searchInputDe}
        />
      </div>
    )
  })