import React from 'react'


const MpartnerMargin = () => {
  return (

    <div>PartnerMargin</div>
  )
}

export default MpartnerMargin