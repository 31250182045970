import PublisherAccount from "./View/Publisher/Account";
// import EditIcon from "./View/Advertiser/Action";
import PublisherCampaigns from "./View/Publisher/Campaigns";
import AdvertiserUser from "./View/User Accounts/AdvertiserUser";
import PublisherUser from "./View/User Accounts/PublisherUser";
import AdvertiserAccount from './View/Advertiser/Account';
import AdvertiserCampaigns from './View/Advertiser/Campaigns';
import AdminReport from "./View/Reports/Admin";
import AdvertiserReport from "./View/Reports/Advertiser";
import PublisherReport from "./View/Reports/Publisher";
import RawReport from "./View/Reports/RawData";
import Tools from "./View/Tools/MPubMarginConfig";

import BrandMapping from "./View/External APIS/BrandMapping";
import MpartnerActivation from "./View/External APIS/MpartnerActivation";
import MpartnerMargin from "./View/External APIS/MpartnerMargin";
import CreateAdvCampaigns from "./View/Advertiser/Stepper Form/CreateAdvCampaigns";
import MPartnerConfig from "./View/External APIS/mPartnerConfig";
import AdminDashboard from "./Component/AdminUser";
import AccessDenied from "./Component/Auth/AccessDenied";
import PublisherUserCampign from "./View/Publisher/PublisherUserCampign";
import { Login1 } from "./Component/Auth/Login1";
import ClickToLeads from "./View/Role/Publisher/Dashboard/ClickToLeads";
import RawDataReport from "./View/Role/Publisher/Dashboard/RawDataReport";
import PublisherUsers from "./View/Role/Publisher/User/Index";
import Vendors from './View/User Accounts/Vendors/Vendors'
import DashboardPUB from "./View/Role/Publisher/Dashboard/Dashboard";
import AddEditContent from "./View/CMS/WebsiteCms/addEditContent";
import TestAPI from "./View/Role/Publisher/Tools/TestAPI";
import ConfigureMediaSource from "./View/Publisher/Configure Media Source/ConfigureMediaSource";
import MPubMargin from "./View/Tools/mPubMargin";
import MPubMarginConfig from "./View/Tools/MPubMarginConfig";
import BudgetList from "./View/Tools/budgetAllocation/budgetList";
import EditBudgetAllocationDetails from "./View/Tools/budgetAllocation/editBudgetAllocationDetails";
import EditCapsFiltering from "./View/Tools/budgetAllocation/editCapsFiltering";
import LeadCampaigns from './View/Advertiser/LeadCampaigns';
import AddTcpaEditDetails from "./View/TCPAcampaign/addTcpaEditDetails";
import TCPAcampDummy from "./View/Advertiser/TCPAcampDummy";
import MbrandExclusion from "./View/Tools/MbrandExclusion";
import TCPAcampaigns from "./View/TCPAcampaign/TCPAcampaigns";
import SeoAccount from "./View/CMS/WebsiteCms/Account";
import AdvertisePage from "./View/CMS/AdvertiserPage";
import AddEditDetails from "./View/CMS/AdvertiserPage/addEditDetails";

import AdminTestAPI from "./View/Tools/Test APIS/AdminTestAPI";
import AdminTCPA_API from "./View/Tools/Test APIS/AdminTCPAAPI";
import VendorAd from "./View/Role/Vendor/VendorAd";
import VendorAdCampaign from './View/Role/Vendor/VendorAdCampaign'
import DashboardVendor from "./View/Role/Vendor/Dashboard/DashboardVendor";
import CpcThreshold from "./View/Tools/CpcThreshold";
import EditCpcThreshold from "./View/Tools/editCpcThreshold";
import AdvRawReport from "./View/Role/Vendor/Dashboard/AdvRawReport";
export enum ROLE {
  publisher = 'publisher',
  admin = 'admin',
  superAdmin='Super-Admin',
  advertiser='advertiser',
  vendors='vendors'
  // Add more roles as needed
}

export const mmRoutes = [
  {
    path: '/',
    element: AdvertiserCampaigns,
    roles: ['admin', 'advertiser', "Super-Admin"],
    exact: true
  },
  {
    path: '/admin-dashboard',
    element: AdminDashboard,
    roles: ['admin', 'advertiser', "Super-Admin"],
    exact: true
  },
  {
    path: '/user',
    element: PublisherUser,
    roles: ['admin', 'advertiser', "Super-Admin"],
    exact: true

  },
  {
    path: '/vendors',
    element: Vendors,
    roles: ['admin', "Super-Admin"],
    exact: true

  },
  {
    path: '*',
    element: AccessDenied,
    roles: ['admin', 'advertiser', "Super-Admin"],
    exact: true
  },
   {
    path: '/access-denied',
    element: AccessDenied,
    roles: ['admin', 'advertiser', 'Publisher', "Super-Admin"],
    exact: true
  },
  
  {
    path: '/Advertiser-user',
    roles: ['admin', 'advertiser', "Super-Admin"],
    element: AdvertiserUser,
    exact: true
  },
  {
    path: '/publisher-accounts',
    roles: ['admin',"Super-Admin"],
    element: PublisherAccount,
    exact: true
  },
  {
    path: '/publisher-campaigns',
    roles: ['admin', "Super-Admin"],
    element: PublisherCampaigns,
    exact: true
  },
  {
    path: '/configure-MediaSource',
    roles: ['admin', "Super-Admin"],
    element: ConfigureMediaSource,
    exact: true
  },
  {
    path: '/advertiser-accounts',
    roles: ['admin','advertiser', "Super-Admin"],
    element: AdvertiserAccount,
    exact: true
  },
  {
    path: '/advertiser-campaigns',
    roles: [ 'admin', 'advertiser', "Super-Admin"],
    element: AdvertiserCampaigns,
    exact: true
  },
  {
    path: '/lead-campaigns',
    roles: [ 'admin', "Super-Admin"],
    element: LeadCampaigns,
    exact: true
  },
  {
    path: '/TCPA-campaigns',
    roles: [ 'admin', "Super-Admin"],
    element: TCPAcampaigns,
    // element: TCPAcampDummy,
    exact: true
  },
 
  {
    path: '/mPartner-activation',
    roles: ['admin', 'advertiser', "Super-Admin"],
    element: MpartnerActivation,
    exact: true
  },
  {
    path: '/adminTestAPI',
    roles: ['admin', 'advertiser', "Super-Admin"],
    element: AdminTestAPI,
    exact: true
  },
  {
    path: '/admin-test-tcpa-API',
    roles: ['admin', 'advertiser', "Super-Admin"],
    element: AdminTCPA_API,
    exact: true
  },
  {
    path: '/brand-mapping',
    element: BrandMapping,
    roles: ['admin', 'advertiser', "Super-Admin"],
    exact: true
  },
  {
    path: '/mPartner-margins',
    element: MpartnerMargin,
    roles: ['admin', 'advertiser', "Super-Admin"],
    exact: true
  },
  {
    path: '/admin-report',
    element: AdminReport,
    roles: ['admin', 'advertiser', "Super-Admin"],
    exact: true
  },
  {
    path:'/publisher-report',
    element: PublisherReport,
    roles: ['admin', 'Publisher', "Super-Admin"],
    exact: true
  },
  {
    path: '/advertiser-report',
    element: AdvertiserReport,
    roles: ['admin', 'publisher', "Super-Admin"],
    exact: true
  },
  {
    path: '/raw-data-report',
    element: RawReport,
    roles: [ 'admin', 'Publisher', 'advertiser', "Super-Admin"],
    exact: true
  },
  {
    path: '/margin-distributer',
    element: Tools,
    roles: ['admin','Publisher', 'advertiser', "Super-Admin"],
    exact: true
  },
  {
    path: '/mPub-margins',
    element: MPubMargin,
    roles: ['admin', "Super-Admin"],
    exact: true
  },
  {
    path: '/cpc-threshold',
    element: CpcThreshold,
    roles: ['admin', "Super-Admin"],
    exact: true
  },
  {
    path: '/cpc-threshold-config',
    roles: [  'admin' , "Super-Admin"],
    element: EditCpcThreshold,
    exact: true

  },
  {
    path: '/budget-allocation',
    element: BudgetList,
    roles: ['admin', "Super-Admin"],
    exact: true
  },
  {
    path: '/edit-budget-caps',
    element: EditBudgetAllocationDetails,
    roles: ['admin', "Super-Admin"],
    exact: true
  },
  {
    path: '/caps-filtering',
    element: BudgetList,
    roles: ['admin', "Super-Admin"],
    exact: true
  },
  {
    path: '/edit-caps-filtering',
    element: EditCapsFiltering,
    roles: ['admin', "Super-Admin"],
    exact: true
  },
  {
    path: '/weighted-adjustment',
    element: Tools,
    roles: ['admin','Publisher', 'advertiser', "Super-Admin"],
    exact: true
  },
  {
    path: '/createAdvertiserCampaigns',
    element: CreateAdvCampaigns,
    roles: ['admin', 'advertiser', "Super-Admin"],
    exact: true
  },
  {
    path: '/createAdvertiser',
    element: CreateAdvCampaigns,
    roles: ['admin','advertiser', "Super-Admin"],
    exact: true
  },


  {
    path: '/mPartners-config',
    roles: [  'admin', 'advertiser', "Super-Admin"],
    element: MPartnerConfig,
    exact: true

  },
  {
    path: '/mPub-margins-config',
    roles: [  'admin', 'advertiser', "Super-Admin"],
    element: MPubMarginConfig,
    exact: true

  },
  {
    path: '/editAdvertiserCampaign',
    element: CreateAdvCampaigns,
    roles: ['admin', 'advertiser', "Super-Admin"],
    exact: true

  },
  {
    path: '/cms-website',
    element: SeoAccount,
    roles: ['admin', "Super-Admin"],
    exact: true
  },
  {
    path: '/advertiseHandler',
    element: AdvertisePage,
    roles: ['admin', "Super-Admin"],
    exact: true
  },
  {
    path: '/edit-cmsWebsite',
    element: AddEditContent,
    roles: ['admin',"Super-Admin"],
    exact: true
  },
  {
    path: '/advertisePageHandler',
    element: AddEditDetails,
    roles: ['admin',"Super-Admin"],
    exact: true
  },
  {
    path: '/createTcpaCamp',
    element: AddTcpaEditDetails,
    roles: ['admin',"Super-Admin"],
    exact: true
  },
  {
    path: '/editTcpaCamp',
    element: AddTcpaEditDetails,
    roles: ['admin',"Super-Admin"],
    exact: true
  }
  

]

export const publisherRoutes = [
  {
    path: '/publisher_user',
    element: PublisherUsers,
    roles: ['publisher'],
    exact: true

  },
  {
    path: '/test-chart',
    element: DashboardPUB,
    roles: ['publisher'],
    exact: true

  },
  {
    path: '*',
    element: PublisherUserCampign,
    roles: ['Publisher'],
    exact: true
  },
  {
    path: '/publisherCampaigns',
    element: PublisherUserCampign,
    roles: ['Publisher'],
    exact: true

  },
  {
    path: '/mPartners-config',
    roles: [  'Publisher',],
    element: MPartnerConfig,
    exact: true

  },
  {
    path: '/overall',
    roles: [  'Publisher',],
    // element: Overall,
   element:DashboardPUB,
    exact: true

  },
  {
    path: '/click-to-leads',
    roles: [  'Publisher',],
    element: ClickToLeads,
    exact: true

  },
  {
    path: '/raw-report',
    roles: [  'Publisher',],
    element:RawDataReport,
    exact: true

  },
  {
    path: '/intraday',
    roles: [  'Publisher',],
    // element:Intraday,
    element:DashboardPUB,
    exact: true

  },
  {
    path: '/testApi',
    roles: [  'Publisher',],
    // element:Intraday,
    element:TestAPI,
    exact: true

  },
]

export const advertiserRoutes = [
  {
    path: '/vendor_user',
    element: PublisherUsers,
    roles: ['Vendor'],
    exact: true

  },
  {
    path: '/vendor-ad',
    element: VendorAd,
    roles: ['Vendor'],
    exact: true

  },
  {
    path: '*',
    element: VendorAd,
    roles: ['Vendor'],
    exact: true
  },
  {
    path: '/vendor-ad-campaigns',
    element: VendorAdCampaign,
    roles: ['Vendor'],
    exact: true

  },
   {
    path: '/adv-overall',
    roles: ['Vendor'],
    // element: Overall,
   element:DashboardVendor,
    exact: true

  },
  {
    path: '/adv-click-to-leads',
    roles: ['Vendor'],
    element: ClickToLeads,
    exact: true

  },
  {
    path: '/adv-raw-report',
    roles: ['Vendor'],
    element:DashboardVendor,
    exact: true

  },
  {
    path: '/adv-intraday',
    roles: ['Vendor'],
    // element:Intraday,
    element:DashboardVendor,
    exact: true

  },

]

export const NoUserRoutes = [
  {
    path: '*',
    element: Login1,
    roles: [],
    exact: true
  },
  {
    path: '/access-denied',
    element: AccessDenied,
    roles: [],
    exact: true
  },
]